import React, {Component} from "react";
import {
  acceptRequsetUser,
  rejectRequsetUser, deleteUser, switchAdminUser, getAllContrAgentsList
} from "../../../actions/mainActions";
import { Table, message, Empty } from "antd";
import VpPagination from "../VpPagination";
// import { ReactComponent as UserPlus } from "../../../assets/images/icons/user_plus.svg";
// import { ReactComponent as UserX } from "../../../assets/images/icons/user_x.svg";

class AllContrAgents extends Component {
  state = {
    loading: false,
    tab: 1,
    page: 1,
    total: 0,
    from: 0,
    to: 0,
    current_page: 0,
    last_page: 0,
    pageSize: 15,
    tableData: [],
    columns: [
      {
        title: "№",
        dataIndex: "key",
        key: "key"
      },
      {
        title: "БИН",
        dataIndex: "bin",
        key: "bin"
      },
      {
        title: "Наименование компании",
        dataIndex: "legal_name",
        key: "legal_name"
      },
      {
        title: "ФИО",
        dataIndex: "fio",
        key: "fio",
        render: (e, row) => row.user && `${row.user.last_name} ${row.user.first_name} ${row.user.middle_name ? row.user.middle_name : ''}`
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (e, row) => row.user ? row.user.email : ''
      }
    ]
  };

  switchAdmin = (id) => {
    switchAdminUser(id).then(res => {
      message.success('Роль изменена');
      this.getUsers();
    })
  }
  confirmDelete = (id) => {
    deleteUser(id).then(res => {
      message.success('Пользователь удален');
      this.getUsers();
    })
  }
  renderRole = (e) => {
    switch (e) {
      case 'user':
        return 'Пользователь';
      case 'root':
        return 'Супер администратор'
      case 'admin':
        return 'Администратор'
      default:
        return 'Нет роли'
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.state.pageSize !== prevState.pageSize) || (this.state.page !== prevState.page) || (this.props.updater !== prevProps.updater)) {
      this.getUsers();
    }
  }

  changeCountPage = pageSize => {
    this.setState({
      pageSize
    });
  };

  changePage = page => {
    this.setState({
      page
    });
  };

  getUsers = () => {
    const {page, pageSize} = this.state;
    this.setState({
      loading: true
    });
    getAllContrAgentsList(page, pageSize).then(res => {
      const mappedData = res.data.data.data;
      mappedData.forEach((el, i) => {
        el.key = ++i + ((page - 1) * pageSize);
      });
      this.setState({
        tableData: mappedData,
        total: res.data.data.total,
        from: res.data.data.from,
        to: res.data.data.to,
        current_page: res.data.data.current_page,
        last_page: res.data.data.last_page,
        loading: false
      });
    }, err => {
      this.setState({
        loading: false
      });
    });
  };

  componentDidMount() {
    this.getUsers();
  }

  accept = id => {
    acceptRequsetUser(id).then(res => {
      this.getUsers();
      message.success("Пользователь принят");
    });
  };

  reject = id => {
    rejectRequsetUser(id).then(res => {
      this.getUsers();
      message.success("Пользователь отклонен");
    });
  };

  render() {
    const {tableData, columns, total, from, to, current_page, last_page, loading} = this.state;
    return (<div>
      <Table
        bordered className={"myProjectsCell usersTable"}
        dataSource={tableData}
        loading={loading}
        columns={columns}
        pagination={false}
        locale={{ emptyText: <Empty description={'Нет данных'}/> }}
      />
      <VpPagination
        changeCountPage={this.changeCountPage}
        changePage={this.changePage}
        total={total}
        from={from}
        to={to}
        current_page={current_page}
        last_page={last_page}/>
    </div>);
  }
}

export default AllContrAgents;
