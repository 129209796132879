import React, { Component } from "react";
import {
    Avatar,
    Button,
    Card,
    Col,
    message,
    Row,
    Table,
    Empty,
    Modal
} from "antd";
import { ReactComponent as Edit2 } from "../../assets/images/icons/edit-2.svg";
import { ReactComponent as Down } from "../../assets/images/icons/down.svg";
import { ReactComponent as AccountPlus } from "../../assets/images/icons/account_plus_outline.svg";
import "./myprojects.scss";
import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import {
    addFile,
    BackendUrl,
    getProject,
    changeProjectStatus,
    postProjectsComment,
    getProjectComments,
    editProjectComment,
    deleteProjectComment,
    getProfileById,
} from "../../actions/mainActions";
import { Link } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import CreateEditProject from "./createEditProjects";
import { ProjectProperty } from "./projectProperty";
import Chart from "../Charts/Chart";
import Comment from "../../components/Comment/Comment";

const rolesMap = {
    owner: [
        'access-edit-project',
        'access-end-project',
        'access-fail-project',
        'access-cancel-project',
        'access-freeze-project',
        'access-add-comments-project',
        'access-add-tasks-project',
        'access-add-vechi-project',
        'access-change-members-project',
        'access-change-curators-project',
    ],
    manager: [
        'access-end-project',
        'access-fail-project',
        'access-freeze-project',
        'access-add-comments-project',
        'access-add-tasks-project',
        'access-add-vechi-project',
        'access-change-members-project',
        'access-change-curators-project',
    ],
    member: [
        'access-add-tasks-project'
    ],
    curator: [
        'access-add-tasks-project'
    ]
}

export class ProjectView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataComment: [],
            updateComment: false,
            toggleInput: false,
            comment: null,
            containerForSum: [],
            dataChart: [],
            totalSumOfChart: null,
            id: null,
            loadingChange: false,
            filesList: [],
            filesArr: [],
            edit: false,
            files: [],
            data: {
                fin_sum: 0
            },
            managers: [{
                id: 0,
                first_name: '',
                last_name: '',
            }],
            members: [{
                id: 0,
                first_name: '',
                last_name: '',
            }],
            curators: [],
            tasks: [],
            fin_source: [],
            fin_sum: '',
            defaultFileList: [],
            projectList: [],
            task: {},
            taskVisible: false,
            center: {
                lat: 51.167347,
                lng: 71.561905
            },
            changeLog: [],
            ownerInfo: null,
            role: '',
        }
        window.state = this.state
        this.memberInputRef = React.createRef()
        this.managerInputRef = React.createRef()
    }

    handleEdit = (item) => {
        this.setState({
            id: item.id,
            visible: true
        });
    }

    componentDidMount() {
        this.getProjectDetails();
        this.getProjectComments()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.data !== this.state.data) {
            this.setState((s) => ({
                ...s,
                data: this.state.data
            }))
        }

    }

    renderComment = ({ children, dataComment }) => {
        return
    }
    setChartData = (data) => {
        const { fin_sum } = data.data.data
        this.setState({ totalSumOfChart: fin_sum })
        let container = []
        const filterFunc = (key, value) => {

            const total = value * 100 / fin_sum;

            switch (key) {
                case 'fin_source_sum_budget':
                    return { 'litres': total, 'name': "Бюджет", "value": value }
                case 'fin_source_sum_investments':
                    return { 'litres': total, 'name': 'Инвестиции', "value": value }
                case 'fin_source_sum_local_budget':
                    return { 'litres': total, 'name': "Местный бюджет", "value": value }
                case 'fin_source_sum_other':
                    return { 'litres': total, 'name': 'Другое', "value": value }
                case 'fin_source_sum_republican_budget':
                    return { 'litres': total, 'name': "Республиканский бюджет", "value": value }
                default:
                    break;
            }
        }
        const res = data.data.data
        for (const [key, value] of Object.entries(res)) {

            if (key === 'fin_source_sum_budget' || key === 'fin_source_sum_investments' || key === 'fin_source_sum_local_budget' || key === 'fin_source_sum_other' || key === 'fin_source_sum_republican_budget') {
                container.push(filterFunc(key, value))
            }
        }
        const containerSum = []
        container.forEach(item => {
            if (item.value === null) {
                item.value = 0
            }

            containerSum.push(item.value)

        })
        containerSum.unshift(fin_sum)
        let restOfPercent = containerSum.reduce((a, b) => parseInt(a) - parseInt(b))
        this.setState({
            dataChart: container,
            containerForSum: containerSum,
            restOfPercent
        })
    }

    getProjectDetails = async () => {
        getProject(this.props.id).then(response => {
            const ownerId = response.data.data.owner_id;
            const projectData = response.data.data;

            let ownerInfo;
            this.setChartData(response)

            getProfileById(ownerId).then(response => {
                ownerInfo = response.data.data;
                const currentRole = this.getProjectRole(projectData);

                console.log(`currentRole`)
                console.log(currentRole)

                this.setState((s) => ({
                    ...s,
                    data: projectData,
                    managers: projectData.managers,
                    curators: projectData.curators,
                    members: projectData.members,
                    files: projectData.files,
                    fin_source: projectData.fin_source ? projectData.fin_source : [],
                    tasks: projectData.tasks.map(t => ({ ...t, key: t.id })),
                    loadingChange: false,
                    ownerInfo,
                    role: currentRole,
                }))
    
            })

        })
    }

    getProjectComments = () => {
        getProjectComments(this.props.id).then((res) => {
            const commentTime = res.data.data
            this.setState({ dataComment: commentTime })
        })
    }

    initChangeManager = (item) => {
        this.handleEdit(item)
    }

    initChangeMember = (item) => {
        this.handleEdit(item)
    }

    renderOperateionType = type => {
        switch (type) {
            case "edit":
                return "Отредактировал(а)";
            case "create":
                return "Создал(а)";
            case "delete":
                return "Удалил(а)"
            default:
                return type
        }
    };

    uploadAvatar = ({ onSuccess, onError, file }) => {
        const formData = new FormData();
        formData.append("files[]", file);
        addFile(formData).then(res => {
            onSuccess(file);
            const filesList = this.state.avatarList;
            filesList.push({
                uid: file.uid,
                name: file.name,
                status: "done",
                response: res.data.data[0]
            });
            this.setState({
                avatarList: [...filesList]
            });
        });
    };

    handleCancel = () => {
        this.setState({
            taskVisible: false,
            visible: false
        })
    }

    changeStatus = () => {
        this.setState({
            loadingChange: true
        })
        changeProjectStatus({ id: this.state.data.id }).then(res => {
            message.success(res.data.message);
            this.getProjectDetails();
        })
    }

    handleNewComment = async text => {
        await postProjectsComment({
            project_id: this.state.data.id,
            text
        })
        this.getProjectComments()
    }

    handleReplyComment = async ({ text, commentId }) => {
        await postProjectsComment({
            project_id: this.state.data.id,
            parent_id: commentId,
            text
        })
        this.getProjectComments()
    }

    handleEditComment = async ({ text, commentId }) => {
        await editProjectComment(commentId, { text })
        this.getProjectComments()
    }

    handleDeleteComment = async (commentId) => {
        await deleteProjectComment(commentId)
    }

    getProjectRole = (projectInfo) => {
        const isOwner = this.checkIsOwner(projectInfo.owner_id);

        const isManager = this.checkIsRole(projectInfo.managers);
        const isMember = this.checkIsRole(projectInfo.members);
        const isCurator = this.checkIsRole(projectInfo.curators);

        if (isOwner) {
            return 'owner';
        } else if (isManager) {
            return 'manager';
        } else if (isMember) {
            return 'member';
        } else if (isCurator) {
            return 'curator';
        }

        return '';
    }

    checkIsOwner = (ownerId) => {
        const currentUser = JSON.parse(localStorage.getItem("user"));

        return (ownerId === currentUser.id);
    }

    checkIsRole = (rolesArray) => {
        const currentUser = JSON.parse(localStorage.getItem("user"));
        let isRole = false;

        for (let i = 0; i < rolesArray.length; i++) {
            const user = rolesArray[i];

            if (user.id === currentUser.id) {
                isRole = true;
                break;
            } 
        }

        if (isRole) {
            return true;
        } else {
            return false;
        }
    }
    
    getRoleName = (role) => {
        if (role === 'owner') {
            return 'Создатель проекта'
        } 

        if (role === 'manager') {
            return 'Руководитель'
        }

        if (role === 'curator') {
            return 'Куратор'
        }

        if (role === 'member') {
            return 'Исполнитель (Команда проекта)'
        }

        if (role === '') {
            return 'не участвуете в проекте'
        }
    }

    render() {
        const columns = [
            {
                title: "Название",
                render: (record) => (
                    <Link to={`/board/${record.id}`}>{record.name}</Link>
                )
            },
            {
                title: 'Описание',
                dataIndex: 'description',
                render: (el, record) => (
                    <Link to={`/board/${record.id}`}>{el.substring(0, 140) + '...'}</Link>)
            },
            {
                title: "Дата создания",
                dataIndex: "start",
                render: (value, record) => <Link
                    to={`/board/${record.id}`}>{(moment(value).format('DD.MM.YYYY'))}</Link>
            },
            {
                title: "Дата закрытия",
                dataIndex: "end",
                render: (value, record) => <Link
                    to={`/board/${record.id}`}>{(moment(value).format('DD.MM.YYYY'))}</Link>
            },
            {
                title: "Ответственный",
                dataIndex: "members",
                render: (value) => (
                    value.map((item, i) => <Link key={item.id}
                        to={`/profile/${item.id}`}>{item.first_name} {item.last_name}{value.length === i + 1 ? '' : ', '}</Link>)
                )
            },
            {
                title: "Постановщик",
                dataIndex: "managers",
                render: (value, i) => (
                    value.map((item) => <Link key={item.id}
                        to={`/profile/${item.id}`}>{item.first_name} {item.last_name}{value.length === i + 1 ? '' : ', '}</Link>)
                )
            },
        ];
        return (
            <Modal 
            visible={true}
            width={"60%"}
            footer={null}
            maskClosable={true}
            onCancel={this.props.handleCancel}>
                <Row>
                    <Col style={{ width: 'calc(100% - 320px)', marginRight: '20px' }}>
                        <Card style={{ marginBottom: "35px" }}>
                            <Row style={{flexWrap: 'nowrap'}}>
                                <Col style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        style={{ height: '70px', width: "70px" }}
                                        type='primary'
                                        size='large'
                                        icon={<ArrowLeftOutlined />}
                                        onClick={this.props.handleCancel}
                                    />
                                </Col>
                                <Col>
                                    {(this.state.data.avatar_src) ?
                                        <Avatar style={{ marginLeft: '20px' }} size={80} src={`${BackendUrl}/${this.state.data.avatar_src}`} />
                                        : null}
                                </Col>
                                <Col style={{ textAlign: 'left', paddingLeft: '20px', display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                    <div>
                                        <span style={{ color: '#1890FF', fontSize: '24px' }}>{this.state.data.name}</span><br />
                                        {
                                            this.state.data.project_type && this.state.data.project_type !== 'normal' && <span style={{ color: '#8B96A5', fontSize: '20px' }}>Тип проекта: <span style={{ fontSize: '20px', color: 'rgb(24, 144, 255)' }}>{this.state.data.project_type === 'investment' ? 'Инвестиционный': this.state.data.project_type === 'infrastructure' ? 'Инфраструктурный' : 'Договорный'}</span></span>
                                        }
                                    </div>
                                </Col>
                                <Col style={{ display: 'flex', alignItems: 'center' }}>
                                    {this.state.role && rolesMap[this.state.role].includes('access-edit-project') ? (<Button
                                        style={{ background: '#338DE0', color: '#fff', borderRadius: '8px', height: '40px', width: "40px", display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px' }}
                                        size='large'
                                        icon={<Edit2 style={{ fill: 'white' }} />}
                                        onClick={() => this.handleEdit(this.state.data)}
                                    />) : null}
                                    {this.state.role && rolesMap[this.state.role].includes('access-end-project') ? (<Button style={{ borderRadius: '8px', height: '40px' }} loading={this.state.loadingChange} type={"primary"}
                                        danger={this.state.data.status === 'to_do'} onClick={() => this.changeStatus()}>
                                        {this.state.data.status === 'to_do' ? 'Завершить проект' : 'Возобновить проект'}
                                    </Button>) : null}
                                </Col>
                            </Row>
                        </Card>
                        <Row style={{ textAlign: "left", marginBottom: '20px' }}>
                            <div className="role">{`Вы - ${this.getRoleName(this.state.role)}`}</div>
                        </Row>
                        <Card bordered={false} className='projectCard' style={{ textAlign: "left", padding: '22px', marginBottom: '20px' }}>
                            <h4>Описание</h4>
                            <pre className="description-overflow folded" style={{ overflow: 'hidden' }}>{this.state.data.description}</pre>
                            <Row style={{ justifyContent: "flex-end" }}>
                                <Button className="link-action" onClick={(event) => {
                                    if (document.querySelector('.description-overflow').classList.contains('folded')) {
                                        document.querySelector('.description-overflow').classList.remove('folded')
                                        event.currentTarget.querySelector('span').innerHTML = 'Свернуть'
                                        event.currentTarget.querySelector('svg').classList.add('rotate')
                                    }
                                    else {
                                        document.querySelector('.description-overflow').classList.add('folded')
                                        event.currentTarget.querySelector('span').innerHTML = 'Развернуть'
                                        event.currentTarget.querySelector('svg').classList.remove('rotate')
                                    }
                                }}>
                                    <span style={{ marginRight: '5px' }}>Развернуть</span>
                                    <Down></Down>
                                </Button>
                            </Row>
                        </Card>
                        <Row style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '20px', marginBottom: 20 }}>
                            <Col>
                                {this.state.data.project_type === 'infrastructure' || this.state.data.project_type === null ? // TODO: delete this check null
                                    <>
                                        <ProjectProperty name="ГПРТ" isActive={this.state.data.gprt} comment={this.state.data.gprt_comment} number={this.state.data.gprt_number} date={this.state.data.gprt_date} file={this.state.data.gprt_file} />
                                        <ProjectProperty name="ДКЗ" isActive={this.state.data.dkz} comment={this.state.data.dkz_comment} number={this.state.data.dkz_number} date={this.state.data.dkz_date} file={this.state.data.dkz_file} />
                                        <ProjectProperty name="ТЭО" isActive={this.state.data.teo} comment={this.state.data.teo_comment} number={this.state.data.teo_number} date={this.state.data.teo_date} file={this.state.data.teo_file} />
                                        <ProjectProperty name="ПСД" isActive={this.state.data.psd} comment={this.state.data.psd_comment} number={this.state.data.psd_number} date={this.state.data.psd_date} file={this.state.data.psd_file} />
                                        <ProjectProperty name="Бюджетная заявка" isActive={this.state.data.budget} comment={this.state.data.budget_comment} number={this.state.data.budget_number} date={this.state.data.budget_date} file={this.state.data.budget_file} />
                                        <ProjectProperty name="Статус БЗ" isActive_budget_status={this.state.data.budget_status} comment={this.state.data.budget_status_comment} number={this.state.data.budget_status_number} date={this.state.data.budget_status_date} file={this.state.data.budget_status_file} />
                                        <ProjectProperty name="Конкурсные процедуры" isActive={this.state.data.competitive} comment={this.state.data.competitive_comment} number={this.state.data.competitive_number} date={this.state.data.competitive_date} file={this.state.data.competitive_file} />
                                        <ProjectProperty name="СМР" isActive={this.state.data.smr} comment={this.state.data.smr_comment} number={this.state.data.smr_number} date={this.state.data.smr_date} file={this.state.data.smr_file} />
                                    </> : null}
                                {this.state.data.project_type === 'investment' ?
                                    <>
                                        <ProjectProperty name="ЗУ" isActive={this.state.data.zu} comment={this.state.data.zu_comment} number={this.state.data.zu_number} date={this.state.data.zu_date} file={this.state.data.zu_file} />
                                        <ProjectProperty name="ПСД" isActive={this.state.data.psd} comment={this.state.data.psd_comment} number={this.state.data.psd_number} date={this.state.data.psd_date} file={this.state.data.psd_file} />
                                        <ProjectProperty name="ГИП" isActive={this.state.data.gip} comment={this.state.data.gip_comment} number={this.state.data.gip_number} date={this.state.data.gip_date} file={this.state.data.gip_file} />
                                        <ProjectProperty name="ФЭО" isActive={this.state.data.feo} comment={this.state.data.feo_comment} number={this.state.data.feo_number} date={this.state.data.feo_date} file={this.state.data.feo_file} />
                                        <ProjectProperty name="Бюджетная заявка" isActive={this.state.data.budget} comment={this.state.data.budget_comment} number={this.state.data.budget_number} date={this.state.data.budget_date} file={this.state.data.budget_file} />
                                        <ProjectProperty name="Фин эконом модель" isActive={this.state.data.fin_econom} comment={this.state.data.fin_econom_comment} number={this.state.data.fin_econom_number} date={this.state.data.fin_econom_date} file={this.state.data.fin_econom_file} />
                                        <ProjectProperty name="Маркетинг План" isActive={this.state.data.marketing_plan} comment={this.state.data.marketing_plan_comment} number={this.state.data.marketing_plan_number} date={this.state.data.marketing_plan_date} file={this.state.data.marketing_plan_file} />
                                        <ProjectProperty name="Текущее состояние" isActive={this.state.data.current_status} comment={this.state.data.current_status_comment} number={this.state.data.current_status_number} date={this.state.data.current_status_date} file={this.state.data.current_status_file} />
                                    </> : null}

                            </Col>
                            <Col>
                                <Card bordered={false} className='projectCard' style={{ textAlign: "left", padding: '22px', marginBottom: '20px' }}>
                                    <Row style={{ justifyContent: 'space-between' }}>
                                        <Col>
                                            <h4>Сумма:</h4>
                                        </Col>
                                        <Col>{this.state.data.fin_sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} тенге</Col>
                                    </Row>
                                    <Row style={{ justifyContent: 'space-between' }}>
                                        <Col>
                                            <h4>Источник финансирования:</h4>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                    <Row style={{ justifyContent: 'space-between', marginBottom: 10 }}>
                                        <Col></Col>
                                        <Col>
                                            <Chart containerForSum={this.state.containerForSum}
                                                dataChart={this.state.dataChart}
                                                totalSumOfChart={this.state.totalSumOfChart} />

                                        </Col>
                                    </Row>
                                    <Row style={{ justifyContent: 'space-between' }}>
                                        <Col>
                                            <h4>% выполнения:</h4>
                                        </Col>
                                        <Col>{this.state.data.perform_percent}%</Col>
                                    </Row>
                                    <Row style={{ justifyContent: 'space-between' }}>
                                        <Col>
                                            <h4>Остаток:</h4>
                                        </Col>
                                        <Col>{this.state.restOfPercent}</Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{ width: '300px' }}>
                        <Card bordered={false} className='projectCard' style={{ textAlign: "left", padding: '22px', marginBottom: '20px' }}>
                            <h3>О проекте</h3>
                            <div className="line"></div>
                            <Row style={{ justifyContent: 'space-between' }}>
                                <Col>
                                    <h4>Дата создания</h4>
                                </Col>
                                <Col>{moment(this.state.data.created_at).format('DD.MM.YYYY')}</Col>
                            </Row>
                            <Row style={{ justifyContent: 'space-between' }}>
                                <Col>
                                    <h4>Дата начала</h4>
                                </Col>
                                <Col>{moment(this.state.data.start).format('DD.MM.YYYY')}</Col>
                            </Row>
                            <Row style={{ justifyContent: 'space-between' }}>
                                <Col>
                                    <h4>Дата окончания</h4>
                                </Col>
                                <Col>{moment(this.state.data.end).format('DD.MM.YYYY')}</Col>
                            </Row>
                            <div className="line"></div>
                            <Row style={{ justifyContent: 'space-between' }}>
                                <Col>
                                    <h4>Статус:</h4>
                                </Col>
                                <Col>Открытая группа</Col>
                            </Row>
                            <Row style={{ justifyContent: 'space-between' }}>
                                <Col>
                                    <h4>Всего задач</h4>
                                </Col>
                                <Col>{this.state.data.all_tasks_count}</Col>
                            </Row>
                            <h3>Участники проекта</h3>
                            <div className="line"></div>
                            <div className="members">

                                <div className="member">
                                    <div className="position" style={{ marginBottom: 10 }}>{'Создатель проекта'}</div>
                                    {this.state.ownerInfo && 
                                        (<div style={{ padding: '0 18px', display: 'flex', flexDirection: 'column'  }} data-value={this.state.ownerInfo.id} key={this.state.ownerInfo.id}>
                                        <span>{this.state.ownerInfo.last_name + ' ' + this.state.ownerInfo.first_name}</span>
                                        <span style={{fontWeight: 400, fontSize: '14px', color: '#96A0B5'}}>{this.state.ownerInfo.organization_name}</span>
                                        </div>)
                                    }

                                </div>

                                <div className="member">
                                    <div className="position" style={{ marginBottom: 10 }}>{this.state.managers.length > 1 ? 'Руководители' : 'Руководитель'}</div>
                                    {
                                        this.state.managers.map((manager) => 
                                            <div style={{ padding: '0 18px', display: 'flex', flexDirection: 'column'  }} data-value={manager.id} key={manager.id}>
                                                <span>{manager.last_name + ' ' + manager.first_name}</span>
                                                <span style={{fontWeight: 400, fontSize: '14px', color: '#96A0B5'}}>{manager.organization_name}</span>
                                            </div>
                                        )
                                    }
                                    {this.state.role && rolesMap[this.state.role].includes('access-edit-project') ? (<div className="position-action">
                                        <Button onClick={() => this.initChangeManager(this.state.data)} className="link-action">
                                            <span style={{ marginRight: '5px' }}>Добавить</span>
                                            <AccountPlus />
                                        </Button>
                                    </div>) : null}
                                </div>
                                <div className="member">
                                    <div className="position" style={{ marginBottom: 10 }}>{this.state.curators.length > 1 ? 'Кураторы' : 'Куратор'}</div>
                                    {
                                        this.state.curators.map((member) =>
                                            <div style={{ padding: '0 18px', display: 'flex', flexDirection: 'column' }} key={member.id}>
                                                <span>{member.last_name + ' ' + member.first_name}</span>
                                                <span style={{fontWeight: 400, fontSize: '14px',  color: '#96A0B5'}}>{member.organization_name}</span>
                                            </div>
                                        )
                                    }
                                    {this.state.role && rolesMap[`${this.state.role}`].includes('access-change-curators-project') ? (<div className="position-action">
                                        <Button onClick={() => this.initChangeMember(this.state.data)} className="link-action">
                                            <span style={{ marginRight: '5px' }}>Добавить</span>
                                            <AccountPlus />
                                        </Button>
                                    </div>) : null}
                                </div>
                                <div className="member">
                                    <div className="position" style={{ marginBottom: 10 }}>{this.state.members.length > 1 ? 'Команда проекта' : 'Команда проекта'}</div>
                                    {
                                        this.state.members.map((member) =>
                                            <div style={{ padding: '0 18px', display: 'flex', flexDirection: 'column' }} key={member.id}>
                                                <span>{member.last_name + ' ' + member.first_name}</span>
                                                <span style={{fontWeight: 400, fontSize: '14px',  color: '#96A0B5'}}>{member.organization_name}</span>
                                            </div>
                                        )
                                    }
                                    {this.state.role && rolesMap[this.state.role].includes('access-change-members-project') ? (<div className="position-action">
                                        <Button onClick={() => this.initChangeMember(this.state.data)} className="link-action">
                                            <span style={{ marginRight: '5px' }}>Добавить</span>
                                            <AccountPlus />
                                        </Button>
                                    </div>) : null}
                                </div>
                            </div>
                            {this.state.files.length > 0 && <h3>Файл проекта</h3>}
                            <div className="line"></div>
                            {
                                this.state.files.map((file) =>
                                    <a key={file.id} className="link-action" href={BackendUrl + file.src} target="_blank" rel="noopener noreferrer" download>
                                        <span>{file.name}</span>
                                    </a>
                                )
                            }
                            <a href={`${BackendUrl}/api/v1/project/${this.state.data.id}/report`} target="_blank" rel="noopener noreferrer" className="download-report">
                                Скачать отчет
                            </a>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '35px', width: '100%', display: 'grid' }}>
                    <h2 style={{ textAlign: 'left' }}>Задачи проекта</h2>
                    <Table
                        className="myProjectsCell"
                        columns={columns}
                        dataSource={this.state.tasks}
                        pagination={false}
                        locale={{ emptyText: <Empty description={'Нет данных'}/>}}
                    />
                </Row>
                <Row className="comments-block" style={{ marginBottom: '35px' }}>
                    <h2 style={{ textAlign: 'left' }}>Комментарии</h2>
                </Row>
                <Comment
                    commentList={this.state.dataComment}
                    onNewComment={this.handleNewComment}
                    onDeleteComment={this.handleDeleteComment}
                    onEditComment={this.handleEditComment}
                    onReplyComment={this.handleReplyComment}
                    canComment={this.state.role && rolesMap[this.state.role].includes('access-add-comments-project') ? true : false}
                />
                {
                    this.state.visible && 
                    <CreateEditProject visible={this.state.visible}

                        editId={this.state.id}
                        handleCancel={this.handleCancel}
                        getListProjects={this.getProjectDetails}
                    />
                }

            </Modal>
        )
    }
}


export const ProjectComments = ({ children, dataComment, getCommentId, commentId, first_name, avatar, text, child_comments }) => {
    return <Comment
        actions={[<label for='comment'><span className='reply' key="comment-list-reply-to-0" onClick={(e) => {
            getCommentId(commentId)
        }}>Ответить</span></label>,<label for='comment'><span className='edit' key="comment-basic-edit" onClick={()=>{getCommentId(commentId,text)}}
        >Редактировать</span></label>,<span className='edit' key="comment-basic-delete" onClick={()=>{getCommentId(commentId,null,'Delete') }}>Удалить</span>]}
        author={first_name}
        avatar={<Avatar src={avatar ? `https://api.project.qaztourism.kz${avatar}` : null} icon={<UserOutlined />} />}
        content={text}
        locale={{ emptyText: 'Нет комментариев' }}
    >
        {children}
    </Comment>
}