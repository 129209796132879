import React, { useState,useEffect } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4ThemesAnimated from "@amcharts/amcharts4/themes/animated";

function Chart(props) {
    am4core.useTheme(am4ThemesAnimated);
// Themes end
 console.log('props.dataChart',props.dataChart)
// Create chart instance
let chart = am4core.create("chartdiv", am4charts.PieChart);

// Add data
const [dataOfChart,setDataOfChart] = useState(null)
const [totalSum,setTotalSum] = useState(10)

useEffect(()=>{
  setDataOfChart(props.dataChart)
  
},[props.dataChart])

useEffect(()=>{

function numberWithCommas(num=10) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
  setTotalSum(numberWithCommas(props.totalSumOfChart ?props.totalSumOfChart: 10))
  
  
  

},[props.totalSumOfChart])


chart.data =dataOfChart 

    // Set inner radius
am4core.addLicense('ch-custom-attribution')
chart.radius = am4core.percent(55)
chart.innerRadius = am4core.percent(80)
// Add and configure Series
let pieSeries = chart.series.push(new am4charts.PieSeries());

pieSeries.dataFields.value = "litres";
pieSeries.dataFields.category = "name";

pieSeries.labels.template.disabled = true;
pieSeries.ticks.template.disabled = true;
chart.legend = new am4charts.Legend();
chart.legend.position = "bottom";

let label = pieSeries.createChild(am4core.Label);
label.text = totalSum.toLocaleString("en-US");
label.horizontalCenter = "middle";
label.verticalCenter = "middle";
label.fontSize = 30;

pieSeries.slices.template.stroke = am4core.color('#F2F7FF')
    pieSeries.slices.template.strokeWidth = 7
    pieSeries.slices.template.strokeOpacity = 1
    pieSeries.slices.template.tooltipText = ''
    pieSeries.labels.template.disabled = true
    pieSeries.ticks.template.disabled = true
    pieSeries.slices.template.cornerRadius = 15
    // pieSeries.slices.template.innerCornerRadius = 7

    pieSeries.colors.list = [
      am4core.color('#338DE0'),
      am4core.color('#82BFF6'),
      am4core.color('#CBE6FF'),
      am4core.color("#845EC2"),
  am4core.color("#D65DB1"),
  am4core.color("#FF6F91"),
  am4core.color("#FF9671"),
  am4core.color("#FFC75F"),
  am4core.color("#F9F871")
    ]

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1
    pieSeries.hiddenState.properties.endAngle = -90
    pieSeries.hiddenState.properties.startAngle = -90
    return (
      <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
    )
}

export default Chart



/* Chart code */
// Themes begin


