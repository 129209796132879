import React, { Component } from "react";

// Styles
import './finishedProject.scss'

// Icons & Images
import {ReactComponent as IconArrowToRight} from "../../../assets/images/icons/icon-arrow-right.svg";
import {ReactComponent as IconCalendar} from "../../../assets/images/icons/finished-projects/calendar.svg";
import {ReactComponent as IconPrice} from "../../../assets/images/icons/finished-projects/price.svg";
import {ReactComponent as IconRegion} from "../../../assets/images/icons/finished-projects/region.svg";

// Components
import Footer from "../../../components/uiLanding/layouts/Footer/footer";
import Header from "../../../components/uiLanding/layouts/Header/header";

import { getFinishedProject } from "../../../actions/projects-finished-list"
import { Link } from "react-router-dom";

class LandingFinishedProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: 'map'
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  viewChange = (type) => {
    this.setState({ viewType: type })
  }

  
  render() {
    const project = getFinishedProject(this.props.match.params.project_id)
    return (
      <div className="projectFinished-landing">
        <Header />

        <div className="projectFinished-landing-block">
          <div className="container">
            <div className="bread-crumbs"><Link to='/'><IconArrowToRight /> Назад</Link></div>
            <h2 className="landing-title">{ project.title }</h2>

            <div className="projectFinished-landing-inner">
              <div className="projectFinished-landing-content">
                <div className="projectFinished-landing-table">

                  <div className="projectFinished-landing-item">
                    <IconCalendar />
                    <div className="projectFinished-landing-rows">
                      <div className="projectFinished-landing-row">
                        <div className="title">Период начала</div>
                        <div className="value">{ project.info.calendar.start }</div>
                      </div>
                      <div className="projectFinished-landing-row">
                        <div className="title">Период завершения</div>
                        <div className="value">{ project.info.calendar.end }</div>
                      </div>
                    </div>
                  </div>

                  <div className="projectFinished-landing-item">
                    <IconPrice />
                    <div className="projectFinished-landing-rows">
                      <div className="projectFinished-landing-row">
                        <div className="title">Стоимость проекта, млн. тенге</div>
                        <div className="value">{ project.info.price }</div>
                      </div>
                    </div>
                  </div>

                  <div className="projectFinished-landing-item">
                    <IconRegion />
                    <div className="projectFinished-landing-rows">
                      <div className="projectFinished-landing-row">
                        <div className="title">Регион</div>
                        <div className="value">{ project.info.place.region }</div>
                      </div>
                      <div className="projectFinished-landing-row">
                        <div className="title">Район/город</div>
                        <div className="value">{ project.info.place.city }</div>
                      </div>
                      <div className="projectFinished-landing-row">
                        <div className="title">Количество номеров</div>
                        <div className="value">{ project.info.place.rooms }</div>
                      </div>
                      <div className="projectFinished-landing-row">
                        <div className="title">Койко-места</div>
                        <div className="value">{ project.info.place.enoughBeds }</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <img className="projectFinished-landing-preview" alt='img' src={require('../../../assets/images/tmp/finished-projects/project-' + project.id + '.jpg')} />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default LandingFinishedProject;
