import React, {Component} from "react";
import {Row, Col, Avatar, Input, Button, message, Form, Card, Upload} from "antd";
import {addFile, putProfile, getProfile, getProfileById, BackendUrl} from "../../actions/mainActions";
import {UserOutlined, PlusCircleOutlined} from "@ant-design/icons";
import { ReactComponent as Edit } from "../../assets/images/icons/edit.svg";
import "./profile.scss";

class Profile extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            first_name: "",
            middle_name: "",
            position_name: "",
            organization_name: "",
            last_name: "",
            email: "",
            phone: "",
            avatar: {},
            avatarList: [],
            viewOnly: true,
            edit: true
        };
    }

    componentDidMount() {
        // this.setState({
        //   first_name: localStorage.getItem("first_name"),
        //   last_name: localStorage.getItem("last_name"),
        //   middle_name: localStorage.getItem("middle_name"),
        //   email: localStorage.getItem("email")
        // });
        const currUserID = JSON.parse(localStorage.getItem('user')).id;
        const path = window.location.pathname;
        const arrayPath = path.split('/');
        if (!!arrayPath[2] && arrayPath[2] !== String(currUserID)) {
            this.setState({
                viewOnly: true
            })
            this.getProfileDataById(arrayPath[2]);
        } else {
            this.getProfileData();
        }
    }

    getProfileDataById = (id) => {
        getProfileById(id).then((response) => {
            this.setState({
                viewOnly: true,
                first_name: response.data.data.first_name,
                last_name: response.data.data.last_name,
                middle_name: response.data.data.middle_name,
                email: response.data.data.email,
                phone: response.data.data.phone,
                organization_name: response.data.data.organization_name,
                avatar: response.data.data.avatar,
                position_name: response.data.data.position_name
            });
        });
    };
    getProfileData = () => {
        const {setNewUserProfile} = this.props;
        getProfile().then((response) => {
            localStorage.setItem("user", JSON.stringify(response.data.data));
            setNewUserProfile();
            const initialValues = response.data.data;
            this.formRef.current.setFieldsValue({...initialValues});
            this.setState({
                viewOnly: false,
                first_name: response.data.data.first_name,
                last_name: response.data.data.last_name,
                middle_name: response.data.data.middle_name,
                email: response.data.data.email,
                phone: response.data.data.phone,
                organization_name: response.data.data.organization_name,
                position_name: response.data.data.position_name,
                avatar: response.data.data.avatar
            });
        });
    };
    handleEdit = () => {
        this.setState({
            edit: !this.state.edit
        });

    };
    uploadAvatar = ({onSuccess, onError, file}) => {
        const formData = new FormData();
        formData.append("files[]", file);
      addFile(formData).then(res => {
            onSuccess(file);
            const filesList = this.state.avatarList;
            filesList.push({
                uid: file.uid,
                name: file.name,
                status: "done",
                response: res.data.data.src[0]
            });
            this.setState({
                avatarList: [...filesList],
                avatar: res.data.data
            });
        });


    };

    handleOk = (e) => {
        this.setState({
            loading: true
        });
        this.setState({...e});
        e.avatar = this.state.avatarList[0] ? String(this.state.avatarList[0].response) : this.state.avatar;

        /*      objToSave.avatar = 'avatar.png';*/
        /*     if (this.state.avatarList.length > 0) {
                 objToSave.avatar = this.state.avatarList[0];
             } else {
                 message.error('Добавьте изображние (аватар) проекта');
                 return false
             }*/
        /*        if (this.state.filesList.length > 0) {
                    objToSave.files = this.state.filesList;
                } else {
                    message.error('Добавьте файлы (изображния, документы) проекта');
                    return false
                }*/
        putProfile(e).then(res => {
            message.success("Обновления сохранены");
            this.setState({
                loading: false,
                avatarList: []
            });
            this.handleEdit();
            this.getProfileData();
        }, err => {
            this.setState({
                loading: false
            })
        });
    };

    render() {
        return (
            <div className={"profilePage"}>
                <Row>
                    <h1 style={{fontWeight: "bold", fontSize: "24px"}} className="componentTitle">ПРОФИЛЬ</h1>
                </Row>
                <Row gutter={14} style={{marginBottom: "14px"}}>
                    <Col span={8}>
                        <Card className="card defaultBg">
                            <h3>Фотография пользователя</h3>
                        </Card>
                    </Col>
                    <Col span={16}>
                        <Card className="card defaultBg">
                            <h3 style={{float: "left"}}>Контактная информация</h3>
                            {!this.state.viewOnly && <Button
                                icon={<Edit/>}
                                style={{border:'none',float:'right', marginRight:"10%" }}
                                onClick={this.handleEdit}
                            > <span style={{textDecoration:'underline', fontSize:'16px', marginLeft:'10px'}}>Редактировать</span> </Button>}

                        </Card>

                    </Col>
                </Row>
                <Row gutter={14}>
                    <Col span={8}>
                        <Card className="card2 defaultBg">
                            <Avatar style={{marginBottom: "30px"}} size={250} icon={<UserOutlined/>}
                                    src={`${BackendUrl}/${this.state.avatar}`}/>
                            <Upload
                                listType="text"
                                fileList={this.state.avatarList}
                                customRequest={this.uploadAvatar}
                                onRemove={() => this.setState({
                                    fileList: []
                                })}
                            >  {
                                this.state.edit ? null :
                                    <Button className={"pl-0"} size="large"
                                            icon={<PlusCircleOutlined/>}
                                            type={"link"}>
                                        Загрузить изображение
                                    </Button>}</Upload>
                        </Card>
                    </Col>
                    <Col span={16}>
                        <Card className="card2 defaultBg">
                            <Form
                                ref={this.formRef}
                                //style={{marginLeft: '100px'}}
                                name="basic"
                                initialValues={this.state}
                                onFinish={this.handleOk}
                            >
                                <Row gutter={14}>
                                    <Col span={8}><span style={{float: "left"}}><h3>Фамилия</h3></span></Col>
                                    <Col span={12} className={'text-left'}>
                                        <Form.Item
                                            name='last_name'
                                        >
                                            {this.state.edit ?
                                                this.state.last_name :

                                                <Input
                                                    placeholder='Введите фамилию'
                                                    value={this.state.last_name}
                                                    size='large'
                                                />

                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={14}>

                                    <Col span={8}><span style={{float: "left"}}> <h3>Имя </h3></span></Col>
                                    <Col span={12} className={'text-left'}>
                                        <Form.Item
                                            name='first_name'
                                        >
                                            {this.state.edit ? this.state.first_name :

                                                <Input
                                                    placeholder='Введите имя'
                                                    value={this.state.first_name}
                                                    size='large'
                                                />

                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={14}>
                                    <Col span={8}><span style={{float: "left"}}> <h3> Отчество </h3></span></Col>
                                    <Col span={12} className={'text-left'}>
                                        <Form.Item

                                            name='middle_name'
                                        >
                                            {this.state.edit ? this.state.middle_name :

                                                <Input
                                                    placeholder='Введите Отчество'

                                                    value={this.state.middle_name}
                                                    size='large'
                                                />

                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={14}>
                                    <Col span={8}><span style={{float: "left"}}><h3>Контактый Email</h3></span></Col>
                                    <Col span={12} className={'text-left'}>
                                        <Form.Item
                                            name='email'
                                        >
                                            {this.state.edit ?
                                                this.state.email :

                                                <Input
                                                    placeholder='Введите email'
                                                    value={this.state.email}
                                                    size='large'
                                                />

                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={14}>
                                    <Col span={8}><span style={{float: "left"}}><h3>Контактый телефон</h3></span></Col>
                                    <Col span={12} className={'text-left'}>
                                        <Form.Item
                                            name='phone'
                                        >
                                            {this.state.edit ?
                                                this.state.phone :
                                                <Input
                                                    placeholder='Введите номер телефона'
                                                    value={this.state.phone}
                                                    size='large'
                                                />
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={14}>
                                    <Col span={8}><span style={{float: "left"}}><h3>Должность</h3></span></Col>
                                    <Col span={12} className={'text-left'}>
                                        <Form.Item
                                            name='position_name'
                                        >
                                            {this.state.edit ?
                                                this.state.position_name :

                                                <Input
                                                    placeholder='Ваша должность в компании'
                                                    value={this.state.position_name}
                                                    size='large'
                                                />

                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={14}>
                                    <Col span={8}><span style={{float: "left"}}><h3>Компания</h3></span></Col>
                                    <Col span={12} className={'text-left'}>
                                        <Form.Item
                                            name='organization_name'
                                        >
                                            {this.state.organization_name }
                                        </Form.Item>
                                    </Col>
                                </Row>



                                {/* <Row gutter={24} style={{marginBottom:'25px'}}>
                    <Col span={4}><span style={{float:'left'}}><h3>Мобильный телефон</h3></span></Col>
                        <Col></Col>
                    </Row> */}

                                {!this.state.edit &&
                                <Row gutter={16}>
                                    <Col span={5}>
                                        <Form.Item>
                                            <Button
                                                size='large'
                                                type='primary'
                                                htmlType='submit'
                                                loading={this.state.loading}
                                            >Сохранить</Button>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Button
                                            size='large'
                                            onClick={this.handleEdit}
                                        >Отмена</Button>
                                    </Col>
                                </Row>

                                }

                                {/* <Row gutter={24} style={{ marginBottom: "25px" }}></Row>
              <Row gutter={24} style={{ marginBottom: "25px" }}></Row> */}
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Profile;
