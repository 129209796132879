import React, {Component} from "react";
import {Button, Col, Form, Input, message, Row, Select, Upload} from "antd";
import {ArrowRightOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {addFile, getMembers, getProjects, getProblemById, BackendUrl} from "../../actions/mainActions";
import ErrorMap from "../ProjectsMap/errorMap";

const {TextArea} = Input;
const {Option} = Select;

class CreateAndViewProblem extends Component {
    formRef = React.createRef();
    state = {
        allMembers: [],
        filesList: [],
        managers: [],
        loading: false,
        allProjects: [],
        tasks: [],
        coords: [],
        initialValues: {
            from: [JSON.parse(localStorage.getItem("user")).id],
            status: "need_help"
        },
        statusList: [{
            value: "need_help",
            name: "Нужна помощь"
        },
            {
                value: "in_progress",
                name: "В разработке"
            },
            {
                value: "resolved",
                name: "Проблема решена"
            }]
    };

    setNewErrorPoint = coords => {
        this.setState({
            coords
        });
    };
    handleOk = (e) => {
        const objToSave = e;
        objToSave.filesArr = [];
        this.state.filesList.forEach(el => {
            objToSave.filesArr.push(el.response);
        });
        objToSave.project_id = String(e.project_id);
        objToSave.task_id = String(e.task_id);
        objToSave.map_object_json = JSON.stringify(this.state.coords);
        this.props.createProblem(objToSave);
    };

    removeDocuments = (e) => {
        const filesList = [...this.state.filesList];
        const index = filesList.findIndex(el => el.uid === e.uid);
        if (index > -1) {
            filesList.splice(index, 1);
        }
        this.setState({
            filesList
        })
    }

    componentDidMount() {
        this.getAllUsers();
        this.getAllProject();
        this.props.problemId && this.getProblemDetails();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.problemId !== prevProps.problemId) {
            this.props.problemId ? this.getProblemDetails() : this.formRef.current.resetFields();
        }
    }

    getAllUsers = () => {
        getMembers().then((response) => {
            this.setState({
                allMembers: response.data.data
            });
        });
    };

    getProblemDetails = () => {
        getProjects().then(response => {
            this.setState({
                allProjects: response.data.data
            });
            getProblemById(this.props.problemId).then(res => {
                const problemValues = res.data.data;
                problemValues.to = res.data.data.to.map(el => el.id);
                problemValues.from = res.data.data.from.map(el => el.id);
                const filesList = res.data.data.files.map(el => {
                    return {
                        uid: el.id,
                        name: el.name,
                        status: "done",
                        response: el.id,
                        url: BackendUrl + el.src
                    }
                })
                this.projectChange(problemValues.project_id);
                this.formRef.current.setFieldsValue(problemValues);
                this.setState({
                    coords: JSON.parse(res.data.data.map_object_json),
                    filesList
                })

            })
        });

    }

    getAllProject = () => {
        getProjects().then(response => {
            this.setState({
                allProjects: response.data.data
            });
        });
    };

    uploadFile = ({onSuccess, onError, file}) => {
        const key = "updatable";
        message.loading({content: "Загрузка файла...", key});
        const formData = new FormData();
        formData.append("files[]", file);
        addFile(formData).then(res => {
            message.success({content: "Файл загружен!", key, duration: 2});
            onSuccess(file);
            const filesList = this.state.filesList;
            filesList.push({
                uid: file.uid,
                name: file.name,
                status: "done",
                response: res.data.data.ids[0],
                url: BackendUrl + res.data.data.src[0]
            });
            this.setState({
                filesList: [...filesList]
            });
        }, err => {
            message.error({content: "Ошибка при загрузке!", key, duration: 2});
        });
    };

    projectChange = (e) => {
        const projects = [...this.state.allProjects];
        const tasks = projects.find(el => el.id === e).tasks;
        this.setState({
            tasks
        });
    };

    render() {
        const {filesList, initialValues} = this.state;
        return (
            <div>
                <Form

                    className="problemForm"
                    ref={this.formRef}
                    initialValues={initialValues}
                    style={{marginLeft: "100px"}}
                    name="basic"
                    onFinish={this.handleOk}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Row gutter={12} style={{marginBottom: "25px"}}>
                        <Col span={6}>
                            <h3>Причина</h3>
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Введите название задачи/проблемы"
                                    }
                                ]}>
                                <Input
                                    readOnly={this.props.problemId}
                                    size="large"
                                    placeholder="Введите название задачи/проблемы"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} style={{marginBottom: "25px"}}>
                        <Col span={6}>
                            <h3>Описание</h3>
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: "Введите описание проблемы"
                                    }
                                ]}>
                                <TextArea
                                    rows={4}
                                    readOnly={this.props.problemId}
                                    placeholder="Введите описание проекта"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} style={{marginBottom: "25px", alignItems: "center"}}>
                        <Col span={6}>
                            <h3>
                                Проект{" "}
                                <ArrowRightOutlined
                                    style={{float: "right"}}
                                />
                            </h3>{" "}
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="project_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Выберите проект"
                                    }
                                ]}>
                                <Select disabled={this.props.problemId}
                                        onChange={(e) => this.projectChange(e)}>
                                    {this.state.allProjects.map((item, key) => (
                                        <Option key={key} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={12} style={{marginBottom: "25px", alignItems: "center"}}>
                        <Col span={6}>
                            <h3>
                                Задача{" "}
                                <ArrowRightOutlined
                                    style={{float: "right"}}
                                />
                            </h3>{" "}
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="task_id"
                                rules={[
                                    {
                                        required: false,
                                        message: "Выберите задачу"
                                    }
                                ]}>
                                <Select
                                    disabled={this.props.problemId}>
                                    {this.state.tasks.map((item, key) => (
                                        <Option key={key} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={12} style={{marginBottom: "25px"}}>
                        <Col span={6}>
                            <h3>Прикрепить Файл</h3>
                        </Col>
                        <Col span={8}>
                            <Upload
                                disabled={this.props.problemId}
                                listType="text"
                                onRemove={this.removeDocuments}
                                fileList={this.state.filesList}
                                customRequest={this.uploadFile}
                            >
                                {filesList.length >= 8 ? null :
                                    <Button className={"pl-0"} size="large"
                                            icon={<PlusCircleOutlined/>}
                                            type={"link"}>
                                        Загрузить файл
                                    </Button>}
                            </Upload>
                        </Col>
                    </Row>

                    <Row gutter={12} style={{marginBottom: "25px", alignItems: "center"}}>
                        <Col span={6}>
                            <h3>
                                Статус{" "}
                                <ArrowRightOutlined
                                    style={{float: "right"}}
                                />
                            </h3>{" "}
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="status"
                                rules={[
                                    {
                                        required: true,
                                        message: "Выберите статус"
                                    }
                                ]}>
                                <Select disabled={this.props.problemId}>
                                    {this.state.statusList.map((item, key) => (
                                        <Option key={key} value={item.value}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={12} style={{marginBottom: "25px", alignItems: "center"}}>
                        <Col span={6}>
                            <h3>
                                Автор заявки{" "}
                                <ArrowRightOutlined
                                    style={{float: "right"}}
                                />
                            </h3>{" "}
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="from"
                                rules={[
                                    {
                                        required: true,
                                        message: "Выберите автора"
                                    }
                                ]}>
                                <Select
                                    disabled={this.props.problemId}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    placeholder="Выберите автора"
                                    size="large"
                                    mode="multiple"
                                    // onChange={(e) => {
                                    // }}
                                    style={{width: "100%"}}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.join(" ").toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.allMembers.map((item, key) => (
                                        <Option key={key} value={item.id}>
                                            {item.first_name} {item.last_name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} style={{marginBottom: "25px", alignItems: "center"}}>
                        <Col span={6}>
                            <h3>
                                Адресат{" "}
                                <ArrowRightOutlined
                                    style={{float: "right"}}
                                />
                            </h3>{" "}
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="to"
                                rules={[
                                    {
                                        required: true,
                                        message: "Выберите адресата"
                                    }
                                ]}>
                                <Select
                                    disabled={this.props.problemId}
                                    mode="multiple"
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    placeholder="Выберите адресата"
                                    showSearch
                                    size="large"
                                    style={{width: "100%"}}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.join(" ").toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.allMembers.map((item, key) => (
                                        <Option value={item.id} key={key}>
                                            {item.first_name} {item.last_name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}
                         style={!this.props.problemId || (this.props.problemId && this.state.coords && this.state.coords.length > 0) ? {
                             opacity: '1', height: 'auto'
                         } : {opacity: '0', height: '0'}}>
                        <Col span={24}>
                            <ErrorMap setNewErrorPoint={this.setNewErrorPoint} coords={this.state.coords}/>
                        </Col>
                    </Row>

                    {!this.props.problemId && <Row gutter={12} style={{marginBottom: "25px", alignItems: "center"}}>
                        <Col span={8}>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    style={{width: "100%"}}
                                >
                                    Добавить риск <PlusCircleOutlined/>
                                </Button>
                            </Form.Item>


                        </Col>
                        <Col span={4}>
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.formRef.current.resetFields();
                                    this.props.handleCancel();
                                }}

                                type="link"
                                size="large"
                            >
                                ОТМЕНА
                            </Button>
                        </Col>
                    </Row>}
                </Form>
            </div>
        );
    }
}

export default CreateAndViewProblem;
