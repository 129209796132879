import React, {Component} from "react";
import {
    acceptRequsetUser, getAllUsersList,
    rejectRequsetUser, deleteUser, switchAdminUser
} from "../../../actions/mainActions";
import {Table, Row, Col, message, Popconfirm, Empty} from "antd";
import {ReactComponent as Delete} from "../../../assets/images/icons/delete.svg";
import VpPagination from "../VpPagination";
import {StarOutlined, StarFilled} from '@ant-design/icons';

class AllUsersList extends Component {
    state = {
        loading: false,
        tab: 1,
        page: 1,
        total: 0,
        from: 0,
        to: 0,
        current_page: 0,
        last_page: 0,
        pageSize: 15,
        tableData: [],
        columns: [
            {
                title: "№",
                dataIndex: "key",
                key: "key"
            },
            {
                title: "ФИО",
                dataIndex: "last_name",
                key: "last_name",
                render: (e, row) => `${row.last_name} ${row.first_name} ${row.middle_name ? row.middle_name : ''}`
            },
            {
                title: "Email",
                dataIndex: "email",
                key: "email"
            },
            {
                title: "Роль",
                dataIndex: "role",
                key: "role",
                render: (e) => this.renderRole(e)
            },
            {
                title: "Компания",
                dataIndex: "organization_name",
                key: "organization_name",
                render: (e, row) => row.contractor.legal_name
            },

            {
                title: "Операции",
                dataIndex: "actions",
                key: "actions",
                render: (e, row) => {
                    return <Row gutter={5}>
                        <Col span={24} className={'dFlexCenter'}>


                            {row.role === 'user' && <Popconfirm
                                placement="topRight"
                                title={'Назначить администратором?'}
                                onConfirm={() => this.switchAdmin(row.id)}
                                okText="Подвердить"
                                cancelText="Отмена"
                            >
                                <StarOutlined className={'actionsIcons goldIcon'}/>
                            </Popconfirm>}


                            {row.role === 'admin' &&
                            <Popconfirm
                                placement="topRight"
                                title={'Снять права администратора?'}
                                onConfirm={() => this.switchAdmin(row.id)}
                                okText="Подвердить"
                                cancelText="Отмена"
                            >
                                <StarFilled className={'actionsIcons goldIcon'}/>
                            </Popconfirm>
                            }

                            <Popconfirm
                                placement="topRight"
                                title={'Подтвердить удаление?'}
                                onConfirm={() => this.confirmDelete(row.id)}
                                okText="Удалить"
                                cancelText="Отмена"
                            >
                                {row.role !== 'root' && <Delete className={'actionsIcons redIcon'}/>}
                            </Popconfirm>
                        </Col>
                    </Row>;
                }
            }
        ]
    };

    switchAdmin = (id) => {
        switchAdminUser(id).then(res => {
            message.success('Роль изменена');
            this.getUsers();
        })
    }
    confirmDelete = (id) => {
        deleteUser(id).then(res => {
            message.success('Пользователь удален');
            this.getUsers();
        })
    }
    renderRole = (e) => {
        switch (e) {
            case 'user':
                return 'Пользователь';
            case 'root':
                return 'Супер администратор'
            case 'admin':
                return 'Администратор'
            default:
                return 'Нет роли'
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.state.pageSize !== prevState.pageSize) || (this.state.page !== prevState.page) || (this.props.updater !== prevProps.updater)) {
            this.getUsers();
        }
    }

    changeCountPage = pageSize => {
        this.setState({
            pageSize
        });
    };

    changePage = page => {
        this.setState({
            page
        });
    };

    getUsers = () => {
        const {page, pageSize} = this.state;
        this.setState({
            loading: true
        });
        getAllUsersList(page, pageSize).then(res => {
            const mappedData = res.data.data.data;
            mappedData.forEach((el, i) => {
                el.key = ++i + ((page - 1) * pageSize);
            });
            this.setState({
                tableData: mappedData,
                total: res.data.data.total,
                from: res.data.data.from,
                to: res.data.data.to,
                current_page: res.data.data.current_page,
                last_page: res.data.data.last_page,
                loading: false
            });
        }, err => {
            this.setState({
                loading: false
            });
        });
    };

    componentDidMount() {
        this.getUsers();
    }

    accept = id => {
        acceptRequsetUser(id).then(res => {
            this.getUsers();
            message.success("Пользователь принят");
        });
    };

    reject = id => {
        rejectRequsetUser(id).then(res => {
            this.getUsers();
            message.success("Пользователь отклонен");
        });
    };

    render() {
        const {tableData, columns, total, from, to, current_page, last_page, loading} = this.state;
        return (<div>
            <Table bordered className={"myProjectsCell usersTable"}
                   dataSource={tableData}
                   loading={loading}
                   columns={columns}
                   pagination={false}
                   locale={{ emptyText: <Empty description={'Нет данных'}/> }}/>
            <VpPagination
                changeCountPage={this.changeCountPage}
                changePage={this.changePage}
                total={total}
                from={from}
                to={to}
                current_page={current_page}
                last_page={last_page}/>
        </div>);
    }
}

export default AllUsersList;
