import React, {Component} from "react";
import {Button, Modal, Form, Input, message} from "antd";
import {changePassword} from "../../actions/mainActions";

class ChangePassword extends Component {
    formRef = React.createRef();
    handleSubmit = (e) => {
        const {handleCancel} = this.props;
        changePassword(e).then(res => {
            message.success('Пароль успешно обновлен');
            handleCancel();
            this.formRef.current.resetFields();
        }, err => {
            message.error('Ошибка при сбросе пароля')
        })
    }

    handleCancel = () => {
    };

    render() {
        const {changePasswordVisible, handleCancel} = this.props;
        return (
            <Modal
                title={'Смена пароля'}
                centered
                footer={null}
                width={"40%"}
                visible={changePasswordVisible}
                onOk={handleCancel}
                onCancel={handleCancel}
            >
                <Form
                    ref={this.formRef}
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={this.handleSubmit}
                >

                    <Form.Item
                        name="old_password"
                        rules={[
                            {
                                required: true,
                                message: 'Введите старый пароль',
                            },
                        ]}
                    >
                        <Input.Password
                            placeholder='Введите старый пароль'
                            size='large'/>
                    </Form.Item>

                    <Form.Item
                        name="new_password"
                        rules={[
                            {
                                required: true,
                                message: 'Введите новый пароль',
                            },
                        ]}
                    >
                        <Input.Password
                            placeholder='Введите новый пароль'
                            size='large'/>
                    </Form.Item>

                    <Form.Item
                        name="confirm_password"
                        rules={[
                            {
                                required: true,
                                message: 'Повторите новый пароль',
                            },
                        ]}
                    >
                        <Input.Password
                            placeholder='Повторите новый пароль'
                            size='large'/>
                    </Form.Item>


                    <Form.Item>
                        <Button type="primary"
                                htmlType="submit" size='large' style={{width: '150px', float: 'left'}}>
                            Сменить
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default ChangePassword;
