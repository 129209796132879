import React, { Component } from "react";
import {
    Avatar,
    Button,
    Col,
    Empty,
    List,
    message,
    Popconfirm,
    Row,
    Spin,
} from "antd";
import { ReactComponent as Edit } from "../../assets/images/icons/edit.svg";
import { ReactComponent as Delete } from "../../assets/images/icons/delete.svg";
import "./myprojects.scss";
import { PlusCircleOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import moment from "moment";
import {
    BackendUrl,
    deleteProjects,
    getMembers,
    getProjects,
    getProjectsByName,
} from "../../actions/mainActions";
import { Link } from "react-router-dom";
import CreateEditProject from "./createEditProjects";

export class MyProjectss extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataofChart: [],
            pageSize: 5,
            projectTypes: [],
            tags: [{
                id: 1,
                name: "Тег проекта 1"
            },
            {
                id: 2,
                name: "Тег проекта 2"
            }],
            id: null,
            userId: JSON.parse(localStorage.getItem('user')).id,
            edit: false,
            coords: [],
            data: [],
            avatarList: [],
            filesList: [],
            allMembers: [],
            initialValues: {},
            loadingList: false,
            selectedRowKeys: [],
            visible: false,
            project_data: {
                name: "",
                avatar: null,
                files: [],
                members: [],
                curators: [],
                managers: [],
                description: "",
                parent_id: "1",
                dependence_id: "1",
                start: moment().format("YYYY-MM-DD, hh:mm"),
                end: moment().format("YYYY-MM-DD, hh:mm"),
            },
            initialState: {
                name: undefined,
                description: undefined,
                curators: undefined,
                managers: undefined,
                members: undefined,
                //  avatar: item.avatar,
                start: undefined,
                end: undefined,
            }
        };
    }

    componentDidMount() {

        this.getTagsProjects();
        /*    const accessToken = JSON.parse(localStorage.getItem('token')).access_token;*/
        this.getListProjects();
        getMembers().then((response) => {
            this.setState({
                allMembers: response.data.data,
            });
        });
    }

   

    onProjectSearch = (name) => {

        this.setState({
            loadingList: true
        })

        if (!name) {
            this.getListProjects();
            return
        }

        getProjectsByName(name).then(resp => {
            const data = resp.data.data
            data.forEach((el, i) => {
                el.key = i
            })

            this.setState({
                data,
                loadingList: false
            })
        }).catch(err => {
            message.error("Произошла ошибка при поиске")
            this.setState({
                loadingList: false
            })
        })
    };

    setNewCenterPoint = (coords) => {
        this.setState({
            coords
        });
    };

    getTagsProjects() {
        // getTags("project").then(res => {
        // });
    }



    getListProjects = () => {
        this.setState({
            loadingList: true
        });
        getProjects().then((response) => {
            const data = !!response.data.data ? Object.values(response.data.data) : [];
            data.forEach((el, i) => {
                el.key = i
            })
            this.setState({
                data,
                loadingList: false
            });
        });
    }
    showModal = () => {
        this.setState({
            id: null,
            visible: true,
        });
    };
    handleEdit = (item) => {
        this.setState({
            id: item.id,
            visible: true
        });
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            edit: false,

        });
    };

    render() {
        const { loadingList, userId } = this.state;
        return (
            <div>
                {/* <h2 className='componentTitle'>Проекты</h2> */}
                {/* <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="Все проекты" key="1"> */}
                        <Spin spinning={loadingList}>
                            <Row className={'toolbarInner'} style={{gap: '24px'}}>
                                <Col span={8}>
                                    <Search
                                        height={'46px'}
                                        className={'myProjects_searchInput innerSearch'}
                                        placeholder="Поиск по проектам"
                                        onSearch={this.onProjectSearch}
                                        size="small"
                                    />
                                </Col>
                                <Col span={5}>
                                    <Button
                                        type="primary"
                                        size="large"
                                        onClick={this.showModal}
                                    >
                                        Добавить проект<PlusCircleOutlined />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <List
                                        className='projectsList'
                                        itemLayout="vertical"
                                        bordered
                                        size="large"
                                        pagination={{
                                            // onChange: (page) => {
                                            // },
                                            size: 10
                                        }}
                                        dataSource={this.state.data}
                                        locale={{ emptyText: <Empty description={'Нет данных'}/> }}
                                        renderItem={(item) => (
                                            <List.Item key={item.name}>
                                                <List.Item.Meta
                                                    avatar={
                                                        <Link
                                                            to={{
                                                                pathname: `/project/${item.id}`,
                                                                propsSearch: item.id
                                                            }}>
                                                            <Avatar
                                                                src={item.avatar_src ? `${BackendUrl}/${item.avatar_src}` : require('../../assets/images/default-project.jpg')} />
                                                        </Link>
                                                    }
                                                    title={
                                                        <div>
                                                            <Link
                                                                to={{
                                                                    pathname: `/project/${item.id}`,
                                                                    propsSearch: item.id
                                                                }}>
                                                                <h2>
                                                                    {item.name}
                                                                </h2>

                                                            </Link>
                                                            <h5>Руководители: {item.managers.map((person, key) =>
                                                                <Link
                                                                    key={person.id}
                                                                    to={`profile/${person.id}`}>{`${person.first_name} ${person.last_name}${item.managers.length === key + 1 ? '' : ', '}`}</Link>)}</h5>
                                                            <h5>Кураторы: {item.curators.map((person, key) => <Link
                                                                key={person.id}
                                                                to={`profile/${person.id}`}>{`${person.first_name} ${person.last_name}${item.curators.length === key + 1 ? '' : ', '}`}</Link>)}</h5>
                                                            <h5>Команда проекта: {item.members.map((person, key) =>
                                                                <Link
                                                                    key={person.id}
                                                                    to={`profile/${person.id}`}>{`${person.first_name} ${person.last_name}${item.members.length === key + 1 ? '' : ', '}`}</Link>)}</h5>
                                                            <h5>Описание: {item.description.substring(0, 150)}...</h5>
                                                            {item.parent_id && <h5>Родительский проект: <Link
                                                                to={`/project/${item.parent_id}`}>{item.parent_name}</Link>
                                                            </h5>}
                                                        </div>
                                                    }
                                                    description={

                                                        <div className="taskDescription">
                                                            <div className="taskItem">
                                                                <h3>Статус</h3>
                                                                {item.status === 'to_do' ? 'В работе' : 'Завершен'}
                                                            </div>
                                                            <div className="taskItem">
                                                                <h3>Всего задач</h3>
                                                                <Link
                                                                    to={`/list/all/${item.id}/all/0`}>{item.all_tasks_count}</Link>
                                                            </div>
                                                            <div className="taskItem">
                                                                <h3>Моих задач</h3>
                                                                <Link
                                                                    to={`/list/all/${item.id}/${userId}/0`}>{item.my_tasks_count}</Link>
                                                            </div>
                                                            <div className="taskItem">
                                                                <h3>Выполнено задач</h3>
                                                                <Link
                                                                    to={`/list/done/${item.id}/all/0`}>{item.done_tasks_count}</Link>
                                                            </div>
                                                            <div>
                                                                {/* <Button className='myProjectsButtons_edit'
                                                                    icon={<Edit
                                                                        className='myProjectsButtons_edit' />}
                                                                    style={{ marginRight: '10px' }}
                                                                    onClick={() => this.handleEdit(item)} /> */}
                                                                <Popconfirm
                                                                    title="Удалить проект?"
                                                                    onConfirm={() => {
                                                                        deleteProjects(item.id).then(res => {
                                                                            message.success('Проект удален');
                                                                            this.getListProjects();
                                                                        }, err => {
                                                                            message.error('Ошибка при удалении проекта')
                                                                        })
                                                                    }}

                                                                    okText="Да"
                                                                    cancelText="Нет"
                                                                >
                                                                    <Button className='myProjectsButtons_delete'
                                                                        icon={<Delete className='deletesvg' />} />
                                                                </Popconfirm>
                                                            </div>

                                                        </div>

                                                    }
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Spin>
                    {/* </TabPane> */}
                    {/*       <TabPane tab="Мои проекты" key="2">
                        <Table
                            className="myProjectsCell"
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={this.state.data}
                        />
                    </TabPane>*/}
                {/* </Tabs> */}
                <CreateEditProject visible={this.state.visible} editId={this.state.id} handleCancel={this.handleCancel}
                    getListProjects={this.getListProjects} />
            </div>
        );
    }
}

export default MyProjectss;
