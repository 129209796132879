import React, { useState } from "react"
import { ReactComponent as Down2 } from "../../assets/images/icons/down-2.svg";
import {
    Card,
    Row,
    Col
} from "antd";
import {
    BackendUrl,
} from "../../actions/mainActions";
import moment from "moment";

export function ProjectProperty(props) {
    const [isExpanded, setExpanded] = useState(false)
    console.log(props,"props")
    let status_budget = props.isActive_budget_status
    switch (props.isActive_budget_status) {
        case "approved":
            status_budget = "Одобрена"
            break;
        case "disabled":
            status_budget = "Отказана"
            break;
        case "revision":
            status_budget = "На рассмотрении"
            break;
        case "partial":
            status_budget = "Частично"
            break;
        default:
            break;
    }
    return (
        <Card onClick={() => {
            setExpanded(!isExpanded)
        }} bordered={false} className='projectCard property' style={{ cursor: 'pointer', textAlign: "left", padding: '10px 30px', marginBottom: '3px' }}>
            <header>
                <span>{props.name}</span>
                <Down2 style={{ fill: '#338DE0', transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }} />
            </header>
            {isExpanded ? <>
                <Row style={{ justifyContent: 'space-between', marginTop: 20 }}>
                    <Col>
                        <h4>Активен:</h4>
                    </Col>
                    <Col>{parseInt(props.isActive) ? 'Да' : 'Нет'}</Col>
                   
                </Row>
                <Row style={{ justifyContent: 'space-between' }}>
                    <Col>
                        <h4>Статус:</h4>
                    </Col>
                    <Col>{status_budget}</Col>
                </Row>
                <Row style={{ justifyContent: 'space-between' }}>
                    <Col>
                        <h4>Комментарий:</h4>
                    </Col>
                    <Col>{props.comment ? props.comment : 'Нет данных'}</Col>
                </Row>
                {props.date ?
                    <Row style={{ justifyContent: 'space-between' }}>
                        <Col>
                            <h4>Дата:</h4>
                        </Col>
                        <Col>{moment(props.date).format('DD.MM.YYYY')}</Col>
                    </Row>
                : null}
                {props.number ?
                    <Row style={{ justifyContent: 'space-between' }}>
                        <Col>
                            <h4>Номер:</h4>
                        </Col>
                        <Col>{props.number}</Col>
                    </Row>
                : null}
                {props.file ?
                    <Row style={{ justifyContent: 'space-between' }}>
                        <Col>
                            <h4>Файл:</h4>
                        </Col>
                        <Col>
                            <a className="link-action" href={BackendUrl + props.file.src} target="_blank" rel="noopener noreferrer" download>
                                <span>{props.file.name}</span>
                            </a>
                        </Col>
                    </Row>
                : null}
            </> : null}
        </Card>
    )
}