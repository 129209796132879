import React, { Component } from "react";
import {
  Button,
  Spin,
  Row,
  Col,
  Select,
  Input
} from 'antd';
import { Link } from "react-router-dom";

// Icons and Images
import { ReactComponent as SearchIcon } from "../../../assets/images/icons/search.svg";
import { ReactComponent as IconProjectEmptyPDF } from "../../../assets/images/icons/presentation.svg";

// Styles
import './tableProjects.scss'

const { Option } = Select;

class TableProjects extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectsData: [],
      total: [],
      loading: false,
      allocatedMoneyFilter: 0,
      destinationFilter: 'all',
      statusFilter: 'all',
      isFilterOn: false,
      filteredProjects: [],
    }
  }

  destinationList = this.props.data.map((project) => {
    return project.destination.title;
  })

  finalDestinationList = this.destinationList.filter((v, i, a) => a.indexOf(v) === i);

  viewChange = (type) => {
    this.setState({ viewType: type })
  }

  applyFilter = () => {

    if (this.state.statusFilter === 'all' && this.state.allocatedMoneyFilter === 0 && this.state.destinationFilter === 'all') {
      this.setState({
        isFilterOn: false,
      })

      return;
    }

    this.setState({
      isFilterOn: true,
    })

    let newFilteredProjects = [];
    const initialProjectsData = this.props.data;

    let currentStatusFilter;
    let currentMoneyFilter;
    let currentDestinationFilter;

    if (this.state.statusFilter !== 'all') {
      currentStatusFilter = this.state.statusFilter;
    }

    if (this.state.allocatedMoneyFilter !== 0) {
      currentMoneyFilter = this.state.allocatedMoneyFilter;
    }

    console.log(`state destinationFilter`)
    console.log(this.state)

    if (this.state.destinationFilter !== 'all') {
      currentDestinationFilter = this.state.destinationFilter;
    }

    newFilteredProjects = initialProjectsData.filter((project) => {
      let isDesired = false;

      if (currentMoneyFilter) {
        if (project.allocatedMoney <= currentMoneyFilter) {
          isDesired = true;
        } else {
          isDesired = false;
          return false;
        }
      }

      if (currentStatusFilter) {
        if (currentStatusFilter === 'done') {
          if (project.id < 8) {
            isDesired = true;
          } else {
            isDesired = false;
            return false;
          }
        }

        if (currentStatusFilter === 'process') {
          if (project.id > 8) {
            isDesired = true;
          } else {
            isDesired = false;
            return false;
          }
        }
      }

      if (currentDestinationFilter) {
        if (project.destination.title === currentDestinationFilter) {
          isDesired = true;
        } else {
          isDesired = false;
          return false;
        }
      }

      if (isDesired) {
        return true;
      } 

      return false;
    })
    
    this.setState({
      filteredProjects: newFilteredProjects,
    })
  }

  render() {

    const formatToCurrnecy = number => {
      return new Intl.NumberFormat('ru-RU').format(number)
    }

    const getCount = () => {
      let count = this.props.data.length
      if (count === 0 || count > 4) return `Всего ${count} проектов`
      if (count < 5 && count > 1) return `Всего ${count} проекта`
      return `Всего ${count} проект`
    }

    const ProjectsGrid = () => {
      return (
        this.state.isFilterOn === false ? (this.props.data.map((projectItem, key) => (
          <Link className='landing-tableProjects-projects-card' to={`/landing-project/${projectItem.id}`} key={key}>
            <div className="image-card">
              {
                !projectItem.tableInfo ? (
                  <img src={require('../../../assets/images/tmp/projects-presentations/project-' + projectItem.id + '.jpg')} alt='project-card' />
                ) : (
                  <IconProjectEmptyPDF className='pdf_svg' />
                )
              }
            </div>
            <h4 className="project-name">{ projectItem.title }</h4>
            <p>Статус: <span>
                { 
                  projectItem.tableInfo?.calendar.end
                  ? (projectItem.tableInfo?.calendar.end <= new Date().getFullYear() ? 'Завершен' : 'В процессе')
                  : 'Завершен'
                }
              </span></p>
            <p><span>{ formatToCurrnecy(projectItem.allocatedMoney) }тг</span></p>
          </Link>
        ))) : (
          this.state.filteredProjects.map((projectItem, key) => (
            <Link className='landing-tableProjects-projects-card' to={`/landing-project/${projectItem.id}`} key={key}>
              <div className="image-card">
                {
                  !projectItem.tableInfo ? (
                    <img src={require('../../../assets/images/tmp/projects-presentations/project-' + projectItem.id + '.jpg')} alt='project-card' />
                  ) : (
                    <IconProjectEmptyPDF className='pdf_svg' />
                  )
                }
              </div>
              <h4 className="project-name">{ projectItem.title }</h4>
              <p>Статус: <span>
                  { 
                    projectItem.tableInfo?.calendar.end
                    ? (projectItem.tableInfo?.calendar.end <= new Date().getFullYear() ? 'Завершен' : 'В процессе')
                    : 'Завершен'
                  }
                </span></p>
              <p><span>{ formatToCurrnecy(projectItem.allocatedMoney) }тг</span></p>
            </Link>
          ))
        )
        
      )
    }

    const ProjectsList = () => {
      return (
        this.state.isFilterOn === false ? (this.props.data.map((projectItem, key) => (
          <Row className='landing-tableProjects-projects-row' style={{ justifyContent: 'space-between', marginBottom: "25px" }} key={key}>
            <Col span={7}>
              <div className='landing-tableProjects-projects-link'>
                <div className='image-card'>
                  {
                    !projectItem.tableInfo ? (
                      <img src={require('../../../assets/images/tmp/projects-presentations/project-' + projectItem.id + '.jpg')} alt='project-card' />
                    ) : (
                      <IconProjectEmptyPDF className='pdf_svg' />
                    )
                  }
                </div>
                <div><Link className="title project-name" to={`/landing-project/${projectItem.id}`}>{ projectItem.title }</Link></div>
              </div>
            </Col>
            <Col span={3}>
              <h4 className="title">Стоимость (тг)</h4>
              <p>{ formatToCurrnecy(projectItem.allocatedMoney) }</p>
            </Col>
            <Col span={4}>
              <h4 className="title">Дестинация</h4>
              <p>{ projectItem.destination.title }</p>
            </Col>
            <Col span={4}>
              <h4 className="title">Статус</h4>
              <p>
                { 
                  projectItem.tableInfo?.calendar.end
                  ? (projectItem.tableInfo?.calendar.end <= new Date().getFullYear() ? 'Завершен' : 'В процессе')
                  : 'Завершен'
                }
              </p>
            </Col>
          </Row>
        ))) : 
        (this.state.filteredProjects.map((projectItem, key) => (
          <Row className='landing-tableProjects-projects-row' style={{ justifyContent: 'space-between', marginBottom: "25px" }} key={key}>
            <Col span={7}>
              <div className='landing-tableProjects-projects-link'>
                <div className='image-card'>
                  {
                    !projectItem.tableInfo ? (
                      <img src={require('../../../assets/images/tmp/projects-presentations/project-' + projectItem.id + '.jpg')} alt='project-card' />
                    ) : (
                      <IconProjectEmptyPDF className='pdf_svg' />
                    )
                  }
                </div>
                <div><Link className="title project-name" to={`/landing-project/${projectItem.id}`}>{ projectItem.title }</Link></div>
              </div>
            </Col>
            <Col span={3}>
              <h4 className="title">Стоимость (тг)</h4>
              <p>{ formatToCurrnecy(projectItem.allocatedMoney) }</p>
            </Col>
            <Col span={4}>
              <h4 className="title">Дестинация</h4>
              <p>{ projectItem.destination.title }</p>
            </Col>
            <Col span={4}>
              <h4 className="title">Статус</h4>
              <p>
                { 
                  projectItem.tableInfo?.calendar.end
                  ? (projectItem.tableInfo?.calendar.end <= new Date().getFullYear() ? 'Завершен' : 'В процессе')
                  : 'Завершен'
                }
              </p>
            </Col>
          </Row>
        )))
      )
    }

    return (
      <div className="landing-tableProjects">
        <Row className='landing-tableProjects-filter' style={{ alignItems: "center", marginBottom: "25px" }}>
          <Input
            className='price-filter'
            placeholder={"Общая стоимость"}
            onChange={(e) => {e.target.value ? this.setState({allocatedMoneyFilter: e.target.value}) : this.setState({allocatedMoneyFilter: 0})}}
          />

          <Select
            className="select-filter"
            onChange={(e) => {
              console.log(e);
              this.setState({
                destinationFilter: e ? e : 'all'
              });
            }}
            placeholder='Дестинация'
            allowClear
            style={{width: '300px'}}
          >
            {this.finalDestinationList.map((item, key) => (
                <Option key={key} value={item}>
                    {item}
                </Option>
            ))}
          </Select>

          <Select
            className="select-filter"
            onChange={(e) => {this.setState({statusFilter: e ? e : 'all'})}}
            placeholder='Статус'
            allowClear
            onClear={() => {this.setState({statusFilter: 'all'})}}
            style={{width: '150px'}}
          >
            <Option key={'done'} value='done'>
              Завершен
            </Option>
            <Option key={'process'} value='process'>
              В процессе
            </Option>

          </Select>
          <Button
            type="primary"
            style={{ borderRadius: "8px" }}
            onClick={() => {this.applyFilter()}}
          >
            Применить
            <SearchIcon style={{ height: '16px', width: '16px', fill: '#fff' }} />
          </Button>
        </Row>

        <div className={`landing-tableProjects-projects-table ${this.props.viewType}`}>
          <Row className="landing-tableProjects-projects-header" style={{ alignItems: "center", justifyContent: 'space-between', marginBottom: "25px" }}>
            <h4>{ getCount() }</h4>
            {/* <div className="landing-tableProjects-projects-sort">
              <h5>Сортировать:</h5>
              <Button type='text'>по стоимости</Button>
              <Button type='text'>по дате публикации</Button>
            </div> */}
          </Row>

          <Spin spinning={this.state.loading}>
            <div className="landing-tableProjects-projects-list">
              {
                this.props.viewType === 'grid'
                  ? <ProjectsGrid />
                  : <ProjectsList />
              }
            </div>
          </Spin>
          {/* <VpPagination
            changeCountPage={15}
            changePage={15}
            total={15}
            from={1}
            to={6}
            current_page={5}
            last_page={15}/> */}
        </div>
      </div>
    )
  }
}

export default TableProjects;
