import React, { useEffect, useState } from "react";
import { Table, Popconfirm, Empty, Tabs, message, Select } from "antd";
import "./tasks.scss";
import moment from "moment";
import { deleteTask, getArchiveData, changeTaskStatus } from "../../actions/mainActions";
import AllArchiveDocs from "./archiveTabs/AllArchiveDocs";
import { TaskView } from "./taskView";
// import MyArchiveDocs from "./archiveTabs/MyArchiveDocs";
// import { ReactComponent as Down } from "../../assets/images/icons/downVector.svg";

const { TabPane } = Tabs;
const Option = Select.Option;

function ArchiveList() {
  const [loading, setLoading] = useState(true);
  const [dataArchives, setDataArchives] = useState([]);
  const [taskVisible, setTaskVisible] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState(null);

  const loadData = async () => {
    setLoading(true);
    const {
      data: { data },
    } = await getArchiveData();
    const archive = data.map((archive) => ({
      ...archive,
      key: archive.id,
    }));
    setDataArchives(archive);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const deleteTaskFromArchive = async (id) => {
    await deleteTask(id);
    loadData();
    message.info("Удален с архива!");
  };

  const showTask = (id) => {
    setCurrentTaskId(id);
    setTaskVisible(true);
  }

  const columns = (deleteTaskFromArchive) => {
    const changeStatus = async (status, {id}) => {
      if (status === 'to_do') {
        await changeTaskStatus(id, 1);
        message.success("Удалено из архива");
        loadData();
      }
      if (status === 'doing') {
        await changeTaskStatus(id, 2);
        message.success("Удалено из архива");
        loadData();
      }
      if (status === 'approval') {
        await changeTaskStatus(id, 3);
        message.success("Удалено из архива");
        loadData();
      }
      if (status === 'done') {
        await changeTaskStatus(id, 4);
        message.success("Удалено из архива");
        loadData();
      }
    }
  
    return [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: "5em",
      render: (i, j, index) => <span>{index + 1}</span>,
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Создатель",
      dataIndex: "action_log",
      key: "author",
      render: ([action_log]) =>
        `${action_log.first_name} ${action_log.last_name} ${
          action_log.middle_name ?? ""
        }`,
    },
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("DD.MM.YYYY"),
      width: 300,
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      width: '97px',
      render: (status, data) => (
        <Select 
          defaultValue={`${status}`}
          className='archive__select'
          showArrow={true}
          onChange={(value) => {changeStatus(value, data)}}
          suffixIcon={(<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.94 0.719727L4 3.77973L7.06 0.719727L8 1.66639L4 5.66639L0 1.66639L0.94 0.719727Z" fill="white"/>
          </svg>
          )}
        >
          <Option value={'archive '} key={'archive '}>В архиве</Option>
          <Option value={'to_do'} key={'to_do'}>Backlog</Option>
          <Option value={'doing'} key={'doing'}>В работе</Option>
          <Option value={'approval'} key={'approval'}>На утверждении</Option>
          <Option value={'done'} key={'done'}>Готов</Option>
        </Select>
      ),
    },
    {
      title: "Операции",
      dataIndex: "src",
      key: "src",
      width: 200,
      render: (src, data) => (
        <div className="archive_actions" style={{ display: "flex", gap: "24px" }}>
          <div
            style={{cursor: 'pointer'}}
            onClick={() => {showTask(data.id)}}
          >
            <svg className="view" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.894336 10.2075C0.509967 10.8026 0.246141 11.2717 0.105573 11.5528C-0.0351909 11.8343 -0.0351909 12.1657 0.105573 12.4472C0.246141 12.7283 0.509967 13.1974 0.894336 13.7925C1.5305 14.7776 2.28113 15.762 3.14546 16.6839C5.66131 19.3675 8.6202 21 12 21C15.3798 21 18.3387 19.3675 20.8545 16.6839C21.7189 15.762 22.4695 14.7776 23.1057 13.7925C23.49 13.1974 23.7539 12.7283 23.8944 12.4472C24.0352 12.1657 24.0352 11.8343 23.8944 11.5528C23.7539 11.2717 23.49 10.8026 23.1057 10.2075C22.4695 9.22245 21.7189 8.23802 20.8545 7.31606C18.3387 4.63249 15.3798 3 12 3C8.6202 3 5.66131 4.63249 3.14546 7.31606C2.28113 8.23802 1.5305 9.22245 0.894336 10.2075ZM4.60454 15.3161C3.82825 14.488 3.14919 13.5974 2.57441 12.7075C2.41128 12.4549 2.26659 12.2176 2.14071 12C2.26659 11.7824 2.41128 11.5451 2.57441 11.2925C3.14919 10.4026 3.82825 9.51198 4.60454 8.68394C6.77619 6.36751 9.2548 5 12 5C14.7452 5 17.2238 6.36751 19.3955 8.68394C20.1718 9.51198 20.8508 10.4026 21.4256 11.2925C21.5887 11.5451 21.7334 11.7824 21.8593 12C21.7334 12.2176 21.5887 12.4549 21.4256 12.7075C20.8508 13.5974 20.1718 14.488 19.3955 15.3161C17.2238 17.6325 14.7452 19 12 19C9.2548 19 6.77619 17.6325 4.60454 15.3161ZM12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"/>
              <mask id="mask0_4028_15387" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="3" width="24" height="18">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.894336 10.2075C0.509967 10.8026 0.246141 11.2717 0.105573 11.5528C-0.0351909 11.8343 -0.0351909 12.1657 0.105573 12.4472C0.246141 12.7283 0.509967 13.1974 0.894336 13.7925C1.5305 14.7776 2.28113 15.762 3.14546 16.6839C5.66131 19.3675 8.6202 21 12 21C15.3798 21 18.3387 19.3675 20.8545 16.6839C21.7189 15.762 22.4695 14.7776 23.1057 13.7925C23.49 13.1974 23.7539 12.7283 23.8944 12.4472C24.0352 12.1657 24.0352 11.8343 23.8944 11.5528C23.7539 11.2717 23.49 10.8026 23.1057 10.2075C22.4695 9.22245 21.7189 8.23802 20.8545 7.31606C18.3387 4.63249 15.3798 3 12 3C8.6202 3 5.66131 4.63249 3.14546 7.31606C2.28113 8.23802 1.5305 9.22245 0.894336 10.2075ZM4.60454 15.3161C3.82825 14.488 3.14919 13.5974 2.57441 12.7075C2.41128 12.4549 2.26659 12.2176 2.14071 12C2.26659 11.7824 2.41128 11.5451 2.57441 11.2925C3.14919 10.4026 3.82825 9.51198 4.60454 8.68394C6.77619 6.36751 9.2548 5 12 5C14.7452 5 17.2238 6.36751 19.3955 8.68394C20.1718 9.51198 20.8508 10.4026 21.4256 11.2925C21.5887 11.5451 21.7334 11.7824 21.8593 12C21.7334 12.2176 21.5887 12.4549 21.4256 12.7075C20.8508 13.5974 20.1718 14.488 19.3955 15.3161C17.2238 17.6325 14.7452 19 12 19C9.2548 19 6.77619 17.6325 4.60454 15.3161ZM12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="white"/>
              </mask>
              <g mask="url(#mask0_4028_15387)">
              <rect width="24" height="24"/>
              </g>
            </svg>
          </div>
          <Popconfirm
            placement="bottom"
            title="Вы действительно хотите удалить документ?"
            onConfirm={() => {
              deleteTaskFromArchive(data.id);
            }}
            okText="Да"
            cancelText="Нет"
          >
            <div style={{width: '24px', height: '24px', cursor: 'pointer'}}>
              <svg className="delete" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7 5H5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4V20C4 21.6569 5.34315 23 7 23H17C18.6569 23 20 21.6569 20 20V7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H19H17V4C17 2.34315 15.6569 1 14 1H10C8.34315 1 7 2.34315 7 4V5ZM9 5H15V4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V5ZM16 7H8H6V20C6 20.5523 6.44772 21 7 21H17C17.5523 21 18 20.5523 18 20V7H16ZM9 11V17C9 17.5523 9.44772 18 10 18C10.5523 18 11 17.5523 11 17V11C11 10.4477 10.5523 10 10 10C9.44772 10 9 10.4477 9 11ZM13 17V11C13 10.4477 13.4477 10 14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17Z"/>
                <mask id="mask0_4028_15298" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="2" y="1" width="20" height="22">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7 5H5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4V20C4 21.6569 5.34315 23 7 23H17C18.6569 23 20 21.6569 20 20V7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H19H17V4C17 2.34315 15.6569 1 14 1H10C8.34315 1 7 2.34315 7 4V5ZM9 5H15V4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V5ZM16 7H8H6V20C6 20.5523 6.44772 21 7 21H17C17.5523 21 18 20.5523 18 20V7H16ZM9 11V17C9 17.5523 9.44772 18 10 18C10.5523 18 11 17.5523 11 17V11C11 10.4477 10.5523 10 10 10C9.44772 10 9 10.4477 9 11ZM13 17V11C13 10.4477 13.4477 10 14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_4028_15298)">
                <rect width="24" height="24"/>
                </g>
              </svg>
            </div>
          </Popconfirm>
        </div>
      ),
    },
  ]};

  return (
    <div className="riskComponent">
      <h2 className="componentTitle problemTitle">АРХИВ</h2>
      <Tabs
        className="problemsTabs"
        defaultActiveKey="1"
        // onChange={}
        style={{ marginTop: "15px" }}
      >
        <TabPane tab="Все документы" key="all">
          <AllArchiveDocs />
        </TabPane>
        {/* <TabPane tab="Мои документы" key="my_docs">
          <MyArchiveDocs />
        </TabPane> */}
      </Tabs>

      <div style={{ position: "relative" }}>
        <Table
          columns={columns(deleteTaskFromArchive)}
          dataSource={dataArchives}
          className="library-table"
          bordered
          //pagination={}
          locale={{ emptyText: <Empty description={"Нет данных"} /> }}
          loading={loading}
        />
      </div>
      {taskVisible ? (
      <TaskView
        taskId={currentTaskId}
        fromArchive={true}
        taskVisible={taskVisible}
        handleCancel={() => {setTaskVisible(false)}}
      />
      ) : null}
    </div>
  );
}

export default ArchiveList;
