import React, { Component } from "react";
import {
    Button,
    Col,
    Modal,
    Row,
    Popconfirm, message,
    Avatar, Progress
} from "antd";
import "../Projects/myprojects.scss";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import {
    BackendUrl,
    getProject, getTask, deleteTask, createTaskComment, getTaskComment, editTaskComment, deleteTaskComment, getProfileById
} from "../../actions/mainActions";
import "@lourenci/react-kanban/dist/styles.css";
import { ReactComponent as Edit } from "../../assets/images/icons/myProjectsButtons_edit.svg";
import { ReactComponent as Delete } from "../../assets/images/icons/myProjectsButtons_delete.svg";
import { Circle, Map, Marker, Polygon, Polyline, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Link } from "react-router-dom";
import L from "leaflet";
import "moment/locale/ru";
import { TaskAddEdit } from "./taskAddEdit";
import {ReactComponent as Close} from "../../assets/images/icons/close_modal.svg";
import Comment from "../../components/Comment/Comment";

const iconMarkerGreen = new L.Icon({
    iconUrl: require("../../assets/images/icons/markerGreen.png"),
    iconSize: new L.Point(23, 29),
    className: "iconMarkerMap"
});

const iconMarkerRed = new L.Icon({
    iconUrl: require("../../assets/images/icons/markerRed.png"),
    iconSize: new L.Point(23, 29),
    className: "iconMarkerMap"
});

const iconMarker = new L.Icon({
    iconUrl: require("../../assets/images/icons/marker.png"),
    iconSize: new L.Point(29, 37),
    className: "iconMarkerMap"
});

const taskRolesMap = {
    owner: [
        'access-edit-task',
        'access-freeze-task',
        'access-fail-task',
        'access-cancel-task',
        'access-accept-task',
        'access-add-comments-task',
        'access-add-vechi-task',
        'access-edit-members-task',
        'access-edit-curators-task',
        'access-edit-contragent-task',
        'access-add-communications-task',
        'access-all-undertasks-task'
    ],
    manager: [
        'access-edit-task',
        'access-freeze-task',
        'access-fail-task',
        'access-add-comments-task',
        'access-add-vechi-task',
        'access-edit-members-task',
        'access-edit-curators-task',
        'access-edit-contragent-task',
        'access-add-communications-task',
        'access-all-undertasks-task'
    ],
    member: [
        'access-add-comments-task',
        'access-add-vechi-task',
        'access-add-communications-task',
        'access-self-undertasks-task'
    ],
    curator: [
        'access-add-comments-task',
        'access-add-vechi-task',
        'access-add-communications-task',
        'access-all-undertasks-task'
    ]
}

export class TaskView extends Component {
    state = {
        task: {
            members: [],
            owner_id: 0,
            managers: []
        },
        changeLog: [],
        project_name: '',
        center: [],
        points: [],
        taskInput: '',
        taskComments: [],
        commentReplyId: null,
        ownerInfo: null,
        role: '',
    }

    setProjectName(id) {
        id && getProject(id).then((response) => {
            this.setState({
                project_name: response.data.data.name
            });
        });
    }

    getDetails = () => {
        const id = this.props.taskId;
        id && getTask(id).then((response) => {
            response.data.data.task_project_id && this.setProjectName(response.data.data.task_project_id);
            const newArrayPoints = response.data.data.map_objects.map((el) => {
                const newEl = el;
                newEl.key = el.id;
                if (el.type === "line" || el.type === "poligon") {
                    newEl.coordinates = el.coordinatesArr.map(coord => [coord.latitude, coord.longitude]);
                    return newEl;
                } else if (el.type === "point") {
                    newEl.coordinates = [el.coordinatesArr[0].latitude, el.coordinatesArr[0].longitude];
                    return newEl;
                } else {
                    return null;
                }
            });
            const center = newArrayPoints[0] ? [newArrayPoints[0].coordinatesArr[0].latitude, newArrayPoints[0].coordinatesArr[0].longitude] : [51.167347, 71.561905];
            const taskData = response.data.data;

            getProfileById(taskData.owner_id).then((response) => {
                const currentRole = this.getTaskRole(taskData);

                console.log(`taskRole`)
                console.log(currentRole)

                this.setState({
                    task: taskData,
                    changeLog: taskData.action_log,
                    center,
                    points: newArrayPoints,
                    ownerInfo: response.data.data,
                    role: currentRole,
                })
            })
        });
    }

    renderOperateionType = type => {
        switch (type) {
            case "edit":
                return "Отредактировал(а)";
            case "create":
                return "Создал(а)";
            case "delete":
                return "Удалил(а)"
            default:
                return type
        }
    }

    startEdit = (id) => {
        this.setState({
            editId: id,
            isVisible: true
        })
    }

    hideAndRefresh = () => {
        this.hideModal();
        this.getDetails();
    }
    hideModal = () => {
        this.setState({
            isVisible: false
        })
    }

    deleteTaskById = (id) => {
        deleteTask(id).then(res => {
            message.success('Задача удалена');
            this.props.handleCancel();
        })
    }

    componentDidMount() {
        this.getDetails();
        this.getInitialTaskComment()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.taskId !== prevProps.taskId) {
            this.getDetails();
            this.getInitialTaskComment();
        }
    }

    renderPriority = (key) => {
        switch (key) {
            case 'normal':
                return 'Средний'
            case 'critical':
                return 'Высокий'
            case 'low':
                return 'Низкий'
            default:
                return key
        }
    }

    taskOnChange = e => {
        this.setState({
            taskInput: e.target.value
        })
    }

    handleNewComment = async text => {
        await createTaskComment({
            task_id: this.props.taskId,
            text: text
        })
        this.getInitialTaskComment()
    }
    handleReplyComment = async ({ text, commentId }) => {
        await createTaskComment({
            task_id: this.props.taskId,
            text,
            parent_id: commentId
        })
        this.getInitialTaskComment()
    }
    handleEditComment = async ({ text, commentId }) => {
        await editTaskComment(commentId, { text })
        this.getInitialTaskComment()
    }
    handleDeleteComment = async commentId => {
        await deleteTaskComment(commentId)
        this.getInitialTaskComment()
    }

    sendTaskComment = () => {
        if (this.state.commentReplyId) {
            createTaskComment({text: this.state.taskInput, task_id: this.props.taskId, parent_id: this.state.commentReplyId}).then(response => this.getInitialTaskComment())
            this.clearReplyId()
        }
        createTaskComment({text: this.state.taskInput, task_id: this.props.taskId}).then(response => this.getInitialTaskComment())
    }
    getInitialTaskComment = () => {
        getTaskComment(this.props.taskId).then(response => this.setState({taskComments: response.data.data}))
    }
    getTaskCommentId = (id, text, deleteTask) => {
        this.setState({
            commentReplyId: id
        })
    }
    clearReplyId = () => {
        this.setState({
            commentReplyId: null
        })
    }

    getTaskRole = (taskInfo) => {
        const isOwner = this.checkIsOwner(taskInfo.owner_id);

        const isManager = this.checkIsRole(taskInfo.managers);
        const isMember = this.checkIsRole(taskInfo.members);
        const isCurator = this.checkIsRole(taskInfo.curators);

        if (isOwner) {
            return 'owner';
        } else if (isManager) {
            return 'manager';
        } else if (isMember) {
            return 'member';
        } else if (isCurator) {
            return 'curator';
        }

        return ''
    }

    checkIsOwner = (ownerId) => {
        const currentUser = JSON.parse(localStorage.getItem("user"));

        return (ownerId === currentUser.id);
    }

    checkIsRole = (rolesArray) => {
        const currentUser = JSON.parse(localStorage.getItem("user"));
        let isRole = false;

        for (let i = 0; i < rolesArray.length; i++) {
            const user = rolesArray[i];

            if (user.id === currentUser.id) {
                isRole = true;
                break;
            } 
        }

        if (isRole) {
            return true;
        } else {
            return false;
        }
    }

    getRoleName = () => {
        const role = this.state.role;

        if (role === 'owner') {
            return 'Вы - Постановщик задачи'
        }

        if (role === 'manager') {
            return 'Вы - Ответственный по задаче'
        }

        if (role === 'member') {
            return 'Вы - Соисполнитель в задаче'
        }

        if (role === 'curator') {
            return 'Вы - Куратор'
        }

        if (role === '') {
            return 'Вы не участвуете в задаче'
        }
    }

    getMyUndertasks = (undertasksArray) => {
        return undertasksArray.filter((undertask) => {
            const role = this.getTaskRole(undertask);

            if (role != '') {
                return true;
            } else {
                return false;
            }
        })
    }

    render() {
        const { taskVisible, handleCancel, selectedMapObject } = this.props;
        const { task, changeLog, project_name, center, points } = this.state;
        return (
            <Modal
                title={
                    <Row className="task_header">
                        <span className="task_title">
                            {task.name}
                        </span>
                        {this.state.role && taskRolesMap[this.state.role].includes('access-edit-task') && 
                        <div style={{display: 'flex'}}>
                            {this.props.fromArchive != true ? (<Button className='myProjectsButtons_edit'
                                icon={<Edit className='myProjectsButtons_edit' />}
                                style={{ marginRight: '15px', background: 'rgba(255, 255, 255, 0.2)', borderRadius: '8px' }}
                                onClick={() => this.startEdit(task.id)}
                            />) : null}
                            {this.state.role && taskRolesMap[this.state.role].includes('access-cancel-task') && this.props.fromArchive != true ? (<Popconfirm
                                placement='bottom'
                                title="Удалить задачу?"
                                onConfirm={() => {
                                    this.deleteTaskById(task.id)
                                }}
                                okText="Да"
                                cancelText="Нет"
                            >
                                <Button 
                                    className='myProjectsButtons_delete' 
                                    icon={<Delete style={{fill: '#fff'}} />}
                                    style={{ background: '#FF575F', borderRadius: '8px'}}
                                />
                            </Popconfirm>) : null}
                        </div>}
                    </Row>

                }
                centered
                footer={null}
                width={"60%"}
                visible={taskVisible}
                // onOk={this.handleCancel}
                onCancel={handleCancel}
                closeIcon={<Close style={{marginTop: '22px'}}/>}
                className="task_view_modal"
            >
                <Row style={{ marginBottom: "35px" }}>
                    <div className="taskView-profile-link">
                        <span>{this.getRoleName()}</span>
                    </div>
                </Row>
                <Row style={{ marginBottom: "35px" }}>
                    <Col span={5}
                        className="formLabelBold">Проект</Col>
                    <Col span={19} style={{ fontSize: "16px" }}>{project_name}</Col>
                </Row>
                <Row style={{ marginBottom: "35px" }} gutter={24}>
                    <Col span={5} className="formLabelBold">
                        Описание
                    </Col>
                    <Col span={19} style={{ fontSize: "16px" }}>
                        <pre>{task.description}</pre>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "35px" }} gutter={24}>
                    <Col span={5}
                        className="formLabelBold">Файлы</Col>
                    <Col span={19} style={{ fontSize: "16px" }}>
                        {task.files && task.files.length > 0 ? task.files.map((item) => <Row key={item.id}
                            style={{ marginBottom: "10px" }}>
                            <span
                                onClick={() => window.open(`${BackendUrl}/${item.src}`, "_blank")}
                                style={{ fontSize: "16px", color: "#1890ff", cursor: "pointer" }}>{item.name}</span>
                        </Row>
                        ) : "Нет файлов"}

                    </Col>
                </Row>

                <Row style={{ marginBottom: "35px" }} gutter={24}>
                    <Col span={5} className="formLabelBold">Постановщик</Col>
                    <Col span={19} style={{ fontSize: "16px", display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        {this.state.ownerInfo ? 
                            (<Link to={`/profile/${this.state.ownerInfo.id}`} key={this.state.ownerInfo.id} className="taskView-profile-link"><span
                            >{`${this.state.ownerInfo.first_name} ${this.state.ownerInfo.last_name} `}</span>
                            <span className="organization">{this.state.ownerInfo.organization_name}</span>
                            </Link>) : ""}
                    </Col>
                </Row>
                <Row style={{ marginBottom: "35px" }} gutter={24}>
                    <Col span={5} className="formLabelBold">Ответственные</Col>
                    <Col span={19} style={{ fontSize: "16px", display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        {task.managers ? task.managers.map((item, i) =>
                            <Link to={`/profile/${item.id}`} key={item.id} className="taskView-profile-link"><span
                            >{`${item.first_name} ${item.last_name}${task.managers.length === i + 1 ? '' : ', '} `}</span>
                            <span className="organization">{item.organization_name}</span>
                            </Link>) : ""}
                    </Col>
                </Row>
                <Row style={{ marginBottom: "35px" }} gutter={24}>
                    <Col span={5} className="formLabelBold">Исполнители</Col>
                    <Col span={19} style={{ fontSize: "16px", display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        {task.members ? task.members.map((item, i) =>
                            <Link to={`/profile/${item.id}`} key={item.id} className="taskView-profile-link">
                                <span>{`${item.first_name} ${item.last_name}${task.members.length === i + 1 ? '' : ', '}`}</span>
                                <span className="organization">{item.organization_name}</span>
                            </Link>) : ""}
                    </Col>
                </Row>
                <Row style={{ marginBottom: "35px" }} gutter={24}>
                    <Col span={5} className="formLabelBold">Кураторы</Col>
                    <Col span={19} style={{ fontSize: "16px", display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        {task.curators ? task.curators.map((item, i) =>
                            <Link to={`/profile/${item.id}`} key={item.id} className="taskView-profile-link">
                                <span>{`${item.first_name} ${item.last_name}${task.curators.length === i + 1 ? '' : ', '}`}</span>
                                <span className="organization">{item.organization_name}</span>
                            </Link>) : ""}
                    </Col>
                </Row>
                <Row style={{ marginBottom: "35px" }} gutter={24}>
                    <Col span={5}
                        className="formLabelBold">Срок</Col>
                    <Col span={19}
                        style={{ fontSize: "16px" }}>{moment(task.start).format("DD.MM.YYYY")} - {moment(task.end).format("DD.MM.YYYY")}</Col>
                </Row>
                <Row style={{ marginBottom: "35px" }}>
                    <Col span={5}
                        className="formLabelBold">Приоритет</Col>
                    <Col span={19} style={{ fontSize: "16px" }}>{this.renderPriority(task.priority)}</Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: '35px' }}>
                    <Col span={5} className="formLabelBold">Выполнение
                        ({task.perform_percent ? task.perform_percent : 0}%)</Col>
                    <Col span={12}>
                        {/* <Slider value={task.perform_percent} disabled /> */}
                        <Progress percent={task.perform_percent} strokeColor={
                            task.perform_percent < 50 ? '#338DE0' :
                            task.perform_percent < 90 ? '#FCC865':
                            '#28C397'
                        } />
                    </Col>
                </Row>

                {task.parent_task_id && <Row style={{ marginBottom: "35px" }}>
                    <Col span={5}
                        className="formLabelBold">Под задача от: </Col>
                    <Col span={19} style={{ fontSize: "16px" }}><Link
                        to={`/board/${task.parent_task_id}`}>{task.parent_task_name}</Link></Col>
                </Row>}

                {task.map_objects && task.map_objects.length > 0 &&
                    <Row style={{ marginBottom: "35px" }} gutter={24}>
                        <Col span={5} className="formLabelBold">
                            Карта
                        </Col>
                        <Col span={19} className={"mapBlockEditor"}>
                            {/* {<ProjectsMap setMapObjectsArr={this.setMapObjectsArr}/>} */}

                            <Map center={center ? center : [30, 30]} zoom={14}
                                className={"mapFullScreen"}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {points && points.map((el, i) => {
                                    if ((el.type === "circle")) {
                                        return <Circle center={el.center}
                                            key={el.id}
                                            draggable
                                            fillColor={selectedMapObject === el.id ? "#338DE0" : this.props.inputValue > el.color ? "green" : "red"}
                                            fillOpacity={0.5}
                                            radius={el.radius}
                                        >
                                            color={selectedMapObject === el.id ? "#338DE0" : moment() >= moment(el.end_date).valueOf() ? "green" : "red"}
                                            <Popup>
                                                {this.props.renderPopup(el)}
                                            </Popup>
                                        </Circle>;
                                    } else
                                        if ((el.type === "line")) {
                                            return <Polyline positions={el.coordinates}
                                                key={i}
                                                color={selectedMapObject === el.id ? "#338DE0" : moment() >= moment(el.end_date).valueOf() ? "green" : "red"}
                                            >
                                                <Popup>
                                                    {this.props.renderPopup(el)}
                                                </Popup>
                                            </Polyline>;
                                        } else
                                            if ((el.type === "poligon")) {
                                                return <Polygon positions={el.coordinates}
                                                    key={i}
                                                    color={selectedMapObject === el.id ? "#338DE0" : moment() >= moment(el.end_date).valueOf() ? "green" : "red"}
                                                >
                                                    <Popup>
                                                        {this.props.renderPopup(el)}
                                                    </Popup>
                                                </Polygon>;
                                            } else
                                                if ((el.type === "point")) {
                                                    return <Marker position={Array.isArray(el.coordinates[0]) ? el.coordinates[0] : el.coordinates}
                                                        key={i}
                                                        icon={selectedMapObject === el.id ? iconMarker : moment() >= moment(el.end_date).valueOf() ? iconMarkerGreen : iconMarkerRed}>

                                                        <Popup>
                                                            {this.props.renderPopup(el)}
                                                        </Popup>
                                                    </Marker>;
                                                } else {
                                                    return null;
                                                }
                                })}
                            </Map>
                        </Col>
                    </Row>
                }

                <Row style={{ marginBottom: "35px" }} gutter={24}>
                    <Col span={5}>
                        <h4 className="formLabelBold">Действия</h4>
                    </Col>
                    <Col span={19}>
                        {changeLog && changeLog.length > 0 && changeLog.reverse().map(el =>
                            <div key={el.id} className='changeLogDiv'>
                                {
                                    el.user_avatar ?
                                    <Avatar 
                                        className="avatarCanban"
                                        src={`${BackendUrl}${el.user_avatar}`}
                                    />
                                    :
                                    <UserOutlined className="avatarCanban" />
                                }
                                <div className="textChangelog">
                                    <p>
                                        <b>{el.last_name} {el.first_name}</b> {this.renderOperateionType(el.operation)} задачу. <br />
                                        <small>{moment(el.oparation_date).format('DD.MM.YYYY HH:mm')}</small></p>
                                </div>
                            </div>)
                        }
                    </Col>
                </Row>

                {task.undertasks ? (<Row style={{ marginBottom: "35px" }} gutter={24}>
                    <Col span={5}>
                        <h4 className="formLabelBold">Подзадачи</h4>
                    </Col>
                    <Col span={19}>
                        {this.state.role && taskRolesMap[this.state.role].includes('access-all-undertasks-task') ? 
                        task.undertasks.map((undertask) => {
                            return (
                                <Link to={`/board/${undertask.id}`}>
                                    {undertask.name}
                                </Link>
                            )
                        }) : 
                        this.getMyUndertasks(task.undertasks).map((undertask) => {
                            return (
                                <Link to={`/board/${undertask.id}`}>
                                    {undertask.name}
                                </Link>
                            )
                        })}
                    </Col>
                </Row>) : null}

                <Row>

                    <Col span={5} className="formLabelBold">
                        Комментарий
                    </Col>
                    <Col span={19}>
                        <Comment
                            commentList={this.state.taskComments}
                            onNewComment={this.handleNewComment}
                            onReplyComment={this.handleReplyComment}
                            onEditComment={this.handleEditComment}
                            onDeleteComment={this.handleDeleteComment}
                            canComment={this.state.role ? true : false}
                        />
                    </Col>
                </Row>


                <TaskAddEdit
                    editId={this.state.editId}
                    hideModal={this.hideAndRefresh}
                    isVisible={this.state.isVisible}
                    taskDetails={task}
                />
            </Modal>
        )
    }

}
