import React, { Component } from "react";
import {
  acceptContractor,
  getContractorsList, rejectContractor,
} from "../../../actions/mainActions";
import { Table, Row, Col, Button, message, Empty } from "antd";
import { ReactComponent as UserPlus } from "../../../assets/images/icons/user_plus.svg";
import { ReactComponent as UserX } from "../../../assets/images/icons/user_x.svg";
import VpPagination from "../VpPagination";

class ContractorsList extends Component {
  state = {
    loading: false,
    tab: 1,
    page: 1,
    total: 0,
    from: 0,
    to: 0,
    current_page: 0,
    last_page: 0,
    pageSize: 15,
    tableData: [],
    columns: [
      {
        title: "№",
        dataIndex: "key",
        key: "key"
      },
      {
        title: "БИН",
        dataIndex: "bin",
        key: "bin"
      },
      {
        title: "Наименование компании",
        dataIndex: "legal_name",
        key: "legal_name"
      },
      {
        title: "ФИО",
        dataIndex: "last_name",
        key: "last_name",
        render: (e, row) => `${row.last_name} ${row.first_name} ${row.middle_name ? row.middle_name : ''}`
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "Операции",
        dataIndex: "actions",
        key: "actions",
        render: (e, row) => {
          return <Row gutter={5}>
            <Col span={24}>
              <Button onClick={() => {this.accept(row.id);}} className={"greenBtn w100"}><UserPlus/> Разрешить</Button>
              <Button onClick={() => this.reject(row.id)} className={"dangerBtn w100"} style={{    position: 'relative',top: '1px'}}><UserX/> Отклонить</Button>
            </Col>
          </Row>;
        }
      }
    ]
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.state.pageSize !== prevState.pageSize) || (this.state.page !== prevState.page) || (this.props.updater !== prevProps.updater)) {
      this.getContractors();
    }
  }

  changeCountPage = pageSize => {
    this.setState({
      pageSize
    });
  };

  changePage = page => {
    this.setState({
      page
    });
  };

  getContractors = () => {
    const { page, pageSize } = this.state;
    this.setState({
      loading: true
    });
    getContractorsList(page, pageSize).then(res => {
      const mappedData = res.data.data.data;
      mappedData.forEach((el, i) => {
        el.key = ++i + ((page - 1) * pageSize);
      });
      this.setState({
        tableData: mappedData,
        total: res.data.data.total,
        from: res.data.data.from,
        to: res.data.data.to,
        current_page: res.data.data.current_page,
        last_page: res.data.data.last_page,
        loading: false
      });
    }, err => {
      this.setState({
        loading: false
      });
    });
  };

  componentDidMount() {
    this.getContractors();
  }
  accept = id => {
    acceptContractor(id).then(res => {
      this.getContractors();
      message.success("Пользователь принят");
    });
  };

  reject = id => {
    rejectContractor(id).then(res => {
      this.getContractors();
      message.success("Пользователь отклонен");
    });
  };

  render() {
    const { tableData, columns, total, from, to, current_page, last_page, loading } = this.state;
    return (<div>
      <Table bordered className={"myProjectsCell usersTable"}
             dataSource={tableData}
             loading={loading}
             columns={columns}
             pagination={false}
             locale={{ emptyText: <Empty description={'Нет данных'}/> }}/>
      <VpPagination
        changeCountPage={this.changeCountPage}
        changePage={this.changePage}
        total={total}
        from={from}
        to={to}
        current_page={current_page}
        last_page={last_page}/>
    </div>);
  }
}

export default ContractorsList;
