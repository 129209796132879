import { Avatar, Button, Popconfirm } from 'antd'
import moment from 'moment'
import React, { Fragment, useRef, useState } from 'react'
import { BackendUrl } from '../../actions/mainActions'
import './comment.scss'

function CommentItem ({ data, children, onChange }) {
  const userId = Number(JSON.parse(localStorage.getItem('user')).id)
  return (
    <>
      <div className='comment'>
        {
          data.owner.avatar
            ? <img src={BackendUrl + data.owner.avatar} alt="" className='avatar'/>
            : <Avatar size={42}/>
        }
        <div className='comment__body'>
          <div className="comment__header">
            <h3>{ `${data.owner.first_name} ${data.owner.last_name}` }</h3>
            <span>{ moment(data.created_at).format('DD.MM.YYYY hh:mm') }</span>
          </div>
          { data.text }
        </div>
        <div className='comment__actions'>
          <button onClick={() => onChange(data, 'reply')}>Ответить</button>
          {
            userId === data.owner.id &&
            <button onClick={() => onChange(data, 'edit')}>Редактировать</button>
          }
          {
            userId === data.owner.id &&
            <Popconfirm
              placement='bottom'
              onConfirm={() => onChange(data, 'delete')}
              title='Вы действительно хотите удалить комментарий?'
              okText='Да'
              cancelText='Нет'
            >
              <button>Удалить</button>
            </Popconfirm>
          }
        </div>
      </div>
      {
        children &&
        <div className='comment child'>
          { children }
        </div>
      }
    </>
  )
}

export default function Comment({
  commentList,
  onNewComment,
  onReplyComment,
  onEditComment,
  onDeleteComment,
  canComment,
}) {
  const [state, setState] = useState({
    selectedComment: null,
    actionType: null,
    inputValue: ''
  })
  const input = useRef(null)

  const onChange = (comment, type) => {
    switch (type) {
      case 'reply':
        setState({
          ...state,
          selectedComment: comment,
          actionType: type,
        })
        input.current.scrollIntoView()
        input.current.focus()
        return
      case 'edit':
        setState({
          ...state,
          selectedComment: comment,
          actionType: type,
          inputValue: comment.text
        })
        input.current.scrollIntoView()
        input.current.focus()
        return
      case 'delete':
        onDeleteComment(comment.id)
        return
      default: return
    }
  }

  const createComment = comment =>
    (
      <CommentItem data={comment} key={comment.id} onChange={onChange}>
        {
          !!comment.child_comments?.length && comment.child_comments.map(childComment =>
            <Fragment key={childComment.id}>
              {createComment(childComment)}
            </Fragment>
          )
        }
      </CommentItem>
    )
  
  const handleInputChange = event => setState({
    ...state,
    inputValue: event.target.value
  })

  const buttonText = () => {
    if (!state.selectedComment) return 'Отправить'
    switch (state.actionType) {
      case 'reply':
        return 'Ответить'
      case 'edit':
        return 'Редактировать'
      default:
        return 'Отправить'
    }
  }

  const handleSubmit = () => {
    if (!state.selectedComment) {
      onNewComment(state.inputValue)
    }
    switch (state.actionType) {
      case 'reply':
        onReplyComment({
          text: state.inputValue,
          commentId: state.selectedComment.id
        })
        break
      case 'edit':
        onEditComment({
          text: state.inputValue,
          commentId: state.selectedComment.id
        })
        break
      default: break
    }
    resetFields()
    
  }
  const resetFields = () =>
    setState({
      ...state,
      inputValue: '',
      selectedComment: null,
      actionType: null,
    })

  const getActionType = action => {
    switch (action) {
      case 'reply':
        return 'Ответить:'
      case 'edit':
        return 'Редактировать'
      default: return
    }
  }

  return (
    <div>
      {
        commentList.map(comment =>
          createComment(comment)
        )
      }
      <div>
        {
          state.selectedComment &&
          <>
            <span style={{ marginRight: '20px' }}>{getActionType(state.actionType)}</span>
            {
              state.actionType === 'reply' &&
              <span>{state.selectedComment.owner.first_name} {state.selectedComment.owner.last_name}</span>
            }
          </>
        }
        {canComment ? (<textarea
          className='comment__input'
          ref={input}
          value={state.inputValue}
          onChange={handleInputChange}
          placeholder="Добавить комментарий"
        />) : null}
        {canComment ? (<div style={{ display: 'flex', gap: '10px' }}>
          <Button disabled={!state.inputValue} type='primary' onClick={handleSubmit}>{buttonText()}</Button>
          {
            state.selectedComment &&
            <Button onClick={resetFields}>Отменить</Button>
          }
        </div>) : null }
      </div>
    </div>
  )
}
