import React, { Component } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_kazakhstanMap from "./kazakhstanMap.json";
// import am5geodata_kazakhstanMap from "@amcharts/amcharts5-geodata/kazakhstanLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { ReactComponent as IconMarker } from "../../../assets/images/icons/marker.svg";
import { ReactComponent as IconClose } from "../../../assets/images/icons/close_modal-dark.svg";
import {ReactComponent as IconProjectEmptyPDF} from "../../../assets/images/icons/presentation.svg";

import './chartMap.scss'
import { Link } from "react-router-dom";

class MapProjects extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      showSideBar: false,
      selectPolygon: {
        title: null,
        count: 0,
        allocatedMoney: '',
        projects: props.data
      }
    }
  }

  componentDidMount() {

    const cities = [
      { cityId: "KZ-ZHT", name: "ЖЕТЫСУСКАЯ ОБЛАСТЬ" },
      { cityId: "KZ-ALM", name: "АЛМАТИНСКАЯ ОБЛАСТЬ" },
      { cityId: "KZ-KAR", name: "КАРАГАНДИНСКАЯ ОБЛАСТЬ" },
      { cityId: "KZ-PAV", name: "ПАВЛОДАРСКАЯ ОБЛАСТЬ" },
      { cityId: "KZ-ZHA", name: "ЖАМБЫЛСКАЯ ОБЛАСТЬ" },
      { cityId: "KZ-AKM", name: "АКМОЛИНСКАЯ ОБЛАСТЬ" },
      { cityId: "KZ-ATY", name: "АТЫРАУСКАЯ ОБЛАСТЬ" },
      { cityId: "KZ-AKT", name: "АКТЮБИНСКАЯ ОБЛАСТЬ" },
      { cityId: "KZ-MAN", name: "МАНГИСТАУСКАЯ ОБЛАСТЬ" },
      { cityId: "KZ-TUR", name: "ТУРКЕСТАНСКАЯ ОБЛАСТЬ" },
      { cityId: "KZ-KZY", name: "КЫЗЫЛОРДИНСКАЯ ОБЛАСТЬ" },
      { cityId: "KZ-KUS", name: "КОСТАНАЙСКАЯ ОБЛАСТЬ" },
      { cityId: "KZ-ABY", name: "АБАЙСКАЯ ОБЛАСТЬ" },
      { cityId: "KZ-ULT", name: "УЛЫТАУСКАЯ ОБЛАСТЬ" },
      { cityId: "KZ-ZAP", name: "ЗКО" },
      { cityId: "KZ-VOS", name: "ВКО" },
      { cityId: "KZ-SEV", name: "СКО" }
    ]

    let root = am5.Root.new("chartMapdiv"); 
    root._logo.dispose();
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "none",
        panY: "none",
        projection: am5map.geoMercator(),
        wheelY: "none",
        minZoomLevel: 1,
        maxZoomLevel: 1
      })
    );

    // -------- Create polygon series --------
    const getCount = polygonId => {
      let count = this.props.data.filter(project => project.destination.id === polygonId).length
      if (count === 0 || count > 4) return `Крупных ${count} проектов`
      if (count < 5 && count > 1) return `Крупных ${count} проекта`
      return `${count} крупный проект`
    }

    // eslint-disable-next-line no-extend-native
    Number.prototype.format = function(n, x) {
      var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
      return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
    }

    const getAllocatedMoney = polygonId => {
      let sum = 0
      this.props.data.forEach(project => {
        if (project.destination.id === polygonId) sum += project.allocatedMoney
      })
      if (Math.floor(sum / 1000000) > 0) return Math.floor(sum / 1000000).format() + (String(Math.floor(sum / 1000000)).length > 3 ? ' млрд' : ' млн')
      return 0
    }

    const customMap = am5geodata_kazakhstanMap.features.map(polygon => {
      let customPolygon = polygon
      customPolygon.properties.count = getCount(polygon.id)
      customPolygon.properties.allocatedMoney = getAllocatedMoney(polygon.id)
      return customPolygon
    })

    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: { type: am5geodata_kazakhstanMap.type, features: customMap },
        include: cities.map(city => {
          return city.cityId
        }),
        fill: am5.color(0xBDD5E9),
        stroke: am5.color(0xffffff),
        strokeWidth: 5
      })
    );


    // -------- Map ToolTip Settings --------
    const generateToolTip = () => {
      return (
        `<div style="min-height:50px;width:255px;background-color:white;padding:12px;display:flex;grid-gap:12px;border: 1px solid #E9EBED;border-radius:10px;pointer-events:none;">
          <div style="height:12px;width:12px;background-color:#FFBE0B;border-radius:50%;margin-top:4px;"></div>
          <div>
            <p style="font-family:'SF Pro Display';font-weight:400;font-size:16px;line-height:19px;letter-spacing:0.15px;color:#051A2D;margin-bottom:10px;">{name}</p>
            <p style="font-family:'SF Pro Display';font-weight:400;font-size:16px;line-height:19px;letter-spacing:0.15px;color:#051A2D;margin-bottom:10px;">{count}</p>
            <p style="font-family:'SF Pro Display';font-weight:400;font-size:16px;line-height:19px;letter-spacing:0.15px;color:#051A2D;margin-bottom:2px;">
              <span style="color:#9A9EA6">ВЫДЕЛЕНО: </span>
              {allocatedMoney}
            </p>
          </div>
        </div>`
      )
    }

    const nameToLowerCase = (id, name) => {
      if (id === 'KZ-ZAP' || id === 'KZ-VOS' || id === 'KZ-SEV') return name
      return name[0].toUpperCase() + name.slice(1).toLowerCase()
    }

    polygonSeries.mapPolygons.template.setAll({
      interactive: true,
      tooltipHTML: generateToolTip(),
      templateField: "polygonSettings",
      showTooltipOn: 'hover'
    });


    const newSelectPolygon = (data) => {
      this.setState({ selectPolygon: {
        title: data.name,
        projects: this.props.data.filter(project => project.destination.id === data.id),
        allocatedMoney: data.allocatedMoney + ' тг',
        count: data.count
      } })
    }

    const howPage = () => {
      return this.props?.page
    }
    const showSideBar = () => {
      this.setState({
        showSideBar: true
      })
    }

    polygonSeries.mapPolygons.template.events.on("click", function(ev) {
      if (howPage() !== 'home') {
        newSelectPolygon(ev.target.dataItem.dataContext)
        showSideBar()
  
        polygonSeries.data.setAll(cities.map(city => {
          const polygon = {}
          polygon.id = city.cityId
          polygon.name = nameToLowerCase(city.cityId, city.name)
          if (city.cityId === 'KZ-ABY' || city.cityId === 'ZHT' || city.cityId === 'KZ-ULT') polygon.polygonSettings = { fill: am5.color('#A8C9E5')}
          if (city.cityId === ev.target.dataItem.dataContext.id) {
            polygon.polygonSettings = {
              fill: am5.color('#FFBE0B'),
              stroke: am5.color('#FFBE0B'),
              strokeWidth: 16,
              strokeOpacity: 0.4
            }
          }
          return polygon
        }));
      } else {
        window.location.href = window.location.href + 'landing-projects'
      }
    });

    let tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false
    });
    
    tooltip.get("background").setAll({
      fillOpacity: 0,
      strokeOpacity: 0
    });
    
    polygonSeries.set("tooltip", tooltip);
    
    polygonSeries.mapPolygons.template.states.create("hover", {
      fill: am5.color('#FFBE0B'),
      fillOpacity: .8,
      stroke: am5.color('#FFBE0B'),
      strokeWidth: 10,
      strokeOpacity: 0.2
    });

    // -------- Map Point Settings --------

    let pointSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        polygonIdField: "cityId",
      })
    );
    
    pointSeries.data.setAll(cities.map(city => { 
      let nameArray = city.name.split(' ')
      return {
        cityId: city.cityId,
        name: `${nameArray[0]}\n${nameArray.slice(1, 5)}`
      }
    }));

    pointSeries.bullets.push(function() {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          centerX: am5.p50,
          centerY: am5.p50,
          text: "{name}",
          fontSize: 12,
          fontStyle: 'italic',
          populateText: true
        })
      });
    });

    polygonSeries.data.setAll(cities.map(city => {
      const polygon = {}
      polygon.id = city.cityId
      polygon.name = nameToLowerCase(city.cityId, city.name)
      if (city.cityId === 'KZ-ABY' || city.cityId === 'KZ-ZHT' || city.cityId === 'KZ-ULT') polygon.polygonSettings = { fill: am5.color('#A8C9E5') }
      return polygon
    }));


    this.root = root;
  }

  componentWillUnmount() {
    if (this.root) this.root.dispose();
  }

  render() {
    const hideSideBar = () => {
      this.setState({
        showSideBar: false
      })
    }

    const formatToCurrnecy = number => {
      return new Intl.NumberFormat('ru-RU').format(number)
    }

    return (
      <div className={`investmentMap ${this.state.showSideBar ? 'active' : ''}`}>
        <div>
          <div id="chartMapdiv"></div>
        </div>
        <div className="oblProjects">
          <div className="oblProjects-obl">
            <div className="pin"><IconMarker /></div>
            <div>
              <h4 className="name">{ this.state.selectPolygon.title }</h4>
              <p className="count">{ this.state.selectPolygon.count }</p>
              <p className="price">Сумма инвестиций <span>{ this.state.selectPolygon.allocatedMoney }</span></p>
            </div>
          </div>

          <button className="close" onClick={hideSideBar}>
            <IconClose />
          </button>

          <div className="oblProjects-list">
            {
              this.state.selectPolygon.projects.map((project, key) => (
                (project.moreInfo || project.tableInfo) ? (
                  <Link className="project" key={key} to={`/landing-project/${project.id}`}>
                    <div className="pin"></div>
                    <div className="content">
                      <h4 className="name">{ project.title }</h4>
                      {/* <p className="type">Тип проекта: <span>{ project.type }</span></p> */}
                      <p className="price">{ formatToCurrnecy(project.allocatedMoney) }</p>
                    </div>
                    { !project.pdf_link ? (<IconProjectEmptyPDF className='pdf_svg' />) : null }
                  </Link>
                ) : (
                  <div className="project" key={key}>
                    <div className="pin" style={{ borderColor: project.color }}></div>
                    <div className="content">
                      <h4 className="name">{ project.title }</h4>
                      {/* <p className="type">Тип проекта: <span>{ project.type }</span></p> */}
                      <p className="price">{ formatToCurrnecy(project.allocatedMoney) }</p>
                    </div>
                    { !project.pdf_link ? (<IconProjectEmptyPDF className='pdf_svg' />) : null }
                  </div>
                )
              ))
            }
          </div>
        </div>
      </div>
    )
  }
}

export default MapProjects;
