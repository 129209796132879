import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

// Styles
import './System.scss'

import {Button} from 'antd'

// Icons & Images
import {ReactComponent as ReestrSVG} from "../../../assets/images/icons/about-system/reestr.svg";
import {ReactComponent as ProcessSVG} from "../../../assets/images/icons/about-system/process.svg";
import {ReactComponent as VisualizationSVG} from "../../../assets/images/icons/about-system/visualization.svg";
import {ReactComponent as DashboardSVG} from "../../../assets/images/icons/about-system/dashboard.svg";
import {ReactComponent as IerarchySVG} from "../../../assets/images/icons/about-system/ierarchy.svg"; 
import {ReactComponent as ReportSVG} from "../../../assets/images/icons/about-system/report.svg";
import {ReactComponent as GanttSVG} from "../../../assets/images/icons/about-system/Gantt.svg"; 
import {ReactComponent as RoadmapSVG} from "../../../assets/images/icons/about-system/Roadmap.svg"; 
import {ReactComponent as IntegrationSVG} from "../../../assets/images/icons/about-system/Integration.svg";  
import {ReactComponent as CalendarSVG} from "../../../assets/images/icons/about-system/Calendar.svg";  
import {ReactComponent as WikiSVG} from "../../../assets/images/icons/about-system/Wiki.svg";  
import {ReactComponent as ImportSVG} from "../../../assets/images/icons/about-system/Import.svg";  
import {ReactComponent as BacklogSVG} from "../../../assets/images/icons/about-system/Backlog.svg";  
import {ReactComponent as CriticalSVG} from "../../../assets/images/icons/about-system/Critical.svg";  

import {ReactComponent as Step1SVG} from "../../../assets/images/icons/about-system/Step1.svg";  
import {ReactComponent as Step2SVG} from "../../../assets/images/icons/about-system/Step2.svg";  
import {ReactComponent as Step3SVG} from "../../../assets/images/icons/about-system/Step3.svg";  
import {ReactComponent as Step4SVG} from "../../../assets/images/icons/about-system/Step4.svg";  




// Components
import Footer from "../../../components/uiLanding/layouts/Footer/footer";
import Header from "../../../components/uiLanding/layouts/Header/header";




class AboutSystem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: 'map'
    };
  }

  render() {
    return (
      <div className="aboutSystem">
        <Header />
          <div className="aboutSystem-block">
            <div className="container">
              <section className="aboutSystem-description">
                <div className="aboutSystem-header">
                  <div className="bread-crumbs"><Link to="/">Главная</Link> <span>I</span> <span>О системе</span></div>
                  <h2 className="landing-title">О системе</h2>
                </div>
                <img className="aboutSystem__picture-first" src={require('../../../assets/images/about-system1.png')} alt="About System"/>

                <div className="aboutSystem__first">
                  <p className="aboutSystem__first__bold">
                    ViewProject - система управления проектами. Основное назначение системы - это автоматизация процессов по проектному управлению 
                  </p>

                  <p className="aboutSystem__first__p">
                    Управление проектами — профессиональная деятельность, основанная на использовании современных научных знаний, навыков, методов, средств и технологий и ориентированная на получение эффективных результатов
                  </p>

                  <a
                  href={require(`../../../assets/images/tmp/about-system/presentation.pdf`)}
                  download
                  >
                    <Button>Скачать презентацию</Button>
                  </a>
                </div>
                
                <div className="aboutSystem__second">
                  <h2 className="aboutSystem__h2">Функциональные возможности системы</h2>
                  <div className="aboutSystem__second__grid">
                    <div className="grid__item">
                      <div className="grid__item__svg-holder">
                        <div className="grid__item__svg-holder__background">
                        </div>
                        <ReestrSVG />
                      </div>
                      <span className="grid__item__text">
                        Реестр проектов
                      </span>
                    </div>

                    <div className="grid__item">
                      <div className="grid__item__svg-holder">
                        <div className="grid__item__svg-holder__background">
                        </div>
                        <ProcessSVG />
                      </div>
                      <span className="grid__item__text">
                        Вехи проектов
                      </span>
                    </div>

                    <div className="grid__item">
                      <div className="grid__item__svg-holder">
                        <div className="grid__item__svg-holder__background">
                        </div>
                        <VisualizationSVG />
                      </div>
                      <span className="grid__item__text">
                        Визуализация объектов на карте
                      </span>
                    </div>

                    <div className="grid__item">
                      <div className="grid__item__svg-holder">
                        <div className="grid__item__svg-holder__background">
                        </div>
                        <DashboardSVG />
                      </div>
                      <span className="grid__item__text">
                        Персональная панель инструментов (dashboard)
                      </span>
                    </div>

                    <div className="grid__item">
                      <div className="grid__item__svg-holder">
                        <div className="grid__item__svg-holder__background">
                        </div>
                        <IerarchySVG />
                      </div>
                      <span className="grid__item__text">
                        Иерархическая структура работ (WBS)
                      </span>
                    </div>

                    <div className="grid__item">
                      <div className="grid__item__svg-holder">
                        <div className="grid__item__svg-holder__background">
                        </div>
                        <ReportSVG />
                      </div>
                      <span className="grid__item__text">
                        Отчеты
                      </span>
                    </div>

                    <div className="grid__item">
                      <div className="grid__item__svg-holder">
                        <div className="grid__item__svg-holder__background">
                        </div>
                        <GanttSVG />
                      </div>
                      <span className="grid__item__text">
                        Диаграмма Ганта
                      </span>
                    </div>

                    <div className="grid__item">
                      <div className="grid__item__svg-holder">
                        <div className="grid__item__svg-holder__background">
                        </div>
                        <RoadmapSVG />
                      </div>
                      <span className="grid__item__text">
                        Дорожная карта проекта
                      </span>
                    </div>

                    <div className="grid__item">
                      <div className="grid__item__svg-holder">
                        <div className="grid__item__svg-holder__background">
                        </div>
                        <IntegrationSVG />
                      </div>
                      <span className="grid__item__text">
                        Интеграция
                      </span>
                    </div>

                    <div className="grid__item">
                      <div className="grid__item__svg-holder">
                        <div className="grid__item__svg-holder__background">
                        </div>
                        <CalendarSVG />
                      </div>
                      <span className="grid__item__text">
                        Календарь
                      </span>
                    </div>

                    <div className="grid__item">
                      <div className="grid__item__svg-holder">
                        <div className="grid__item__svg-holder__background">
                        </div>
                        <WikiSVG />
                      </div>
                      <span className="grid__item__text">
                        Проектная документация (wiki)
                      </span>
                    </div>

                    <div className="grid__item">
                      <div className="grid__item__svg-holder">
                        <div className="grid__item__svg-holder__background">
                        </div>
                        <ImportSVG />
                      </div>
                      <span className="grid__item__text">
                        Импорт/Экспорт
                      </span>
                    </div>

                    <div className="grid__item">
                      <div className="grid__item__svg-holder">
                        <div className="grid__item__svg-holder__background">
                        </div>
                        <BacklogSVG />
                      </div>
                      <span className="grid__item__text">
                        Список задач (Backlog)
                      </span>
                    </div>

                    <div className="grid__item">
                      <div className="grid__item__svg-holder">
                        <div className="grid__item__svg-holder__background">
                        </div>
                        <CriticalSVG />
                      </div>
                      <span className="grid__item__text">
                        Критический путь
                      </span>
                    </div>

                  </div>
                </div>

                <div className="aboutSystem__third">
                  <h2 className="aboutSystem__h2">Цель проекта</h2>
                  <div className="aboutSystem__third__blue-box">

                  </div>
                  <div className="aboutSystem__third__text-holder">
                    <p className="aboutSystem__third__p">Единое управление в сфере туризма.</p>
                    <p className="aboutSystem__third__p">Стать единой системой для работы с разного вида проектами и обеспечить удобство их рассмотрения для инвесторов.</p>
                  </div>
                </div>
              </section>

              <section className="aboutSystem-investproject">
                <div className="aboutSystem-header">
                  <h2 className="landing-title">Инвестиционный проект</h2>
                </div>

                <img className="aboutSystem__picture-second" src={require('../../../assets/images/about-system2.png')} alt='About System (InvestProject)'/>
                <div className="aboutSystem__first">
                  <p className="aboutSystem__first__p">
                    Одной из функций системы является оказание содействия привлечению инвестиций в реализацию инвестиционных проектов. 
                  </p>

                  <p className="aboutSystem__first__p">
                    На сайте предоставлена информация о реализованных и реализуемых проектов туристкой отрасли. 
                  </p>

                  <p className="aboutSystem__first__p">
                    В случае заинтересованности АО «НК «Kazakh Invest» предоставит необходимую информацию по инвестиционным проектам.
                  </p>
                </div>
                <div className="aboutSystem__investproject__second">
                  <p>
                    Конструктор инвестиционных проектов. 
                  </p>
                  <p>
                    Интерфейса открытых данных системы ViewProject для инвестора
                  </p>
                </div>
                <p className="aboutSystem__investproject__steps__text">
                  Подача заявки разделена по шагам:
                </p>
                <div className="aboutSystem__investproject__steps">
                  <div className="step">
                    <Step1SVG />
                    Выбор категории проекта
                  </div>
                  <div className="step">
                    <Step2SVG />
                    Выбор типа
                  </div>
                  <div className="step">
                    <Step3SVG />
                    Оформление заявки
                  </div>
                  <div className="step">
                    <Step4SVG />
                    Отправка заявки
                  </div>
                </div>
                <div className="aboutSystem__investproject__steps__images">
                  <img src={require('../../../assets/images/about-system3.png')} alt="About System"/>
                  <img src={require('../../../assets/images/about-system4.png')} alt="About System"/>
                </div>
              </section>
            </div>
          </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(AboutSystem);
