import React, {Component} from "react";
import {Col, Row, message, Popconfirm, Spin} from "antd";
import moment from "moment";
import {deleteNotif, readNotif} from "../actions/mainActions";
import {ReactComponent as Delete} from "../assets/images/icons/delete.svg";

class NotificationsPage extends Component {
    state = {
        loading: false
    }
    DeleteNotification = (id, read) => {
        this.setState({
            loading: true
        })
        deleteNotif(id).then(res => {
            this.props.getNewNotif();
            this.setState({
                loading: false
            })
            message.success('Уведомление удалено');

        });
    };
    readNotification = (id, read) => {
        this.setState({
            loading: true
        })
        readNotif(id).then(res => {
            this.props.getNewNotif();
            this.setState({
                loading: false
            })
            message.success(read ? 'Уведомление отмечено, как непрочитанное' : 'Уведомление отмечено, как прочитанное');
        });
    };


    render() {
        const {loading} = this.state;
        return (
            <div>
                <Spin spinning={loading}>
                <h2 className='componentTitle'>Список уведомлений</h2>

                    {this.props.notifications && this.props.notifications.map((el) =>
                        <Row key={el.id} className="notificateRow defautlWhiteBg">
                            <Col span={8}><p
                                style={el.status === 'read' ? {} : {fontWeight: 'bold'}}>{`${el.name} - ${el.description}`}</p>
                            </Col>
                            <Col style={{color: "#A0A0A0"}} span={4}>{moment(el.created_at).format("DD.MM.YYYY")}</Col>
                            <Col span={1}>
                                <span onClick={() => this.readNotification(el.id, el.status === 'read')}
                                      className={el.status === 'read' ? 'readedDot' : 'unreadedDot'}>•</span>
                            </Col>
                            <Col span={1}>
                                <Popconfirm placement="topLeft" title={'Удалить уведомление?'}
                                            onConfirm={() => this.DeleteNotification(el.id)} okText="Да"
                                            cancelText="Нет">

                                    <Delete className={"deleteIcon"}/>
                                </Popconfirm>
                            </Col>
                        </Row>)
                    }
                </Spin>
            </div>
        );
    }
}

export default NotificationsPage;
