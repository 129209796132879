import React, { Component } from "react";
import { Document, Page } from "react-pdf/dist/entry.webpack";
// Styles
import './projectDetail.scss'
import '../FinishedProject/finishedProject.scss'

import { Modal, Button, Spin } from "antd";
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";

// Icons & Images
import {ReactComponent as IconArrowToRight} from "../../../assets/images/icons/icon-arrow-right.svg";
import {ReactComponent as IconDownload} from "../../../assets/images/icons/icon-cloud-download.svg";
import {ReactComponent as IconProjectEmptyPDF} from "../../../assets/images/icons/presentation.svg";

import {ReactComponent as IconCalendar} from "../../../assets/images/icons/finished-projects/calendar.svg";
import {ReactComponent as IconPrice} from "../../../assets/images/icons/finished-projects/price.svg";
import {ReactComponent as IconRegion} from "../../../assets/images/icons/finished-projects/region.svg";

import {ReactComponent as IconProjectInfo1} from "../../../assets/images/icons/project-info/project-info-1.svg";
import {ReactComponent as IconProjectInfo2} from "../../../assets/images/icons/project-info/project-info-2.svg";
import {ReactComponent as IconProjectInfo3} from "../../../assets/images/icons/project-info/project-info-3.svg";
import {ReactComponent as IconProjectInfo4} from "../../../assets/images/icons/project-info/project-info-4.svg";
import {ReactComponent as IconProjectInfo5} from "../../../assets/images/icons/project-info/project-info-5.svg";
import {ReactComponent as IconProjectInfo6} from "../../../assets/images/icons/project-info/project-info-6.svg";
import {ReactComponent as IconProjectInfo7} from "../../../assets/images/icons/project-info/project-info-7.svg";
import {ReactComponent as IconProjectInfo8} from "../../../assets/images/icons/project-info/project-info-8.svg";
import {ReactComponent as IconProjectInfo9} from "../../../assets/images/icons/project-info/project-info-9.svg";
import {ReactComponent as IconProjectInfo10} from "../../../assets/images/icons/project-info/project-info-10.svg";
import {ReactComponent as IconProjectInfo11} from "../../../assets/images/icons/project-info/project-info-11.svg";
import {ReactComponent as IconProjectInfo12} from "../../../assets/images/icons/project-info/project-info-12.svg";
import {ReactComponent as IconProjectInfo13} from "../../../assets/images/icons/project-info/project-info-13.svg";
import {ReactComponent as IconProjectInfo14} from "../../../assets/images/icons/project-info/project-info-14.svg";
import {ReactComponent as IconProjectInfo15} from "../../../assets/images/icons/project-info/project-info-15.svg";
import {ReactComponent as IconProjectInfo16} from "../../../assets/images/icons/project-info/project-info-16.svg";
import {ReactComponent as IconProjectInfo17} from "../../../assets/images/icons/project-info/project-info-17.svg";
import {ReactComponent as IconProjectInfo18} from "../../../assets/images/icons/project-info/project-info-18.svg";
import {ReactComponent as IconProjectInfo19} from "../../../assets/images/icons/project-info/project-info-19.svg";
import {ReactComponent as IconProjectInfo20} from "../../../assets/images/icons/project-info/project-info-20.svg";
import {ReactComponent as IconProjectInfo21} from "../../../assets/images/icons/project-info/project-info-21.svg";
import {ReactComponent as IconProjectInfo22} from "../../../assets/images/icons/project-info/project-info-22.svg";
import {ReactComponent as IconProjectInfo23} from "../../../assets/images/icons/project-info/project-info-23.svg";
import {ReactComponent as IconProjectInfo24} from "../../../assets/images/icons/project-info/project-info-24.svg";
import {ReactComponent as IconProjectInfo25} from "../../../assets/images/icons/project-info/project-info-25.svg";
import {ReactComponent as IconProjectInfo26} from "../../../assets/images/icons/project-info/project-info-26.svg";

// Components
import Footer from "../../../components/uiLanding/layouts/Footer/footer";
import Header from "../../../components/uiLanding/layouts/Header/header";

import { getProjectData } from "../../../actions/projects-list"

// Import styles
import { ModalPDF } from "../../../components/ModalPDF/modalpdf";

class LandingProjectDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: 'map',
      pageNumber: 1,
      numPages: 1,
      modalLoaded: false,
      modalPageNumber: 1,
    };
  }

  viewChange = (type) => {
    this.setState({ viewType: type })
  }

  onDocumentLoadSuccess = (pdfObject) => {
    const numPages = pdfObject.numPages;

    this.setState({
      numPages
    })
  }

  pdfNextPage = (currentPage) => {
    
    this.setState({
      pageNumber: currentPage + 1,
      modalPageNumber: currentPage + 1,
    })
  }

  pdfPrevPage = (currentPage) => {
    
    this.setState({
      pageNumber: currentPage - 1,
      modalPageNumber: currentPage - 1,
    })
  }

  showModal = () => {
    this.setState({
      modalVisible: true,
    })
  }

  hideModal = () => {
    this.setState({
      modalVisible: false,
    })
  }

  handlePageChangeInModal = (page) => {
    const pageNumber = page.currentPage + 1;

    this.setState({
      modalPageNumber: pageNumber,
    })
  }

  render() {
    const projectData = getProjectData(this.props.match.params.project_id)
    const infoArray = []
    if (projectData.moreInfo) Object.entries(projectData.moreInfo).forEach(([key, value]) => {
      infoArray.push({ title: key, text: value })
    })

    const havePDF = projectData.pdf_link !== undefined

    return (
      <div className="projectDetail-landing">
        {
          havePDF ? (
            <Modal
              className="pdfModal"
              title={null}
              centered
              closable={false}
              footer={null}
              width={"auto"}
              visible={this.state.modalVisible}
              onOk={() => {}}
              onCancel={() => {this.hideModal()}}
              maskStyle={{
                background: 'rgba(0, 43, 111, 0.8)',
              }}
            >
              <ModalPDF 
              pageNumber={this.state.pageNumber-1}
              modalPageNumber={this.state.modalPageNumber}
              handlePageChange={this.handlePageChangeInModal}
              fileUrl={require(`../../../assets/images/tmp/projects-presentations-pdf/${projectData.pdf_link}`)}>
              </ModalPDF>
              <span className="pdf__modal__pages">
                {`${this.state.modalPageNumber}/${this.state.numPages} стр`}
              </span>
              <svg onClick={() => {this.hideModal()}} className="pdf__modal__close-svg" width="69" height="64" viewBox="0 0 69 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line y1="-1" x2="89.8276" y2="-1" transform="matrix(0.723608 0.690211 -0.55717 0.830398 2 2)" stroke="white" stroke-width="2"/>
                <line y1="-1" x2="89.8277" y2="-1" transform="matrix(0.723606 -0.690213 0.557173 0.830396 2 64)" stroke="white" stroke-width="2"/>
              </svg>
            </Modal>
          ) : null
        }
        <Header />

        <div className="projectDetail-landing-inner container">
          <div className="projectDetail-landing-content">
            <div className="bread-crumbs"><button onClick={() => window.history.back()}><IconArrowToRight /> Назад</button></div>
            <h2 className="landing-title">{ projectData.title }</h2>

            {
              projectData.tableInfo ? (
                <div className="projectFinished-landing">
                  <div className="projectFinished-landing-table">
                  <div className="projectFinished-landing-item">
                    <IconCalendar />
                    <div className="projectFinished-landing-rows">
                      <div className="projectFinished-landing-row">
                        <div className="title">Период начала</div>
                        <div className="value">{ projectData.tableInfo.calendar.start }</div>
                      </div>
                      <div className="projectFinished-landing-row">
                        <div className="title">Период завершения</div>
                        <div className="value">{ projectData.tableInfo.calendar.end }</div>
                      </div>
                    </div>
                  </div>


                    <div className="projectFinished-landing-item">
                      <IconPrice />
                      <div className="projectFinished-landing-rows">
                        <div className="projectFinished-landing-row">
                          <div className="title">Стоимость проекта, млн. тенге</div>
                          <div className="value">{ projectData.allocatedMoney / 1000000 }</div>
                        </div>
                      </div>
                    </div>

                    <div className="projectFinished-landing-item">
                      <IconRegion />
                      <div className="projectFinished-landing-rows">
                        <div className="projectFinished-landing-row">
                          <div className="title">Регион</div>
                          <div className="value">{ projectData.tableInfo.placeInfo.region }</div>
                        </div>
                        <div className="projectFinished-landing-row">
                          <div className="title">Район/город</div>
                          <div className="value">{ projectData.tableInfo.placeInfo.city }</div>
                        </div>
                        <div className="projectFinished-landing-row">
                          <div className="title">Количество номеров</div>
                          <div className="value">{ projectData.tableInfo.placeInfo.rooms }</div>
                        </div>
                        <div className="projectFinished-landing-row">
                          <div className="title">Койко-места</div>
                          <div className="value">{ projectData.tableInfo.placeInfo.enoughBeds }</div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              ) : (
                <>
                  { infoArray.map((item, key) => (
                    <div className="prejectDetail-info" key={key}>
                      { item.title === 'compass' && <IconProjectInfo1 className='prejectDetail__svg'/> }
                      { item.title === 'government' && <IconProjectInfo2 className='prejectDetail__svg' /> }
                      { item.title === 'visits2' && <IconProjectInfo3 className='prejectDetail__svg' /> }
                      { item.title === 'revenue' && <IconProjectInfo4 className='prejectDetail__svg' /> }
                      { item.title === 'health' && <IconProjectInfo5 className='prejectDetail__svg' /> }
                      { item.title === 'mainTerritory' && <IconProjectInfo6 className='prejectDetail__svg' /> }
                      { item.title === 'wellnessFacilities' && <IconProjectInfo7 className='prejectDetail__svg' /> }
                      { item.title === 'status' && <IconProjectInfo8 className='prejectDetail__svg' /> }
                      { item.title === 'spirituality' && <IconProjectInfo9 className='prejectDetail__svg' /> }
                      { item.title === 'history' && <IconProjectInfo10 className='prejectDetail__svg' /> }
                      { item.title === 'duraitionOfStay' && <IconProjectInfo11 className='prejectDetail__svg' /> }
                      { item.title === 'touristGrowth' && <IconProjectInfo12 className='prejectDetail__svg' /> }
                      { item.title === 'profitFromProject' && <IconProjectInfo13 className='prejectDetail__svg' /> }
                      { item.title === 'newEcoZone' && <IconProjectInfo14 className='prejectDetail__svg' /> }
                      { item.title === 'map' && <IconProjectInfo15 className='prejectDetail__svg' /> }
                      { item.title === 'newKeyRooms' && <IconProjectInfo16 className='prejectDetail__svg' /> }
                      { item.title === 'visits' && <IconProjectInfo17 className='prejectDetail__svg' /> }
                      { item.title === 'analitics' && <IconProjectInfo18 className='prejectDetail__svg' /> }
                      { item.title === 'placeOfRest' && <IconProjectInfo19 className='prejectDetail__svg' /> }
                      { item.title === 'supportFromState' && <IconProjectInfo20 className='prejectDetail__svg' /> }
                      { item.title === 'beach' && <IconProjectInfo21 className='prejectDetail__svg' /> }
                      { item.title === 'ecoTourism' && <IconProjectInfo22 className='prejectDetail__svg' /> }
                      { item.title === 'keyAreas' && <IconProjectInfo23 className='prejectDetail__svg' /> }
                      { item.title === 'construction' && <IconProjectInfo24 className='prejectDetail__svg' /> }
                      { item.title === 'newRooms' && <IconProjectInfo25 className='prejectDetail__svg' /> }
                      { item.title === 'additionalRooms' && <IconProjectInfo26 className='prejectDetail__svg' /> }

                      <p className="prejectDetail-info-text">{ item.text }</p>
                    </div>
                  )) }
                </>
              )
            }

          </div>
          <div className="projectDetail-landing-preview">
          <a
            className="projectDetail-landing-download"
            href={
              havePDF
              ? require(`../../../assets/images/tmp/projects-presentations-pdf/${projectData.pdf_link}`)
              : null
            }
            download={ projectData.title }
            disabled={!havePDF}
          >
            <IconDownload />
          </a>

            {
              havePDF ? (
                <Document 
                loading={<div className="pdf__loader"><Spin /></div>}
                file={require(`../../../assets/images/tmp/projects-presentations-pdf/${projectData.pdf_link}`)}
                onLoadSuccess={this.onDocumentLoadSuccess}>
                  <Page 
                  loading={<div className="pdf__loader"><Spin /></div>}
                  pageNumber={this.state.pageNumber} 
                  height={632}
                  onClick={() => {this.showModal()}}/>

                  <Button 
                  style={this.state.pageNumber === 1 ? {opacity: '0.5'} : null} 
                  disabled={this.state.pageNumber === 1 ? true : false} 
                  size="large" 
                  className="prejectDetail__pdf__button pdf__button__prev"
                  onClick={() => {this.pdfPrevPage(this.state.pageNumber)}}>
                    <ArrowLeftOutlined 
                    style={{color: 'rgba(0, 43, 111, 1)'}}/>
                  </Button>

                  <Button 
                  style={this.state.pageNumber === this.state.numPages ? {opacity: '0.5'} : null} 
                  disabled={this.state.pageNumber === this.state.numPages ? true : false} 
                  size="large" 
                  className="prejectDetail__pdf__button pdf__button__next"
                  onClick={() => {this.pdfNextPage(this.state.pageNumber)}}>
                    <ArrowRightOutlined 
                    style={{color: 'rgba(0, 43, 111, 1)'}}/>
                  </Button>
                </Document>
              ) : (
                <div className="pdf_empty">
                  <IconProjectEmptyPDF className='pdf_empty_svg' /> 
                  <h4 className="pdf_empty_title">Проект еще в реализации!</h4>
                </div>
              )
            }
            {/* <button className="projectDetail-landing-download" ><IconDownload /></button> */}

            {/* <a
              className="projectDetail-landing-presentation"
              href={require(`../../../assets/images/tmp/projects-presentations-pdf/Project-${projectData.id}.pdf`)}
              target="_blank"
            >
              <img src={require('../../../assets/images/tmp/projects-presentations/project-' + projectData.id + '.jpg')} alt="" /> */}
              {/* <div className="projectDetail-landing-controls">
                <button><IconArrowToRight /></button>
                <button><IconArrowToRight /></button>
              </div> */}
            {/* </a> */}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default LandingProjectDetail;
