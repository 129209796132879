import React, { Component } from "react";
import { Link } from "react-router-dom";

// Icons - Images
import { ReactComponent as IconLogo } from "../../../../assets/images/icons/logo-white-title.svg";
import { ReactComponent as IconPhone } from "../../../../assets/images/icons/icon-phone-incoming.svg";
import { ReactComponent as IconEmail } from "../../../../assets/images/icons/icon-mail.svg";
import { ReactComponent as IconInstagram } from "../../../../assets/images/icons/social-networks/instagram.svg";
import { ReactComponent as IconFacebook } from "../../../../assets/images/icons/social-networks/facebook.svg";
import { ReactComponent as IconYouTube } from "../../../../assets/images/icons/social-networks/youtube.svg";

// CSS Styles
import './footer.scss'
import { Button } from "antd";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="footer-inner">
            <div className="footer-left">
              <Link to='/'>
                <IconLogo className="footer-logo" />
              </Link>

              <div className="footer-socialNetworks">
              <a className="footer-socialNetworks-link" target="_blank" rel="noopener noreferrer"href="https://www.instagram.com/kazakhtourism_official/">
                  <IconInstagram />
                </a>
                <a className="footer-socialNetworks-link" target="_blank" rel="noopener noreferrer"href="https://www.facebook.com/TraveltoKazakhstan/">
                  <IconFacebook />
                </a>
                <a className="footer-socialNetworks-link" target="_blank" rel="noopener noreferrer"href="https://www.youtube.com/kazakhstantravel">
                  <IconYouTube />
                </a>
              </div>

              <Link to='/create-projects'>
                <Button className="footer-submit" type="primary" size="large">Подать заявку</Button>
              </Link>
            </div>
            <div className="footer-right">
              <div className="footer-col">
                <h4 className="footer-col-title">Меню</h4>
                <nav className="footer-nav">
                  <Link to='/about-system' className="footer-link">О системе</Link>
                  <Link to='/landing-projects' className="footer-link">Проекты</Link>
                  <Link to='/state-support-measures' className="footer-link">Меры господдержки</Link>
                </nav>
              </div>
              <div className="footer-col">
                <h4 className="footer-col-title">Контакты</h4>
        {/*        <p className="description">Поддержка и сопровождение инвестиционных проектов в ЩБКЗ. <br/> Помощь в поиске земельного участка и оформлении необходимых документов</p>
*/}
                <a className="footer-link-block" href="tel:+77018884728" target="_blank" rel="noopener noreferrer">
                  <IconPhone />
                  <p className="footer-link">+7(701) 888 47 28</p>
                </a>
                <a className="footer-link-block" href="mailto: y.adilkhanov@gmail.com" target="_blank" rel="noopener noreferrer">
                  <IconEmail />
                  <p className="footer-link"> y.adilkhanov@gmail.com</p>
                </a>
              </div>
            </div>
          </div>
  {/*        <p className="footer-copyright">Copyright © 2022. Crafted with love.</p>*/}
        </div>
      </footer>
    );
  }
}

export default Footer;
