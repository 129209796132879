import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

// Styles
import './stateSupportMeasures.scss'

// Icons & Images

// Components
import Footer from "../../../components/uiLanding/layouts/Footer/footer";
import Header from "../../../components/uiLanding/layouts/Header/header";




class StateSupportMeasures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: 'map'
    };
  }

  viewChange = (type) => {
    this.setState({ viewType: type })
  }

  render() {
    return (
      <div className="stateSupportMeasures">
        <Header />

        <div className="stateSupportMeasures-block">
          <div className="container">
            <section className="stateSupportMeasures-inner">
              <div className="stateSupportMeasures-header">
                <div className="bread-crumbs"><Link to="/">Главная</Link> <span>I</span> <span>Меры господдержки</span></div>
                <h2 className="landing-title">Меры господдержки</h2>
              </div>

              <div className="stateSupportMeasures-list">
                <div className="left">
                  <div className="stateSupportMeasures-list-item">
                    <p>Об утверждении Правил возмещения части затрат субъектов предпринимательства по приобретению оборудования и техники для горнолыжных курортов</p>
                    <a target="_blank" rel="noopener noreferrer" href="https://adilet.zan.kz/rus/docs/V2100026382?fbclid=IwAR0iMJhXSEzsoAHPaa77EaqJk9GUbQPDRdy7Gy-6s88hNNV5_xbhDDUnwxo">https://adilet.zan.kz/rus/docs/V2100026382?fbclid=IwAR0iMJhXSEzsoAHPaa77EaqJk9GUbQPDRdy7Gy-6s88hNNV5_xbhDDUnwxo</a>
                  </div>
                  <div className="stateSupportMeasures-list-item">
                    <p>Об утверждении Правил возмещения части затрат субъектов предпринимательства по строительству объектов придорожного сервиса</p>
                    <a target="_blank" rel="noopener noreferrer" href="https://adilet.zan.kz/rus/docs/V2100026375?fbclid=IwAR2I24Vu_l4t0iiEx3jtkoP_RXAdq8Bcvjq20Ct4as1HCdEF6ycZwIFqX_w">https://adilet.zan.kz/rus/docs/V2100026375?fbclid=IwAR2I24Vu_l4t0iiEx3jtkoP_RXAdq8Bcvjq20Ct4as1HCdEF6ycZwIFqX_w</a>
                  </div>

                </div>

                <div className="right">
                <div className="stateSupportMeasures-list-item">
                    <p>Об утверждении Правил возмещения части затрат субъектов предпринимательства при строительстве, реконструкции объектов туристской деятельности</p>
                    <a target="_blank" rel="noopener noreferrer" href="https://adilet.zan.kz/rus/docs/V2100026403?fbclid=IwAR0UdQiBIh_TMk9c50dM3Sw9AnHsNqyS8fAelQG2b3wFB4018JKLcjaYshU">https://adilet.zan.kz/rus/docs/V2100026403?fbclid=IwAR0UdQiBIh_TMk9c50dM3Sw9AnHsNqyS8fAelQG2b3wFB4018JKLcjaYshU</a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(StateSupportMeasures);
