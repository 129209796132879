import React, { Component } from "react";
import { Button, Row } from "antd";
import { Link, withRouter } from "react-router-dom";

// Styles
import './projects.scss'

// Icons & Images
import { ReactComponent as ViewMap } from "../../../assets/images/icons/icon-location-marker.svg";
import { ReactComponent as ViewGrid } from "../../../assets/images/icons/view-grid.svg";
import { ReactComponent as ViewList } from "../../../assets/images/icons/view-list.svg";

// Components
import Footer from "../../../components/uiLanding/layouts/Footer/footer";
import Header from "../../../components/uiLanding/layouts/Header/header";
import MapProjects from "../../../components/uiLanding/MapProjects/mapProjects";
import TableProjects from "../../../components/uiLanding/TableProjects/tableProjects";

import { projectsList } from "../../../actions/projects-list"

class LandingProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: 'map'
    };
  }

  viewChange = (type) => {
    this.setState({ viewType: type })
  }

  render() {
    return (
      <div className="projectsLanding">
        <Header />

        <div className="projectsLanding-block">
          <div className="container">
            <section className="projectsLanding-inner">
              <Row style={{ justifyContent: 'space-between', marginBottom: "25px" }}>
                <div>
                  <div className="bread-crumbs"><Link to="/">Главная</Link> <span>I</span> <span>Карта</span></div>
                  <h2 className="landing-title">Карта проектов</h2>
                </div>
                <Row style={{ alignItems: 'center', justifyContent: 'end', gridGap: "30px" }}>
                  <div className='projectsLanding-view-filter'>
                    <Button className={`projectsLanding-filter-view-btn type-map map ${this.state.viewType === 'map' ? 'active' : ''}`}
                      onClick={() => this.viewChange('map')}
                      icon={<ViewMap />}
                    />
                    <Button className={`projectsLanding-filter-view-btn type-grid ${this.state.viewType === 'grid' ? 'active' : ''}`}
                      onClick={() => this.viewChange('grid')}
                      icon={<ViewGrid />}
                    />
                    <Button className={`projectsLanding-filter-view-btn type-list ${this.state.viewType === 'list' ? 'active' : ''}`}
                      onClick={() => this.viewChange('list')}
                      icon={<ViewList />}
                    />
                  </div>
                </Row>
              </Row>


              { this.state.viewType === 'map' && <MapProjects data={projectsList} /> }
              { (this.state.viewType === 'grid' || this.state.viewType === 'list') && <TableProjects viewType={this.state.viewType} data={projectsList} /> }
            </section>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(LandingProjects);
