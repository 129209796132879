import React, {Component} from "react";
import {Row, Select, Spin, Tooltip, Col, Empty, Switch} from "antd";
import {Bar, Doughnut} from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {ReactComponent as File} from "../../assets/images/icons/file.svg";

import "@lourenci/react-kanban/dist/styles.css";
import "./panel.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {Redirect} from "react-router-dom";
import noAvatar from "../../assets/images/avatar.png"

import { ReactComponent as IconArrowToRight } from "../../assets/images/icons/arrow-to-right.svg";
import { ReactComponent as IconDefaultPeopleAvatar } from "../../assets/images/icons/default_people_avatar.svg";

import moment from "moment";
import {getProject, getProjects, BackendUrl, getDashboardData, getCompaniesList, getProjectsWithFilter, getProfileById, getUsersFromAllOrganisations} from "../../actions/mainActions";
import {getMembers, getTaskWithFilter} from "../../actions/mainActions";

const {Option} = Select;

const toNext = () => {
    document.querySelectorAll('#tasks-today__slider')[0].scrollBy(545, 0);
}

const toPrev = () => {
    document.querySelectorAll('#tasks-today__slider')[0].scrollBy(0 - 545, 0)
}

const tasks = {
    options: {
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: 'Задачи',
                color: "black",
                position: 'top',
                align: 'start',
                font: {
                    family: 'SF Pro Display, sans-serif',
                    size: 16,
                    weight: 600,
                },
                padding: {
                    bottom: 41,
                    top: 20,
                }
            },
            datalabels: {
                display: true,
                color: 'rgba(255, 255, 255, 0.5)',
                font: {
                    family: 'SF Pro Display',
                    size: 16,
                },
                formatter: (value) => {
                    return value > 0 ? value : ''
                }
            },
            legend: {
                position: "right",
                align: 'center',
                labels: {
                    boxWidth: 14,
                    boxHeight: 14,
                    color: '#96A0B5',
                    padding: 45,
                    font: {
                        family: 'sans-serif',
                        size: 12,
                        weight: '400',
                    }
                }
            }
        }
    }
}

const tasksPriorities = {
    options: {
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: 'Количество незавершенных задач по приоритету',
                color: "black",
                position: 'top',
                align: 'start',
                font: {
                    family: 'SF Pro Display, sans-serif',
                    size: 16,
                    weight: 600,
                },
                padding: {
                    bottom: 41,
                    top: 20,
                }
            },
            datalabels: {
                display: true,
                color: 'rgba(255, 255, 255, 0.5)',
                font: {
                    family: 'SF Pro Display',
                    size: 16,
                },
                formatter: (value) => {
                    return value > 0 ? value : ''
                }
            },
            legend: {
                position: "right",
                align: 'center',
                labels: {
                    boxWidth: 80,
                    padding: 10
                }
            }
        }
    }
}

const tasksByMonths = {
    options: {
        scales: {
            y: {
                ticks: {
                    beginAtZero: true,
                    callback: function(value) {if (value % 1 === 0) {return value;}}
                },
                title: {
                    display: true,
                    text: 'Количество задач',
                    color: '#96A0B5',
                    font: {
                        size: '14px',
                        family: 'sans-serif',
                    },
                    padding: {
                        bottom: 20,
                    }
                },
            },
            x: {
                grid: {
                    display: false,
                }
            }
        },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
            title: {
                display: true,
                text: 'Количество выполненных задач по месяцам',
                color: "black",
                position: 'top',
                align: 'start',
                font: {
                    family: 'SF Pro Display, sans-serif',
                    size: 16,
                    weight: 600,
                },
                padding: {
                    bottom: 41,
                    top: 20,
                }
            },
            datalabels: {
                display: false,
            },
        },
    }
}

const projectsInfo = {
    options: {
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Количество проектов',
                    color: '#96A0B5',
                    font: {
                        size: '14px',
                        family: 'sans-serif',
                    },
                    padding: {
                        bottom: 20,
                    }
                },
            },
            x: {
                grid: {
                    display: false,
                }
            }
        },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
            title: {
                display: true,
                text: 'Проекты по статусу',
                color: "black",
                position: 'top',
                align: 'start',
                font: {
                    family: 'SF Pro Display, sans-serif',
                    size: 16,
                    weight: 600,
                },
                padding: {
                    bottom: 41,
                    top: 20,
                }
            },
            datalabels: {
                display: true,
                color: ['#96A0B5', '#FFFFFF', '#FFFFFF'],
                anchor: "start",
                align: "end",
                offset: 10,
                font: {
                    family: 'SF Pro Display',
                    size: 24,
                },
            },
        },
    }
}

export class Panel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizationsList: null,
            selectedOrganization: 'all',

            projectsList: null,
            selectedProject: 'all',

            usersList: null,
            selectedUser: 'all',

            allMembers: null,

            onlyMyProjects: false,

            loading: true,
            taskList: null,
            todayTasks: [],

            overdueTasksCount: null,
            projectsData: {
                labels: [`Всего`, "В работе", "Завершено"], 
                datasets: [{
                    data: [0,0,0],
                    backgroundColor: ['#E7EDF2', '#1D62CB', '#28C397']
                }],
            },
            tasksCategoriesData: {
                labels: [`Backlog`, "В работе", "На утверждении", "Готово"], 
                datasets: [{
                    data: [20, 8, 11, 10],
                    backgroundColor: ['#338DE0', '#1D62CB', '#FCC865', '#28C397']
                }],
            },
            tasksPrioritiesData: {
                labels: ["Высокий", "Средний", "Низкий"], 
                datasets: [{
                    data: [2, 2, 1],
                    backgroundColor: ['#FF575F', '#FCC865', '#28C397']
                }],
            },
            tasksByMonthsData: {
                labels: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
                datasets: [{
                    data: [1,2,3,4,5,6,7,8,9,10,11,12],
                    backgroundColor: '#5CA9FF'
                }]
            },
            userInfo: null,
            currentRole: null,
        };
    }

    getInitialDashboardData = async () => {
        await this.getCurrentUserInfo();
        await this.getAllOrganizationsList();
        getUsersFromAllOrganisations().then((response) => {
            this.setState({
                allMembers: response.data.data,
                usersList: response.data.data,
            })
        })
    }

    getAllProjectsInfo = () => {
        if (this.state.currentRole === 'root' || this.state.currentRole === 'admin') {
            this.getAdminView();
        } else {
            this.getOnlyMyView();
        }
    }

    getCurrentUserInfo = async () => {
        const userInfo = JSON.parse(localStorage.getItem("user"));

        this.setState({
            userInfo,
            currentRole: userInfo.role,
        })

        console.log(`userInfo`)
        console.log(userInfo)
    }

    getTodayTasks = (tasklist) => {
        const userInfo = JSON.parse(localStorage.getItem("user"));

        const myEmail = userInfo.email;

        const myTasks = tasklist.filter((task) => {

            let isMyTask = false;

            if (task.status === 'archive ') {
                return false;
            }

            if (task.members && task.members.length > 0) {
                const membersList = task.members;
                
                for (let i = 0; i < membersList.length; i++) {
                    if (membersList[i].email.toString().toUpperCase() === myEmail.toString().toUpperCase()) {
                        isMyTask = true;
                        break;
                    } else {
                        isMyTask = false;
                    }
                }
            } else {
                return false;
            }

            if (isMyTask) {
                return true;
            } else {
                return false;
            }
        })


        const today = new Date().setHours(0,0,0,0);

        const myTodayTasks = myTasks.filter((task) => {

            if (task.status === 'archive ' || task.status === 'done') {
                return false;
            }

            const start = new Date(task.start).setHours(0,0,0,0);
            const end = new Date(task.end).setHours(0,0,0,0);

            if (today >= start && today <= end) {
                return true;
            } else {
                return false;
            }
        })

        this.setState({
            todayTasks: myTodayTasks,
        })
    }

    handleProjects = () => {
        const projects = this.state.projectsList;
        let totalProjects = 0;
        let toDoProjects = 0;
        let doneProjects = 0;

        totalProjects = projects.length;

        projects.forEach((project) => {
            if (project.status) {
                if (project.status === 'to_do') {
                    toDoProjects += 1;
                } else {
                    doneProjects +=1;
                }
            }
        })
        
        this.setState({
            projectsData: {
                labels: [`Всего`, "В работе", "Завершено"], 
                datasets: [{
                    data: [totalProjects,toDoProjects,doneProjects],
                    backgroundColor: ['#E7EDF2', '#1D62CB', '#28C397']
                }],
            }
        })
    }

    handleTasks = (tasks = this.state.taskList) => {
        let backlogTasks = 0;
        let doingTasks = 0;
        let approvalTasks = 0;
        let doneTasks = 0;

        let highPriority = 0;
        let middlePriority = 0;
        let lowPriority = 0;

        tasks.forEach(({status, priority})=> {
            if (status !== 'archive ') {
                if (status === 'to_do') {
                    backlogTasks += 1;
                }

                if (status === 'doing') {
                    doingTasks += 1;
                }

                if (status === 'approval') {
                    approvalTasks += 1;
                }

                if (status === 'done') {
                    doneTasks += 1;
                }
            }

            if (priority) {
                if (status !== 'done' && status !=='archive ' && priority === 'critical') {
                    highPriority += 1;
                }

                if (status !== 'done' && status !=='archive ' && priority === 'normal') {
                    middlePriority +=1;
                }

                if (status !== 'done' && status !=='archive ' && priority === 'low') {
                    lowPriority += 1;
                }
            }
        });

        this.setState({
            tasksCategoriesData: {
                labels: [`Backlog`, "В работе", "На утверждении", "Готово"], 
                datasets: [{
                    data: [backlogTasks, doingTasks, approvalTasks, doneTasks],
                    backgroundColor: ['#338DE0', '#1D62CB', '#FCC865', '#28C397']
                }],
            },
            tasksPrioritiesData: {
                labels: ["Высокий", "Средний", "Низкий"], 
                datasets: [{
                    data: [highPriority, middlePriority, lowPriority],
                    backgroundColor: ['#FF575F', '#FCC865', '#28C397']
                }],
            }
        })

        this.handleTasksByMonths(tasks);
    }

    handleTasksByMonths = (tasksArray) => {
        const currentYear = new Date().getFullYear();

        let endedTasksByMonthsArray = [0,0,0,0,0,0,0,0,0,0,0,0];
        let finishTasksByMonthsArray;

        tasksArray.forEach((task) => {
            if (task.done_date) {
                if (task.done_date.split('-')[0] === currentYear.toString() && task.status === 'done') {
                    const month = task.done_date.split('-')[1];
                    
                    finishTasksByMonthsArray = endedTasksByMonthsArray.map((task, index) => {
                        if (index === (Number(month) - 1)) {
                            return task + 1;
                        } else {
                            return task;
                        }
                    })

                    endedTasksByMonthsArray = finishTasksByMonthsArray;

                    return true;
                }
            }
        })


        this.setState({
            tasksByMonthsData: {
                labels: ["Январь " + currentYear, "Февраль " + currentYear, 
                "Март " + currentYear, "Апрель " + currentYear, "Май " + currentYear, 
                "Июнь " + currentYear, "Июль " + currentYear, "Август " + currentYear, 
                "Сентябрь " + currentYear, "Октябрь " + currentYear, 
                "Ноябрь " + currentYear, "Декабрь " + currentYear],
                datasets: [{
                    data: endedTasksByMonthsArray,
                    borderRadius: 3,
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 280);
                        gradient.addColorStop(0, "#346FE0");
                        gradient.addColorStop(0.85, "#5CA9FF");
                        gradient.addColorStop(1, '#c3dfff');
                        return gradient;
                    }
                }]
            }
        })
    }

    handleOverdueTasks = (tasks = this.state.taskList) => {
        const todayTimestamp = new Date().setHours(0,0,0,0);
        let overDueTasks = [];

        overDueTasks = tasks.filter((task) => {
            const endTimestamp = new Date(task.end).setHours(0,0,0,0);
            
            if (todayTimestamp > endTimestamp && task.status !== 'archive ' && task.status !== 'done') {
                return true;
            } else {
                return false;
            }
        })

        this.setState({
            overdueTasksCount: overDueTasks.length,
        })
    }

    formatArrayOfIds = (arrayOfIds, overdueTasksObj) => {
        let resultArray = [];

        if (arrayOfIds.length < 6) {
            for (let i = arrayOfIds.length; i < 6; i++) {
                arrayOfIds[i] = null
            }

            resultArray = arrayOfIds;

            return resultArray;
        }

        if (arrayOfIds.length > 6) {
            arrayOfIds.sort((id1,id2) => {
                if (overdueTasksObj[id1]['count'] > overdueTasksObj[id2]['count']) {
                    return 1;
                }

                if (overdueTasksObj[id1]['count'] < overdueTasksObj[id2]['count']) {
                    return -1;
                }

                return 0;
            })

            resultArray = arrayOfIds.slice(0,5);
            return resultArray
        }

        return arrayOfIds;
    }

    getCountOfOverdueTasks = (arrayOfIds, overdueTasksObj) => {
        const countArray = [];

        arrayOfIds.forEach((id) => {
            if (id === null) {
                countArray.push(null);
                return;
            }

            countArray.push(overdueTasksObj[id]['count']);
        })

        return countArray;
    }

    getUserInfos = (arrayOfIds, overdueTasksObj) => {
        const arrayOfUserInfos = [];

        arrayOfIds.forEach((id) => {
            if (id === null) {
                arrayOfUserInfos.push(null);
                return;
            } 

            arrayOfUserInfos.push({
                id,
                info: overdueTasksObj[id]['info'],
                count: overdueTasksObj[id]['count']
            })
        })

        return arrayOfUserInfos;
    }

    getOverdueTasksFromProject = (project) => {
        const tasks = project.tasks;
        const todayTimestamp = new Date().setHours(0,0,0,0);
        let overDueTasks = [];

        overDueTasks = tasks.filter((task) => {
            const endTimestamp = new Date(task.end).setHours(0,0,0,0);
            
            if (todayTimestamp > endTimestamp && task.status !== 'archive ' && task.status !== 'done') {
                return true;
            } else {
                return false;
            }
        })

        this.setState({
            overdueTasksCount: overDueTasks.length,
        })
    }

    getUsersList = async (filter = 'all', id = null) => {
        const allMembers = this.state.allMembers;

        if (filter === 'all') {
            this.setState({
                usersList: allMembers,
            })
            return;
        }

        if (filter === 'organization') {
            const newUsersList = allMembers.filter((user) => {
                if (!user.organization_id) {
                    return false;
                }

                if (user.organization_id === id) {
                    return true;
                } else {
                    return false;
                }
            })

            this.setState({
                usersList: newUsersList,
            })
            return;
        }

        if (filter === 'project') {
            const stackArray = [];

            getProject(id).then((response) => {

                const project = response.data.data;

                const projectOwnerId = project.owner_id;
                const projectManagers = project.managers;
                const projectCurators = project.curators;
                const projectMembers = project.members;
                let ownerInfo;

                getProfileById(projectOwnerId).then((response) => {
                    ownerInfo = response.data.data;
                    stackArray.push(ownerInfo);
                    projectManagers.forEach((manager) => stackArray.push(manager))
                    projectCurators.forEach((curator) => stackArray.push(curator))
                    projectMembers.forEach((member) => stackArray.push(member))

                    console.log(`stackArray`)
                    console.log(stackArray)

                    const newUsersList = [...new Map(stackArray.map(item => [item['id'], item])).values()];
                    this.setState({
                        usersList: newUsersList,
                    })
                })
            });
        }

    }

    getAllOrganizationsList = async () => {
        if (this.state.currentRole === 'root') {
            getCompaniesList().then((response) => {
                this.setState({
                    organizationsList: response.data.data.data,
                    selectedOrganization: 'all',
                })
            })
        }
    }

    async componentDidMount() {
        await this.getInitialDashboardData();
        this.getAllProjectsInfo();
    }


    // ----- HANDLE SELECTORS (CONTROLS) -----

    handleOrganizationsSelector = (organizationId) => {
        console.log(`organizationId change: ${organizationId}`);
        this.setState({
            selectedOrganization: organizationId,
        })
        this.handleOrganizationsChange(organizationId);
        this.setState({
            selectedProject: 'all',
            selectedUser: 'all'
        })
    }

    handleProjectsSelector = (projectId) => {
        console.log(`projectId change: ${projectId}`)
        this.setState({
            selectedProject: projectId,
        })
        this.handleProjectsChange(projectId)
        this.setState({
            selectedUser: 'all'
        })
    }

    handleUsersSelector = (userId) => {
        console.log(`userId change: ${userId}`)
        this.handleUsersChange(userId);
        this.setState({
            selectedUser: userId,
        })
    }

    handleMyProjectsSwitcher = (value) => {
        this.setState({
            onlyMyProjects: value,
        })

        if (value) {
            this.setState({
                selectedProject: 'all'
            })
            this.getOnlyMyView();
        } else {
            this.setState({
                selectedOrganization: 'all',
                selectedProject: 'all',
                selectedUser: 'all'
            })
            this.getAdminView();
        }
    }

    // ----- HANDLERS CHANGES -----

    handleOrganizationsChange = (organizationId) => {
        this.updateProjectsAndTasksByOrganization(organizationId);
        this.getUsersList('organization', organizationId);
    }

    updateProjectsAndTasksByOrganization = (organizationId) => {

        this.setState({
            loading: true,
        });

        let filterForProjects;

        const filterForTasks = {
            status: [],
            project_id: null,
            user_id: null,
            from_date: null,
            to_date: null,
            organization_id: organizationId === 'all' ? null : organizationId,
        }

        if (this.state.currentRole === 'root') {
            filterForProjects = {
                organization_id: organizationId === 'all' ? null : organizationId,
            }
            getProjectsWithFilter(filterForProjects).then((response) => {
                this.setState({
                    projectsList: response.data.data,
                })
                this.handleProjects();
                
                getTaskWithFilter(filterForTasks).then((response) => {
                    console.log(`organizationId: ${organizationId}`)
                    console.log(`tasklist:`)
                    console.log(response.data.data)
                    this.setState({
                        taskList: response.data.data,
                        loading: false,
                    })
                    this.handleTasks();
                    this.handleOverdueTasks();
                    this.getTodayTasks(this.state.taskList);
                })
            })
        } else {
            getProjects().then((response) => {
                this.setState({
                    projectsList: !!response.data.data ? Object.values(response.data.data) : [],
                });
                this.handleProjects();
                
                getTaskWithFilter(filterForTasks).then((response) => {
                    this.setState({
                        taskList: response.data.data,
                        loading: false,
                    })
                    this.handleTasks();
                    this.handleOverdueTasks(this.state.projectsList);
                    this.getTodayTasks(this.state.taskList);
                })
            });
        }
    }

    handleProjectsChange = (projectId) => {
        this.updateTasksByProject(projectId);
        if (projectId !== 'all') {
            this.getUsersList('project' , projectId);
        } else {
            const filterForUsersList = this.state.selectedOrganization === 'all' ? 'all' : 'organization'
            this.getUsersList(filterForUsersList, this.state.selectedOrganization);
        }
    }

    updateTasksByProject = (projectId) => {

        if (projectId === 'all') {
            this.handleOrganizationsChange(this.state.selectedOrganization);
            this.getTodayTasks(this.state.taskList);
            return;
        }

        this.setState({
            loading: true,
        });

        getProject(projectId).then((response) => {

            const projectData = response.data.data;
            const projectTasks = response.data.data.tasks;

            this.setState({
                taskList: projectTasks,
                loading: false,
            })
            this.handleTasks();
            this.getTodayTasks(projectTasks);
            this.getOverdueTasksFromProject(projectData);
        });
    }

    handleUsersChange = (userId) => {
        if (userId === 'all') {
            if (this.state.selectedProject !== 'all') {
                this.updateTasksByProject(this.state.selectedProject);
                this.getUsersList('project' , this.state.selectedProject);
            } else {
                this.updateProjectsAndTasksByOrganization(this.state.selectedOrganization);
            }
        } else {
            this.updateTasksByUser(userId);
        }
    }

    updateTasksByUser = (userId) => {

        const tasks = this.state.taskList;

        const newTasks = tasks.filter((task) => {
            console.log(`TASK: ${task.name}`)
            if (this.isRelatableTaskOrProject(task, userId) === true) {
                return true
            } else {
                return false;
            }
        });

        this.handleTasks(newTasks);
        this.handleOverdueTasks(newTasks);
    }

    isRelatableTaskOrProject = (object, userId = null) => {
        const objectOwnerId = object.owner_id;
        const objectManagers = object.managers;
        const objectMembers = object.members;
        const objectCurators = object.curators;

        let currentUserId = userId ? userId : this.state.userInfo.id;

        if (currentUserId == objectOwnerId) {
            return true;
        }

        if (objectManagers && objectManagers.length > 0) {
            for (let i = 0; i < objectManagers.length; i++) {
                const user = objectManagers[i];
    
                if (currentUserId === user.id) {
                    return true;
                }
            }
        }

        if (objectMembers && objectMembers.length > 0) {
            for (let i = 0; i < objectMembers.length; i++) {
                const user = objectMembers[i];
    
                if (currentUserId === user.id) {
                    return true;
                }
            }
        }

        if (objectCurators && objectCurators.length > 0) {
            for (let i = 0; i < objectCurators.length; i++) {
                const user = objectCurators[i];
    
                if (currentUserId === user.id) {
                    return true;
                }
            }
        }

        return false;
    }

    // ----- GET ONLY MY PROJECTS (FOR NON ADMINS AND WITH SWITCHER ON) -----

    getOnlyMyView = () => {
        this.setState({
            loading: true,
        })

        const filterForTasks = {
            status: [],
            project_id: null,
            user_id: null,
            from_date: null,
            to_date: null,
            organization_id: null,
        }

        getProjects().then((response) => {
            const myProjects = response.data.data.filter((project) => {
                if (this.isRelatableTaskOrProject(project, this.state.userInfo.id) === true) {
                    return true;
                } else {
                    return false;
                }
            })

            this.setState({
                projectsList: myProjects,
            });
            this.handleProjects();
            
            getTaskWithFilter(filterForTasks).then((response) => {
                const myTasks = response.data.data.filter((task) => {
                    if (this.isRelatableTaskOrProject(task, this.state.userInfo.id) === true) {
                        return true;
                    } else {
                        return false;
                    }
                })

                this.setState({
                    taskList: myTasks,
                    loading: false,
                })
                this.handleTasks();
                this.handleOverdueTasks();
                this.getTodayTasks(this.state.taskList);
            })
        });
    }

    getAdminView = () => {
        this.setState({
            loading: true,
        })

        let filterForProjects;

        const filterForTasks = {
            status: [],
            project_id: null,
            user_id: null,
            from_date: null,
            to_date: null,
            organization_id: null,
        }

        if (this.state.currentRole === 'root') {
            filterForProjects = {
                organization_id: null,
            }
            getProjectsWithFilter(filterForProjects).then((response) => {
                this.setState({
                    projectsList: response.data.data,
                })
                this.handleProjects();

                
                getTaskWithFilter(filterForTasks).then((response) => {
                    this.setState({
                        taskList: response.data.data,
                        loading: false,
                    })
                    this.handleTasks();
                    this.handleOverdueTasks();
                    this.getTodayTasks(this.state.taskList);
                })
            })
        } else {
            getProjects().then((response) => {
                this.setState({
                    projectsList: !!response.data.data ? Object.values(response.data.data) : [],
                });
                this.handleProjects();

                getTaskWithFilter(filterForTasks).then((response) => {
                    this.setState({
                        taskList: response.data.data,
                        loading: false,
                    })
                    this.handleTasks();
                    this.handleOverdueTasks();
                    this.getTodayTasks(this.state.taskList);
                })
            });
        }
    }

    render() {
        return (
            <div className='panel'>
                {this.state.notAuth && <Redirect to={"/auth"}/>}
                <Row>
                    <h2 className='componentTitle'>Добро пожаловать во VIEWPROJECT!</h2>
                </Row>
                <Row className={"toolbarInner filterTasks"} style={{marginBottom: '15px', gap: "24px"}}>
                    {this.state.currentRole === 'root' && !this.state.onlyMyProjects ? (<Select
                    style={{
                        width: '250px',
                    }}
                    value={this.state.selectedOrganization}
                    onChange={(value) => {this.handleOrganizationsSelector(value)}}
                    showArrow={false}
                    className="select_arrow_style_stroke"
                    >
                        <Option value="all">Все организации</Option>
                        {this.state.organizationsList ? this.state.organizationsList.map((organization) => (<Option value={organization.id}>
                            {organization.legal_name}
                        </Option>)) : null}
                    </Select>) : null}

                    <Select
                    style={{
                        width: '250px',
                    }}
                    value={this.state.selectedProject}
                    onChange={(value) => {this.handleProjectsSelector(value)}}
                    showArrow={false}
                    className="select_arrow_style_stroke"
                    >
                        <Option value="all">Все проекты</Option>
                        {this.state.projectsList ? this.state.projectsList.map((project) => (<Option value={project.id}>
                            {project.name}
                        </Option>)) : null}
                    </Select>

                    {this.state.currentRole === 'root' || this.state.currentRole === 'admin' ? !this.state.onlyMyProjects && (<Select
                    style={{
                        width: '250px',
                    }}
                    value={this.state.selectedUser}
                    onChange={(value) => {this.handleUsersSelector(value)}}
                    showArrow={false}
                    className="select_arrow_style_stroke"
                    >
                        <Option value="all">Все пользователи</Option>
                        {this.state.usersList ? this.state.usersList.map((user) => (<Option value={user.id}>
                            {`${user.first_name} ${user.last_name}`}
                        </Option>)) : null}
                    </Select>) : null}

                    {this.state.currentRole === 'root' || this.state.currentRole === 'admin' ? (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Switch
                            onChange={(value) => this.handleMyProjectsSwitcher(value)}
                            style={{
                                marginRight: '5px'
                            }}
                            />
                            Мои проекты и задачи
                        </div>
                    ) : null}


                </Row>
                {this.state.loading ? <Row style={{display: 'flex', justifyContent: 'center', marginBottom: '30px'}}><Spin /></Row> : (
                    <Row className="dashboard__first-row">
                    <div 
                    className="dashboard__first-row__chart-holder"
                    >
                        <Bar 
                            className="bar__canvas"
                            data={(this.state.projectsData)}
                            options={projectsInfo.options}
                            plugins={[ChartDataLabels]}
                        />
                    </div>
                    <div 
                    className="dashboard__first-row__chart-holder"
                    >
                        <span className="doughnut-tasks__total doughnut__total">
                            {this.state.tasksCategoriesData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0)}
                        </span>
                        <div className="doughnut-tasks__legend-holder">
                            <p className="doughnut-tasks__legend">{this.state.tasksCategoriesData.datasets[0].data[0]}</p>
                            <p className="doughnut-tasks__legend">{this.state.tasksCategoriesData.datasets[0].data[1]}</p>
                            <p className="doughnut-tasks__legend">{this.state.tasksCategoriesData.datasets[0].data[2]}</p>
                            <p className="doughnut-tasks__legend">{this.state.tasksCategoriesData.datasets[0].data[3]}</p>
                            <div className="doughnut-tasks__overdue-tasks">
                                <p className="overdue__count">{this.state.overdueTasksCount ? this.state.overdueTasksCount : '0'}</p>
                                <p className="overdue__text">Просроченные</p>
                            </div>
                        </div>
                        <Doughnut 
                            className="doughnut-tasks__canvas"
                            data={this.state.tasksCategoriesData}
                            options={tasks.options}
                            plugins={[ChartDataLabels]}
                        />
                    </div>
                    <div 
                    className="dashboard__first-row__chart-holder"
                    >
                        <span className="doughnut-priorities__total doughnut__total">
                            {this.state.tasksPrioritiesData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0)}
                        </span>
                        <div className="doughnut-priorities__legend-holder">
                            <p className="doughnut-priorities__legend">{this.state.tasksPrioritiesData.datasets[0].data[0]}</p>
                            <p className="high-priority priority">Высокий</p>
                            <p className="doughnut-priorities__legend">{this.state.tasksPrioritiesData.datasets[0].data[1]}</p>
                            <p className="mid-priority priority">Средний</p>
                            <p className="doughnut-priorities__legend">{this.state.tasksPrioritiesData.datasets[0].data[2]}</p>
                            <p className="low-priority priority">Низкий</p>
                        </div>
                        <Doughnut 
                            className="doughnut-priorities__canvas"
                            data={this.state.tasksPrioritiesData}
                            options={tasksPriorities.options}
                            plugins={[ChartDataLabels]}
                        />
                    </div>
                    </Row>
                )}
                {this.state.loading ? null : (
                    <Row>
                        <div 
                        className="dashboard__second-row__chart-holder"
                        >
                            <Bar 
                                className="bar-tasks__canvas"
                                data={(this.state.tasksByMonthsData)}
                                options={tasksByMonths.options}
                                plugins={[ChartDataLabels]}
                            />
                        </div>
                        <div 
                        className="dashboard__second-row__chart-holder"
                        >
                            <div className="tasks-today">
                                <div className="tasks-today__header">
                                <h3 className="tasks-today__h3">Задачи на сегодня</h3>
                                <div className="tasks-today__control">
                                    <button onClick={toPrev}>
                                    <IconArrowToRight fill="#96A0B5"/>
                                    </button>
                                    <button onClick={toNext}>
                                    <IconArrowToRight fill="#96A0B5"/>
                                    </button>
                                </div>
                                </div>

                                <div className="tasks-today__slider" id="tasks-today__slider">
                                    {this.state.todayTasks.length === 0 ? (<div className="tasks-today__empty"><Empty description=''></Empty></div>) : null}
                                    {this.state.todayTasks.map((task) => {
                                        return (<div className={"tasks-today__card cardKanban"}>
                                        <div>
                                            <div
                                                onClick={() => {
                                                    this.props.history.push(`/board/${task.id}`);
                                                }}
                                            >
                                                <Row>
                                                    <Col span={24} className={"titleCardKanban"}>
                                                        {" "}
                                                        {task.name}{" "}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <div className="progress-bar">
                                                        <div
                                                            className={
                                                                "progress-bar-inner " +
                                                                (task.perform_percent < 50
                                                                    ? "small"
                                                                    : task.perform_percent < 90
                                                                        ? "medium"
                                                                        : "large")
                                                            }
                                                            style={{width: task.perform_percent + "%"}}
                                                        ></div>
                                                    </div>
                                                    <div className="progress-data">
                                                        <div className="progress-data-title">Выполнено</div>
                                                        <div className="progress-data-value">
                                                            {task.perform_percent}%
                                                        </div>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <div className="priority">
                                                        {task.priority === "critical" ? (
                                                            <div className="high-priority priority-holder">Высокий</div>
                                                        ) : null}
                                                        {task.priority === "normal" ? (
                                                            <div className="mid-priority priority-holder">Средний</div>
                                                        ) : null}
                                                        {task.priority === "low" ? (
                                                            <div className="low-priority priority-holder">Низкий</div>
                                                        ) : null}
                                                        <div className="expire">
                                                            до {moment(task.end).format("DD.MM.YYYY")}
                                                        </div>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <div className="infobar">
                                                        <div className="members">
                                                            {task.members.length > 0 &&    
                                                                task.members.slice(0,3).map((el) => (
                                                                    <Tooltip
                                                                    title={`${el.first_name}  ${el.last_name}`}
                                                                    >
                                                                    <div className={"userInTask"} key={el.id}>
                                                                        <div
                                                                            className={"avatarCanban"}
                                                                            style={{display: "flex"}}
                                                                        >
                                                                            {el.avatar ? (
                                                                                <img
                                                                                    src={BackendUrl + el.avatar}
                                                                                    alt=""
                                                                                    style={{
                                                                                        objectFit: "cover",
                                                                                        width: 28,
                                                                                        height: 28,
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <IconDefaultPeopleAvatar
                                                                                style={{
                                                                                    width: 28,
                                                                                    height: 28,
                                                                                }}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    </Tooltip>
                                                                ))}
                                                        </div>
                                                        <div className="info">
                                                            <File
                                                                style={{marginLeft: 10, marginRight: 3, fill: "#96A0B5"}}
                                                            />{" "}
                                                            {task.files.length}
                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                    </Row>
                )}
            </div>
        );
    }
}

export default Panel;

