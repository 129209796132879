import React, {Component} from "react";
import {getDictionaryList, addDictionary} from "../../../actions/mainActions";
import {Button, Col, Empty, Form, Input, Modal, Row, Table} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import moment from "moment";

class Dictionary extends Component {
    state = {
        visibleRegModal: false,
        loading: false,
        dictionaryTable: []
    }
    formRef = React.createRef();

    componentDidMount() {
        this.getDictionarys();
    }

    getDictionarys = () => {
        getDictionaryList().then(res => {
            this.setState({
                dictionaryTable: res.data.data
            })
        })
    }
    handleCancel = () => {
        this.setState({
            visibleRegModal: false
        })
    }
    showModal = () => {
        this.setState({
            visibleRegModal: true
        })
    }
    handleOk = e => {
        this.setState({
            loading: true
        })
        addDictionary(e).then(res => {
            this.getDictionarys();
            this.setState({
                loading: false,
                visibleRegModal: false
            })
        }, err => {
            this.setState({
                loading: false,
            })
        })
    }

    render() {
        const {loading, dictionaryTable} = this.state;
        const columns = [
            {
                title: "№",
                dataIndex: "id",
                key: "id"
            },
            {
                title: "Название",
                dataIndex: "name",
                key: "name"
            },
            {
                title: "Ключ",
                dataIndex: "code",
                key: "code"
            },
            {
                title: "Дата изменения",
                dataIndex: "updated_at",
                key: "updated_at",
                render: el => el ? moment(el).format('DD.MM.YYYY HH:mm') : ''
            }
        ];
        return (
            <div>
                <div style={{overflow: "auto"}}>
                    <h2 className='componentTitle float-left'>Управление справочниками</h2>
                    {this.props.role === 'root' && <Button
                        style={{marginLeft: "100px"}}
                        type="primary"
                        className={"float-left"}
                        size="large"
                        onClick={this.showModal}
                    >
                        Добавить справочник<PlusCircleOutlined/>
                    </Button>}
                </div>

                <div className="wrapPad">
                    <Table bordered className={"myProjectsCell usersTable"}
                           dataSource={dictionaryTable}
                           loading={loading}
                           columns={columns}
                           pagination={false}
                           locale={{ emptyText: <Empty description={'Нет данных'}/> }}
                        />
                </div>

                <Modal
                    title='Добавить справочник'
                    centered
                    width={'50%'}
                    footer={null}
                    visible={this.state.visibleRegModal}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                >
                    <Form
                        className="problemForm"
                        ref={this.formRef}
                        style={{marginLeft: "100px"}}
                        name="basic"
                        onFinish={this.handleOk}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Row gutter={12} style={{marginBottom: "25px"}}>
                            <Col span={18}>
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Введите справочника"
                                        }
                                    ]}>
                                    <Input
                                        size="large"
                                        placeholder="Введите справочника"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={8}>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        style={{width: "100%"}}
                                        loading={loading}
                                    >
                                        Добавить <PlusCircleOutlined/>
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item>
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.formRef.current.resetFields();
                                            this.handleCancel();
                                        }}

                                        type="link"
                                        size="large"
                                    >
                                        ОТМЕНА
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default Dictionary;