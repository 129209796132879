import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Switch,
  Upload,
  Spin,
  Popconfirm,
  Empty
} from "antd";
import { PlusCircleOutlined, DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as Close } from "../assets/images/icons/close_modal.svg";
import { getLibraryData, getMyLibraryData, createNewDocument, deleteDocument } from "../actions/mainActions";
import moment from "moment";

class Library extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleAddDocModal: false,
      loadingTable: true,
      myLibrary: true,
      libraryTable: [],
      loadingModal: false,
      pagination_total: 1
    };
    this.formRef = React.createRef();
  }

  handleCloseModal = () => {
    this.setState({
      visibleAddDocModal: false,
    });
  };
  componentDidMount() {
    getMyLibraryData().then((response) => {
      this.setState({
        libraryTable: response.data.data.docs,
        pagination_total: response.data.data.total,
        loadingTable: false,
      });
    });
  }

  userId = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    return user.id
  }
  myDocs = (page = 1) => {
    console.log(page)
    this.setState({
      loadingTable: true,
      myLibrary: true,
    });
    getMyLibraryData(page).then((response) => {
      this.setState({
        libraryTable: response.data.data.docs,
        pagination_total: response.data.data.total,
        loadingTable: false,
      });
    });
  };
  allDocs = (page = 1) => {
    console.log(page)
    this.setState({
      loadingTable: true,
      myLibrary: false,
    });
    getLibraryData(page).then((response) => {
      this.setState({
        libraryTable: response.data.data.docs,
        pagination_total: response.data.data.total,
        loadingTable: false,
      });
    });
  };

  deleteDocument = id => {
    deleteDocument(id).then(response => {
      this.state.myLibrary ? this.myDocs() : this.allDocs()
    })
  }

  onFinish = data => {
    const formData = new FormData()
    formData.set('name', data.name)
    formData.set('file', data.file.file)
    formData.set('is_publish', data.is_publish ? 1 : 0)
    createNewDocument(formData).then(response => {
      this.formRef.current.resetFields()
      this.handleCloseModal()
      this.myDocs()
    })
  }
  onFinishFailed = (e) => {
    console.log(e)
  }
  getFieldValue(name) {
    return this.formRef.current && this.formRef.current.getFieldValue(name);
  }

  setFieldsValue(name, value) {
    this.formRef.current && this.formRef.current.setFieldsValue({ [name]: value });
  }
  render() {
    const tableColumns = [
      {
        title: "№",
        dataIndex: "id",
        key: "id",
        width: "5em",
        render: (i, j, index) => (
          <span>{index+1}</span>
        )
      },
      {
        title: "Название",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Дата",
        dataIndex: "created_at",
        key: "created_at",
        render: (date) => moment(date).format("DD.MM.YYYY"),
        width: 300,
      },
      {
        title: "Операции",
        dataIndex: "src",
        key: "src",
        width: 200,
        render: (src, data) => (
          <div style={{display: 'flex', gap: '7px'}}>
            <a
              href={process.env.REACT_APP_BACK_END ? process.env.REACT_APP_BACK_END + src : 'https://api.project.codetau.com' + src}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              Скачать <DownloadOutlined style={{ marginLeft: "5px" }} />
            </a>
            {this.userId() === data.owner.id && 
              <Popconfirm
              placement='bottom'
              title="Вы действительно хотите удалить документ?"
              onConfirm={() => {
                this.deleteDocument(data.id)
              }}
              okText="Да"
              cancelText="Нет"
              >
                <span className="delete-library-document">Удалить<DeleteOutlined /></span>
              </Popconfirm>}
          </div>
        ),
      },
    ];

    return (
      <div>
        <section className="library-section" style={{ padding: "0 10px" }}>
          <Row style={{ alignItems: "center", marginBottom: "15px" }}>
            <Col span={6}>
              <h2
                className='componentTitle'
                style={{
                  fontWeight: "bold",
                  marginBottom: 0,
                  textAlign: "start",
                }}
              >
                БИБЛИОТЕКА
              </h2>
            </Col>
            <Col span={6}>
              <Button
                type="primary"
                size="large"
                onClick={() => this.setState({ visibleAddDocModal: true })}
              >
                Добавить документ
                <PlusCircleOutlined />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className={
                  this.state.myLibrary
                    ? "library-toggle-button active"
                    : "library-toggle-button"
                }
                onClick={this.myDocs}
              >
                Мои документы
              </button>
            </Col>
            <Col>
              <button
                className={
                  this.state.myLibrary
                    ? "library-toggle-button"
                    : "library-toggle-button active"
                }
                onClick={this.allDocs}
              >
                Все документы
              </button>
            </Col>
          </Row>
          <div style={{ position: "relative" }}>
            <Table
              columns={tableColumns}
              dataSource={this.state.libraryTable}
              className="library-table"
              bordered
              pagination={{
                pageSize: 20,
                total: this.state.pagination_total,
                onChange: (page) => this.state.myLibrary ? this.myDocs(page) : this.allDocs(page)
              }}
              locale={{ emptyText: <Empty description={'Нет данных'}/> }}
            ></Table>
            {this.state.loadingTable && (
              <div className="library-loading">
                <Spin spinning={true} />
              </div>
            )}
          </div>
        </section>
        <Modal
          title="Добавить документ"
          visible={this.state.visibleAddDocModal}
          onCancel={this.handleCloseModal}
          footer={null}
          width={"50%"}
          closeIcon={<Close style={{ marginTop: "20px" }} />}
          className="add-document-modal"
        >
          
          <Form name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            ref={this.formRef}
          >
            <h3>Название</h3>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Введите имя документа",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Введите название документа"
                className="my-ant-input"
                
              />
            </Form.Item>
              <h3>Документ</h3>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: '15px' }}
              >
                <Form.Item name="is_publish" style={{marginBottom: 0}}>
                    <Switch/>
                </Form.Item>
                <span>Сделать публичным</span>
              </div>
            <Form.Item
              name="file"
              rules={[
                {
                  required: true,
                  message: "Загрузите документ",
                },
              ]}
            >
              <Upload
                maxCount={1}
                beforeUpload={() => false}
              >
                <Button className={"pl-0"} size="large" type={"link"}>
                  + Загрузить документ
                </Button>
            <p>Вы можете загрузить документы в следующих форматах : doc,docx,xlx,xls,xlsx,pdf,csv,ppt,pptx,rtf,txt,xml,odt</p>
              </Upload>
            </Form.Item>
            <Row>
              <Form.Item>
                <Button
                  loading={this.state.loading}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{ borderRadius: "8px" }}
                >
                  Опубликовать
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="text"
                  size="large"
                  style={{ borderRadius: "8px" }}
                  onClick={this.handleCloseModal}
                >
                  Отмена
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default Library;
