import React, {Component} from "react";
import {Button, message, Modal, Spin, Tabs} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import ActualProblems from "./actualProblems";
import AllProblems from "./allProblems";
import ResolvedProblems from "./resolvedProblems";
import CreateAndViewProblem from "./createAndViewProblem";
import {addProblem} from "../../actions/mainActions";
import NeedHelpProblems from "./needHelpProblems";

const {TabPane} = Tabs;

class Problems extends Component {
    state = {
        addCounter: 0,
        updater: 0,
        tab: 'all',
        problemId: null,
        visibleRegModal: false,
        loadingCreate: false
    };
    handleCancel = () => {
        this.props.parentProps.history.push('/problems')
        this.setState({
            visibleRegModal: false
        });
    };
    callback = (e) => {
        this.setState({
            tab: e
        });
    };
    showModal = () => {
        this.setState({
            visibleRegModal: true
        });
    };
    createProblem = (data) => {
        let addCounter = this.state.addCounter;
        this.setState({
            loadingCreate: true,
        });
        addProblem(data).then(res => {
            message.success("Проблема создана");
            addCounter++;
            this.setState({
                addCounter,
                visibleRegModal: false,
                loadingCreate: false
            });
        }, err => {
            this.setState({
                loadingCreate: false
            });
        });
    };

    componentDidMount() {
        this.openViewFromSearch()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {visibleRegModal} = this.state;
        const id = this.props.parentProps.match.params.id;
        if ((prevState.problemId !== id) || (prevState.visibleRegModal !== visibleRegModal)) {
            this.openViewFromSearch();
        }
    }

    openViewFromSearch = () => {
        const id = this.props.parentProps.match.params.id;
        id && this.setState({
            visibleRegModal: true,
            problemId: id
        })
    }

    render() {
        const {tab, addCounter, problemId} = this.state;
        return (
            <div className="riskComponent">
                <h2 className='componentTitle problemTitle'>Риски</h2>
                <Button
                    type="primary"
                    className="addProblemsBtn"
                    size="large"
                    onClick={() => {
                        this.setState({visibleRegModal: true, problemId: null})
                    }}
                >
                    Добавить риск<PlusCircleOutlined/>
                </Button>

                <Tabs className="problemsTabs" defaultActiveKey="1" onChange={(e) => this.callback(e)}
                      style={{marginTop: "15px"}}>
                    <TabPane tab="Все риски" key="all">
                        {tab === "all" && <AllProblems showModal={this.showModal} parentProps={this.props.parentProps}
                                                       addCounter={addCounter}/>}
                    </TabPane>
                    <TabPane tab="Нужна помощь" key="need_jelp">
                        {tab === "need_jelp" &&
                        <NeedHelpProblems showModal={this.showModal} parentProps={this.props.parentProps}
                                          addCounter={addCounter}/>}
                    </TabPane>
                    <TabPane tab="В разработке" key="in_progress">
                        {tab === "in_progress" &&
                        <ActualProblems showModal={this.showModal} parentProps={this.props.parentProps}
                                        addCounter={addCounter}/>}
                    </TabPane>
                    <TabPane tab="Закрытые" key="resolved_problems">
                        {tab === "resolved_problems" &&
                        <ResolvedProblems showModal={this.showModal} parentProps={this.props.parentProps}
                                          addCounter={addCounter}/>}
                    </TabPane>
                </Tabs>

                <Modal
                    title={"Сообщить о проблеме"}
                    centered
                    footer={null}
                    width={"60%"}
                    visible={this.state.visibleRegModal}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                >
                    {this.state.visibleRegModal &&
                    <Spin spinning={this.state.loadingCreate}>
                        <CreateAndViewProblem handleCancel={this.handleCancel} createProblem={this.createProblem}
                                              problemId={problemId}/>
                    </Spin>}
                </Modal>
            </div>);
    }
}

export default Problems;
