import React, {Component} from "react";
import {
    Button,
    Col,
    DatePicker,
    message,
    Row,
    Select,
    Spin,
    Tooltip,
} from "antd";
import "../Projects/myprojects.scss";
import moment from "moment";
import Board from "@lourenci/react-kanban";
import {PlusOutlined} from "@ant-design/icons";
import {
    addFile,
    BackendUrl,
    getContragents,
    getMembers,
    getProjects,
    changeTaskStatus,
    getTaskWithFilter,
    newOrdering,
    updateStatus,
} from "../../actions/mainActions";
import "@lourenci/react-kanban/dist/styles.css";
import {ReactComponent as File} from "../../assets/images/icons/file.svg";
import { ReactComponent as IconDefaultPeopleAvatar } from "../../assets/images/icons/default_people_avatar.svg";
import "leaflet/dist/leaflet.css";
import {NavLink} from "react-router-dom";
import "moment/locale/ru";
import {TaskView} from "./taskView";
import {TaskAddEdit} from "./taskAddEdit";
import {ReactComponent as Archive} from "../../assets/images/icons/archive.svg";
import {ReactComponent as BlueArchive} from "../../assets/images/icons/archive_blue.svg";

const {Option} = Select;

const {RangePicker} = DatePicker;

const ARCHIVE_STATUS_ID = 5

export class TaskList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentRole: null,
            projectFilterId: "all",
            statusFilter: "all",
            activeTab: "board",
            changeLog: [],
            editId: null,
            edit: false,
            center: null,
            task: {},
            taskVisible: false,
            projectsList: [],
            data: [],
            filterProps: {
                memberId: "all",
                projectId: "all",
            },
            allMembers: [],
            selectedRowKeys: [],
            avatarList: [],
            filesList: [],
            loadingList: false,
            isVisible: false,
            mapObjectsArr: [],
            initialValues: {},
            visible: false,
            project_name: "",
            project_id: null,
            id_for_subTask: null,
            task_data: {
                name: "",
                description: "",
                avatar: null,
                priority: "normal",
                type: "task",
                files: [],
                managers: [],
                members: [],
                parent_id: "1",
                dependence_id: "1",
                start: moment().format("YYYY-MM-DD, hh:mm"),
                end: moment().format("YYYY-MM-DD, hh:mm"),
            },
            board: {
                columns: [
                    {
                        id: "to_do",
                        title: "Backlog",
                        type: "to_do",
                        class: "one",
                        cards: [],
                    },
                    {
                        id: "doing",
                        title: "В работе",
                        type: "doing",
                        class: "two",
                        cards: [],
                    },
                    {
                        id: "approval",
                        title: "На утверждении",
                        class: "three",
                        type: "approval",
                        cards: [],
                    },
                    {
                        id: "done",
                        title: "Готов",
                        type: "done",
                        class: "four",
                        cards: [],
                    },
                ],
            },
            newCardTitle: "",
            newCardPerson: "",
            newCardDate: "",
            newCardManager: "",
            showMap: false,
            selectedTask: null,
            filterDate: ["day", "week", "month"],
            filterOrganization: [],
        };
    }

    filter = {
        status: [],
        project_id: null,
        user_id: null,
        from_date: null,
        to_date: null,
        organization_id: null,
    };

    showTask = () => {
        this.setState({
            taskVisible: true,
        });
    };

    renderOperateionType = (type) => {
        switch (type) {
            case "edit":
                return "Отредактировал(а)";
            case "create":
                return "Создал(а)";
            case "delete":
                return "Удалил(а)";
            default:
                return type;
        }
    };

    renderPopup = (el) => {
        return (
            <div style={{minWidth: "250px"}}>
                <Row gutter={16}>
                    {el.image.length > 0 && (
                        <Col span={8} style={{display: "flex", alignItems: "center"}}>
                            <img
                                style={{width: "100%"}}
                                src={BackendUrl + el.image[0].url}
                                alt={el.image[0].name}
                            />
                        </Col>
                    )}
                    <Col span={el.image.length > 0 ? 16 : 24}>
                        <h4>{el.name}</h4>
                        <p>{el.description}</p>
                        <p>Дата завершения: {moment(el.end_date).format("DD.MM.YYYY")}</p>
                    </Col>
                </Row>
            </div>
        );
    };

    openCardIfUrlTasiIdExist = (id) => {
        this.setState({
            selectedTask: id,
        });
        this.showTask(id);
    };

    uploadFile = ({onSuccess, onError, file}) => {
        const formData = new FormData();
        formData.append("files[]", file);
        addFile(formData).then((res) => {
            onSuccess(file);
            const filesList = this.state.filesList;
            filesList.push({
                uid: file.uid,
                name: file.name,
                status: "done",
                response: res.data.data[0],
            });
            this.setState({
                filesList: [...filesList],
            });
        });
    };

    getAllMembers = () => {
        getMembers().then((res) => {
            const allMembers = res.data.data;
            this.setState({
                allMembers,
            });
        });
    };

    getAllProjects = () => {
        const {editId} = this.props;
        getProjects().then((res) => {
            const projectsList = editId
                ? res.data.data.filter((el) => el.id !== editId)
                : res.data.data;
            this.setState({
                projectsList,
            });
        });
    };

    getAllContragents = () => {
        getContragents().then((res) => {

            const filterOrganization = res.data.data.data;

            console.log(`FILTERORGANIZATION`)
            console.log(filterOrganization)
            this.setState({
                filterOrganization,
            });
        });
    };

    getListTasks = async (memberId, projectId, date) => {

        this.setState({
            loadingList: true,
        });

        let response;

        try {
            response = await getTaskWithFilter(this.filter);
        } catch (error) {
            this.setState({
                loadingList: false,
            });
            return;
        }
        const columns = [...this.state.board.columns];
        columns.forEach((el) => (el.cards = []));

        const tasksList = response.data.data;
        tasksList.map((item) => {
            const column = columns.find((el) => el.type === item.status);
            column.cards.push({
                key: item.id,
                id: item.id,
                title: item.name,
                priority: item.priority,
                end: item.end,
                files: item.files,
                perform_percent: item.perform_percent ? item.perform_percent : "0",
                controlPerson: item.managers,
                resultPerson: item.members,
                endDate: item.end,
                status: item.status,
                project_id: item.project_id,
                toStart: false,
            });
            return true;
        });

        this.setState({
            data: tasksList,
            loadingList: false,
        });
    };

    onFinishFailed = () => {
        message.error("Проверьте введенный поля");
    };

    renderColumnHeader = (col, bag) => {
        return (
            <div className={"kanbanHeader " + col.class}>
                <span className={"colKanabTitle"}>{col.title}</span>
                <Button size={"small"} onClick={() => this.showModal(col)}>
                    <PlusOutlined/>
                </Button>
            </div>
        );
    };

    showModal = (col) => {
        this.setState({
            isVisible: true,
            selectedColumn: col,
        });
    };

    handleEdit = (task) => {
        this.setState({
            isVisible: true,
            taskVisible: false,
            edit: true,
            taskId: task.id,
        });
    };

    hideModal = () => {
        this.setState({
            isVisible: false,
            edit: false,
            taskVisible: false,
        });
    };

    hideAndRefresh = () => {
        this.getListTasks();
        this.hideModal();
        this.props.history.push("/board");
    };

    loadOn = () => {
        this.setState({
            loadingList: true,
        });
    };
    
    loadOff = () => {
        this.setState({
            loadingList: false,
        });
    };

    renderColor = (status, endDate) => {
        switch (status) {
            case "done":
                return "greenIconStroke";
            default:
                return moment(endDate).isBefore(moment(), "day")
                    ? "redIconStroke"
                    : "yellowIconStroke";
        }
    };

    renderCard = (card, bag) => {
        return (
            <div className={"cardKanban"}>
                <div>
                    <div
                        onClick={() => {
                            this.setState({
                                taskVisible: true,
                            });
                            this.props.history.push(`/board/${card.id}`);
                        }}
                    >
                        <Row>
                            <Col span={24} className={"titleCardKanban"}>
                                {" "}
                                {card.title}{" "}
                            </Col>
                        </Row>
                        <Row>
                            <div className="progress-bar">
                                <div
                                    className={
                                        "progress-bar-inner " +
                                        (card.perform_percent < 50
                                            ? "small"
                                            : card.perform_percent < 90
                                                ? "medium"
                                                : "large")
                                    }
                                    style={{width: card.perform_percent + "%"}}
                                ></div>
                            </div>
                            <div className="progress-data">
                                <div className="progress-data-title">Выполнено</div>
                                <div className="progress-data-value">
                                    {card.perform_percent}%
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="priority">
                                {card.priority === "critical" ? (
                                    <div className="high">Высокий</div>
                                ) : null}
                                {card.priority === "normal" ? (
                                    <div className="medium">Средний</div>
                                ) : null}
                                {card.priority === "low" ? (
                                    <div className="low">Низкий</div>
                                ) : null}
                                <div className="expire">
                                    до {moment(card.end).format("DD.MM.YYYY")}
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="infobar">
                                <div className="members">
                                    {card.resultPerson.length > 0 &&
                                        card.resultPerson.slice(0,3).map((el) => (
                                            <Tooltip
                                            title={`${el.first_name} ${el.last_name}`}
                                            >
                                                <div className={"userInTask"} key={el.id}>
                                                    <div
                                                        className={"avatarCanban"}
                                                        style={{display: "flex"}}
                                                    >
                                                        {el.avatar ? (
                                                            <img
                                                                src={BackendUrl + el.avatar}
                                                                alt=""
                                                                style={{
                                                                    objectFit: "cover",
                                                                    width: 28,
                                                                    height: 28,
                                                                }}
                                                            />
                                                        ) : (
                                                            <IconDefaultPeopleAvatar style={{
                                                                width: 28,
                                                                height: 28,
                                                            }} />
                                                        )}
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        ))}
                                </div>
                                <div className="info">
                                    <File
                                        style={{marginLeft: 10, marginRight: 3, fill: "#96A0B5"}}
                                    />{" "}
                                    {card.files.length}
                                </div>
                                <div>
                                    <Tooltip
                                        placement="bottomLeft"
                                        title="Добавить в архив"
                                        color="#121F3E"
                                    >
                                        <Button
                                            style={{border: "none"}}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.handleClickArchive(card);
                                            }}
                                        >
                                            <BlueArchive/>
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        );
    };

    handleCancel = (e) => {
        const projectFilterId = this.props.match.params.projectId;
        const statusFilter = this.props.match.params.status;
        const selectedTask = this.props.match.params.taskId;
        const userId = this.props.match.params.userId;
        this.props.history.push(
            selectedTask
                ? `/list/${statusFilter}/${projectFilterId}/${userId}/0`
                : "/board"
        );
        this.getListTasks();
        this.setState({
            taskVisible: false,
            taskId: null,
        });
    };

    updateStatusCheck = (c, d, r, e) => {
        r.status = d.toColumnId;
        if (c.fromColumnId !== d.toColumnId) {
            const data = {
                id: r.id,
                status: d.toColumnId,
            };
            this.loadOn();
            updateStatus(data).then(
                (res) => {
                    message.success("Статус изменен");
                    this.loadOff();
                },
                (err) => this.loadOff()
            );
            const column = e.columns.find((el) => el.id === d.toColumnId);
            const newOrder = column.cards.map((el, i) => {
                return {
                    id: el.id,
                    order: i,
                    key: el.id,
                };
            });
            this.loadOn();
            newOrdering({tasks: newOrder}).then(
                (res) => {
                    this.loadOff();
                },
                (err) => this.loadOff()
            );
            const columnold = e.columns.find((el) => el.id === c.fromColumnId);
            const newOrderOld = columnold.cards.map((el, i) => {
                return {
                    id: el.id,
                    order: i,
                    key: el.id,
                };
            });
            this.loadOn();
            newOrderOld.length > 0 &&
            newOrdering({tasks: newOrderOld}).then(
                (res) => {
                    this.loadOff();
                },
                (err) => this.loadOff()
            );
        } else {
            const column = e.columns.find((el) => el.id === d.toColumnId);
            const newOrder = column.cards.map((el, i) => {
                return {
                    id: el.id,
                    order: i,
                    key: el.id,
                };
            });
            this.loadOn();
            newOrdering({tasks: newOrder}).then(
                (res) => {
                    message.success("Порядок изменен");
                    this.loadOff();
                },
                (err) => this.loadOff()
            );
        }
    };

    componentDidMount() {
        const currUser = JSON.parse(localStorage.getItem('user'));

        const currentRole = currUser.role;
        // const currentRole = 'user';

        this.setState({
            currentRole
        })

        if (currentRole === 'root' || currentRole === 'admin') {
            console.log(`THIS USER IS ADMIN/SUPERADMIN`)
            this.changeMember(null)
            // return;
        } else {
            if (currUser && currUser.id) {
                this.changeMember(currUser.id)
            } else {
                this.changeMember(null);
            }
        }

        const taskId = this.props.match.params.id;
        taskId && this.openCardIfUrlTasiIdExist(taskId);
        const path = this.props.match.path.split("/")[1];

        this.getAllMembers();
        this.getAllProjects();
        this.getAllContragents();

        if (path === "list") {
            if (
                this.props.match.params.taskId &&
                this.props.match.params.taskId > 0
            ) {
                this.setState({
                    activeTab: path,
                    projectFilterId: this.props.match.params.projectId,
                    statusFilter: this.props.match.params.status,
                    selectedTask: this.props.match.params.taskId,
                });
                this.showTask(this.props.match.params.taskId);
            } else {
                this.setState({
                    activeTab: path,
                    projectFilterId: this.props.match.params.projectId,
                    statusFilter: this.props.match.params.status,
                });
            }
        } else {
            this.setState({
                activeTab: path,
            });
        }
    }

    setActivaTab = (tab) => {
        this.props.history.push(`/${tab === "list" ? "list/all/all/all/0" : tab}`);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const taskId = this.props.match.params.id || this.props.match.params.taskId;
        if (taskId && taskId !== "0" && taskId !== prevState.selectedTask) {
            taskId && this.openCardIfUrlTasiIdExist(taskId);
        }
        if (prevState.taskId !== this.state.taskId) {
            this.getListTasks();
        }
    }

    changeMember = (id) => {
        this.filter.user_id = id;
        this.getListTasks();
    };

    changeProject = (id) => {
        this.filter.project_id = id;
        this.getListTasks();
    };

    handleChangeOrganization = (organization_id) => {
        this.filter.organization_id = organization_id;
        this.getListTasks();
    };

    handleChangeDate = (array) => {
        if (array === null) {
            this.filter.from_date = null;
            this.filter.to_date = null;
            this.getListTasks();
        } else {
            const [from_date, to_date] = array;
            this.filter.from_date = from_date.format("YYYY-MM-DD");
            this.filter.to_date = to_date.format("YYYY-MM-DD");
            this.getListTasks();
        }
    };

    handleClickArchive = async (task) => {
        await changeTaskStatus(task.id, ARCHIVE_STATUS_ID);
        message.success("Добавлено в архив")
        this.getListTasks();
    };

    render() {
        const {allMembers, projectsList, filterOrganization} = this.state;
        // const { history, match } = this.props;

        return (
            <div>
                {/* <h2 className="componentTitle">Задачи</h2> */}
                <Spin spinning={this.state.loadingList}>
                    <Row className={"toolbarInner filterTasks"} style={{justifyContent: "space-between"}}>
                        <Row style={{gap: "24px"}}>
                            {this.state.currentRole === 'admin' || this.state.currentRole === 'root' ? (<Select
                                className="select__organizations select_arrow_style_stroke"
                                style={{
                                    float: "left",
                                    height: "47px",
                                }}
                                value={this.filter.organization_id}
                                placeholder="Контрагент"
                                onChange={this.handleChangeOrganization}
                                showArrow={false}
                            >
                                <Option value={null}>Все контрагенты</Option>
                                {filterOrganization.map((item) => (
                                    <Option value={item.id} key={item.id}>
                                        {item.legal_name}
                                    </Option>
                                ))}
                            </Select>) : null}

                            {this.state.currentRole === 'admin' || this.state.currentRole === 'root' ? (<Select
                                className="select__users select_arrow_style_stroke"
                                style={{float: "left", height: "47px"}}
                                placeholder="Пользователь"
                                value={this.filter.user_id}
                                onChange={this.changeMember}
                                showArrow={false}
                                // value={status}
                            >
                                <Option value={null}>Все пользователи</Option>
                                {allMembers.map((item) => (
                                    <Option value={item.id} key={item.id}>
                                        {item.first_name} {item.last_name}
                                    </Option>
                                ))}
                            </Select>) : null}

                            <Select
                                className="select__projects select_arrow_style_stroke"
                                style={{
                                    float: "left",
                                    height: "47px",
                                }}
                                placeholder="Проект"
                                value={this.filter.project_id}
                                onChange={this.changeProject}
                                showArrow={false}
                                // value={status}
                            >
                                <Option value={null}>Все проекты</Option>
                                {projectsList.map((item) => (
                                    <Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>

                            <RangePicker
                                className="select__date"
                                style={{
                                    float: "left",
                                    height: "47px",
                                    borderRadius: "8px",
                                }}
                                onChange={this.handleChangeDate}
                                placeholder={["Дата от", "Дата по"]}
                            />
                        </Row>
                        <NavLink
                            to="task/archive"
                            className="archive"
                        >
                            <Archive/>
                            <span style={{whiteSpace: 'nowrap'}}>Перейти в архив</span>
                        </NavLink>
                    </Row>

                    <Row gutter={16}>
                        <Board
                            renderColumnHeader={this.renderColumnHeader}
                            renderCard={this.renderCard}
                            initialBoard={this.state.board}
                            disableColumnDrag
                            onCardDragEnd={(e, r, c, d) => {
                                this.updateStatusCheck(c, d, r, e);
                            }}
                        />
                    </Row>
                </Spin>

                {/* <Spin spinning={this.state.loadingList}>
                    <Tabs defaultActiveKey="board" onChange={this.setActivaTab} activeKey={activeTab}>
                        <TabPane tab="Мои задачи" key="board">
                            <Row className={"toolbarInner"}>
                                <Col span={8}>
                                    <Search
                                        className={"innerSearch"}
                                        placeholder="Поиск по проектам"
                                        onSearch={onSearch}
                                        size="small"
                                    />
                                </Col>
                                <Col span={6} style={{ display: "flex" }}>
                                    <Button type="primary" size="large" onClick={this.showModal}>
                                        Добавить Задачу <PlusCircleOutlined />
                                    </Button>
                                </Col>
                            </Row>

                        </TabPane>
                        <TabPane tab="Список задач" key="list">
                            <TaskListsByStatus history={history} match={match} />
                        </TabPane>
                    </Tabs>
                </Spin> */}
                <TaskAddEdit
                    editId={this.state.editId}
                    hideModal={this.hideAndRefresh}
                    isVisible={this.state.isVisible}
                    onFinishFailed={this.onFinishFailed}
                />
                {this.state.taskVisible && (
                    <TaskView
                        taskId={this.state.selectedTask}
                        handleEdit={this.handleEdit}
                        deleteTask={this.deleteTask}
                        getListTasks={this.getListTasks}
                        taskVisible={this.state.taskVisible}
                        handleCancel={this.handleCancel}
                        selectedMapObject={this.state.selectedMapObject}
                        inputValue={this.state.inputValue}
                        renderPopup={this.renderPopup}
                        changeLog={this.state.changeLog}
                        center={this.state.center}
                        points={this.state.points}
                        projectName={this.state.project_name}
                        renderOperateionType={this.renderOperateionType}
                    />
                )}
            </div>
        );
    }
}
