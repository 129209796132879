export const projectsList = [
  {
    id: 1,
    pdf_link: 'Project-1.pdf',
    title: 'Курортный комплекс побережье озера Алаколь',
    destination: {
      id: 'KZ-VOS',
      title: 'Алакольское побережье, Восточно-Казахстанская область (ВКО)',
    },
    type: 'Глэмпинг',
    color: '#FF006E',
    allocatedMoney: 613600000,
    longitude: 46.138257,
    latitude: 82.034600,
    moreInfo: {
      compass: 'Алакольское побережье Восточно-Казахстанской области (ВКО) обладает уникальными природными характеристиками для развития оздоровительного и пляжного туризма и входит в топ-10 объектов карты туристификации Республики Казахстан.',
      government: 'Государство планирует продолжить активное развитие курортной зоны, инвестируя порядка 60 млн долларов США в строительство поддерживающей инфраструктуры и благоустройство, а также предоставляя налоговые льготы, льготное финансирование и полную поддержку частным инвесторам.',
      visits2: 'В настоящее время турпоток составляет приблизительно 190 тыс. посещений в год. Ожидается, что к 2030 году этот показатель вырастет до 600 тыс. посещений.',
      revenue: 'Текущий доход от туризма находится на уровне 13 млн долларов США в год. Согласно прогнозам, к 2030 году он достигнет приблизительно 130 млн долларов США, при этом средний чек на человека будет равен приблизительно 210 долларов США за посещение.',
      health: 'Ценностное предложение курортной зоны включает оздоровительное и пляжное направления и подразумевает увеличение продолжительности сезона, а также развитие круглогодичной посещаемости лечебно-оздоровительных объектов.',
      mainTerritory: 'Ключевой зоной реализации оздоровительного потенциала станет территория в непосредственной близости от источника Барлык Арасан, а основной точкой роста гостиничной инфраструктуры — новая территория побережья в п. Кабанбай.',
      wellnessFacilities: 'Ожидаемый рост турпотока к 2030 году потребует ввода дополнительных оздоровительных объектов вместимостью 700 номеров, а также отелей и домов отдыха на 5700 номеров'
    }
  },
  {
    id: 2,
    pdf_link: 'Project-2.pdf',
    title: 'Балхашская курортная зона',
    destination: {
      id: 'KZ-KAR',
      title: 'Балхаш, Карагандинская область',
    },
    type: 'Глэмпинг',
    color: '#06D6A0',
    allocatedMoney: 85000000,
    longitude: 46.829118,
    latitude: 74.969802,
    moreInfo: {
      map: 'Балхашская курортная зона расположена на территории Карагандинской области, представляет особый интерес для туристов с точки зрения пляжного отдыха в Казахстане и входит в топ-10 объектов карты туристификации Республики Казахстан.',
      visits: 'Текущий турпоток составляет 80 тыс. посещений в год. Ожидается, что к 2030 году этот показатель достигнет 330 тыс. посещений.',
      analitics: 'Согласно прогнозам, к 2030 году средний чек на посещение вырастет на 35 %, при этом более качественное и разнообразное продуктовое предложение позволит увеличить расходы клиентов на досуг и питание на 50 %.',
      placeOfRest: 'В ценностное предложение курортной зоны входит доступный пляжный отдых с разнообразными активностями для всех членов семьи.',
      construction: 'Государство планирует инвестировать более 9 млрд тенге для развития инфраструктуры на территории курортной зоны площадью 500 га. Сюда относятся проведение центрального водоснабжения, модернизация линий электропередачи, благоустройство пляжной территории.',
      newRooms: 'Развитие курортной зоны потребует введения около 1900 дополнительных номеров. Общие инвестиции на застройку нового номерного фонда составят 7,8 млрд тенге.',
      supportFromState: 'Государство готово предоставить налоговые льготы и полную поддержку частным инвесторам.',
    },
  },
  {
    id: 3,
    pdf_link: 'Project-3.pdf',
    title: 'Баянаульская курортная зона',
    destination: {
      id: 'KZ-PAV',
      title: 'Баянаул, Павлодарская область',
    },
    type: 'Глэмпинг',
    color: '#FFB423',
    allocatedMoney: 250000000,
    longitude: 50.790977,
    latitude: 75.696969,
    moreInfo: {
      map: 'Баянаул обладает уникальным природно-культурным наследием, способствующим развитию масштабного туризма, и входит в топ-10 объектов карты туристификации Казахстана.',
      visits: 'Текущий турпоток составляет 150 тыс. посещений в год. Ожидается, что к 2030 году этот показатель достигнет 460 тыс. посещений.',
      analitics: 'В настоящее время ежегодный доход от туристической отрасли составляет 2–3 млрд тенге. Согласно прогнозам, к 2030 году показатель вырастет до 16 млрд тенге (при среднем чеке на человека — прибл. 40 тыс. тенге за посещение).',
      beach: 'В ценностное предложение включены эко-культурное, приключенческоспортивное и пляжное направления, что будет способствовать развитию круглогодичного отдыха.',
      additionalRooms: 'Ожидаемый рост турпотока потребует ввода дополнительных номеров (ок. 1300 номеров к 2030 году), общие инвестиции на которые составят прибл. 7 млрд тенге, а IRR — 23 %.',
      placeOfRest: 'Ключевой точкой роста курортной зоны станут прибрежные территории озер Сабындыколь и Торайгыр.',
      supportFromState: 'Государство планирует инвестировать более 15 млрд тенге на проведение поддерживающей инфраструктуры к основным точкам притяжения, а также готово предоставить налоговые льготы и полную поддержку частным инвесторам.',
    },
  },
  {
    id: 4,
    pdf_link: 'Project-4.pdf',
    title: 'Имантау Шалкарская курортная зона',
    destination: {
      id: 'KZ-AKM',
      title: 'Имантау-Шалкар, Акмолинская область',
    },
    type: 'Глэмпинг',
    color: '#02A5C9',
    allocatedMoney: 160000000,
    longitude: 50.143292,
    latitude: 64.291715,
    moreInfo: {
      map: 'Имантау-Шалкарская курортная зона обладает уникальным природным наследием, способствующим развитию истинного экотуризма, и является одним из приоритетных объектов в топ-10 карты туристификации Республики Казахстан.',
      visits: 'На сегодняшний день турпоток составляет приблизительно 40 тыс. посещений в год. При этом существует потенциал увеличения турпотока до 280 тыс. посещений к 2030 году',
      ecoTourism: 'Ценностное предложение курортной зоны будет развиваться в направлении экотуризма. При этом основной акцент будет сделан на доступность отдыха. Имантау-Шалкарская курортная зона также будет развиваться как сателлитная дестинация более крупного курорта, находящегося в 150 км, — Щучинско-Боровской курортной зоны (ЩБКЗ).',
      keyAreas: 'Ключевые зоны развития курорта — это три озера: оз. Имантау (пресное озеро с красивейшим островом), где будет развиваться новый номерной фонд; оз. Шалкар (соленое озеро с богатым лесным ландшафтом), а также оз. Айыртавчик (небольшое по размерам, но наиболее теплое озеро, развивающееся как центр детского оздоровительного отдыха).',
      newKeyRooms: 'Ожидаемый рост турпотока потребует ввода дополнительных номеров (около 1400 ед. ), общий объем инвестиций в которые составит приблизительно 4,4 млрд тенге.',
      supportFromState: 'Государство планирует инвестировать более 7 млрд тенге в улучшение поддерживающей инфраструктуры и благоустройство курортной зоны, а также готово предоставить полную поддержку частным инвесторам.',
    },
  },
  {
    id: 5,
    pdf_link: 'Project-5.pdf',
    title: 'Курортный комплекс Мангистау',
    destination: {
      id: 'KZ-MAN',
      title: 'Мангистау, Мангистауская область',
    },
    type: 'Глэмпинг',
    color: '#002B6F',
    allocatedMoney: 2832000000,
    longitude: 43.696783,
    latitude: 51.296981,
    moreInfo: {
      map: 'Теплое море у берегов Мангистау способно привлечь туристов не только из Казахстана, но и из ближнего зарубежья, прочно закрепляя курорт в топ-10 приоритетных объектов карты туристификации Республики Казахстан.',
      visits: 'Ожидается, что к 2030 году турпоток в Мангистау вырастет более чем в шесть раз и достигнет 740 тыс. посещений.',
      beach: 'Ключевым направлением туризма в Мангистау останется пляжный отдых, аналогов которому нет в регионе. Дополнят его оздоровительные, развлекательные и экокультурные предложения, что будет способствовать развитию круглогодичного туризма.',
      analitics: 'Согласно прогнозам, благодаря росту турпотока, увеличению количества въездных туристов, а также качественно новому туристическому предложению к 2030 году доходы от туризма составят 165 млрд тенге.',
      placeOfRest: 'Теплый пляж в 50 км к югу от г. Актау (областного центра Мангистау) станет ключевой точкой роста благодаря благоприятным природноклиматическим свойствам',
      newKeyRooms: 'Ожидаемый рост турпотока потребует ввода дополнительных номеров (7 700 номеров к 2030 году), общие инвестиции на которые составят приблизительно 280 млн долларов США, а IRR — 27% (в среднем для совокупных инвестиций).',
      supportFromState: 'Государство планирует инвестировать более 100 млн долларов США в подведение поддерживающей инфраструктуры к основным точкам притяжения, а также готово предоставить налоговые льготы и полную поддержку частным инвесторам.',
    },
  },
  {
    id: 6,
    pdf_link: 'Project-6.pdf',
    title: 'Историко-культурный комплекс Туркестан',
    destination: {
      id: 'KZ-TUR',
      title: 'Туркестанская область',
    },
    type: 'Глэмпинг',
    color: '#FF006E',
    allocatedMoney: 1888000000,
    longitude: 43.171867,
    latitude: 68.273176,
    moreInfo: {
      status: 'Благодаря новому статусу областного центра Туркестан стал одним из приоритетных направлений дальнейшего развития страны.',
      spirituality: 'Туркестан — это духовный центр тюркских народов, в который для совершения «малого хаджа» ежегодно приезжают туристы из Казахстана и тюркских стран.',
      history: 'Богатейшая история, насчитывающая 1500 лет, и множество исторических объектов открывают для Туркестана перспективу стать центром притяжения для культурного туризма в Центральной Азии.',
      duraitionOfStay: 'Совершенствование продуктового предложения позволит продлить длительность пребывания туристов с 10 часов до 2 дней.',
      touristGrowth: 'Рост турпотока до 1 млн к 2030г. и увеличение длительности пребывания сформируют спрос на дополнительные номера (прибл. 1400 ед.), что открывает уникальную возможность выйти на рынок с быстрорастущим спросом на 3–5-зведочные отели.',
      profitFromProject: 'Типовой проект 4-звездочного отеля на 100 номеров обеспечит инвестору IRR на уровне 20-22 % и принесет NPV в размере 600-800 тыс. долларов США',
      newEcoZone: 'В целях привлечения инвестиций государство планирует создать свободную экономическую зону «TURKISTAN», а также инвестировать значительные средства в развитие инфраструктуры города.'
    },
  },
  {
    id: 7,
    pdf_link: 'Project-7.pdf',
    title: 'Курортный комплекс побережье озера Щучье',
    destination: {
      id: 'KZ-SEV',
      title: 'Озеро Щучье, Северо-Казахстанская обл (СКО)',
    },
    type: 'Глэмпинг',
    color: '#06D6A0',
    allocatedMoney: 2879000000,
    longitude: 52.978577,
    latitude: 70.205357,
    moreInfo: {
      map: 'Щучинско-Боровская курортная зона (ЩБКЗ) – одно из самых уникальных мест в Центральной Азии, обладающее богатым природно-культурным наследием, способствующим развитию масштабного экотуризма.',
      visits: 'Казахстан – страна с уверенным экономическим ростом и привлекательным климатом для инвесторов. Более того, Правительство РК обозначило туризм одной из приоритетных отраслей и определило ТОП-10 туристских дестинаций, одной из которых является ЩБКЗ.',
      analitics: 'В 2030 г. турпоток в ЩБКЗ увеличится в 2,5 раза с ~700 тыс. до 1,6 млн посещений в год, преимущественно из Казахстана и России.',
      ecoTourism: 'Ценностное предложение курортной зоны направлено на обеспечение круглогодичной посещаемости и включает экотуризм (основное направление), пляжное, спортивное и оздоровительное направления.',
      keyAreas: 'Ключевой зоной является территория южного побережья озера Щучье площадью 40 га для развития премиального отдыха и строительства 5–6 отелей с общим номерным фондом 1 000–1 200 номеров.',
      newKeyRooms: 'Ожидаемый рост турпотока потребует ввода дополнительных номеров за счет частных инвестиций – всего около 4 100 к 2030 году, из которых 1 000–1 100 будут номера премиум-класса на оз. Щучье. Предварительная общая стоимость типового проекта строительства 4-звездочного отеля составляет 6,1 млн долл. США со средней окупаемостью 6–8 лет.',
      supportFromState: 'Государство планирует инвестировать более 116 млн долл. США в улучшение поддерживающей инфраструктуры и облагораживание курортной зоны, а также готово предоставить налоговые льготы и полную поддержку частным инвесторам.',
    },
  },
  {
    id: 11,
    title: '«Строительство новой курортной зоны «AQBURA resort» (2 - этап)',
    destination: {
      id: 'KZ-AKM',
      title: 'Бурабайский район, территория между озером Большое Чебачье и озером Текеколь.',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 80000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2023,
        end: 2030,
      },
      placeInfo: {
        region: 'Бурабайский район',
        city: 'Территория между озером Большое Чебачье и озером Текеколь',
        rooms: 750,
        enoughBeds: 1500
      },
    }
  },
  {
    id: 12,
    title: 'Строительство культурно-оздоровительного комплекса  "Akkol resort"',
    destination: {
      id: 'KZ-AKM',
      title: 'Аккольский район, Аккольский лесхоз',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 11900000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2025,
      },
      placeInfo: {
        region: 'Аккольский район',
        city: 'Аккольский лесхоз',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 13,
    title: 'Строительство спортивно-оздоровительного комплекса «Baidala»',
    destination: {
      id: 'KZ-AKM',
      title: 'Аккольский район,  Аккольский лесхоз',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 450000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2025,
      },
      placeInfo: {
        region: 'Аккольский район',
        city: 'Аккольский лесхоз',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 14,
    title: 'Строительство туристической базы отдыха на озере Большая Сарыоба в Волгодоновском сельском округе',
    destination: {
      id: 'KZ-AKM',
      title: 'Аршалынский район, Волгодоновский сельский округ, с.Волгодоновка',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 710000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2025,
      },
      placeInfo: {
        region: 'Аршалынский район',
        city: 'Волгодоновский сельский округ, с.Волгодоновка',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 15,
    title: '«Строительство туристической базы отдыха»',
    destination: {
      id: 'KZ-AKM',
      title: 'г. Кокшетау',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 1200000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2023,
      },
      placeInfo: {
        region: ' - ',
        city: 'г. Кокшетау',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 16,
    title: '5 звездочный Гостиничный комплекс "Hilton" ',
    destination: {
      id: 'KZ-AKT',
      title: 'Актюбинская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 1800000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2024,
      },
      placeInfo: {
        region: 'Актюбинская область',
        city: ' - ',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 17,
    title: 'Кемпинг',
    destination: {
      id: 'KZ-AKT',
      title: 'Байганинский район',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 200000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Байганинский район',
        city: ' - ',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 18,
    title: 'Гостиница 4*',
    destination: {
      id: 'KZ-AKT',
      title: 'г. Актюбинск',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 4200000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2019,
        end: 2025,
      },
      placeInfo: {
        region: ' - ',
        city: 'г. Актюбинск',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 19,
    title: 'База отдыха «QARGALY ECO RESORT»',
    destination: {
      id: 'KZ-KAR',
      title: 'Каргалинский район',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 4000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2025,
      },
      placeInfo: {
        region: 'Каргалинский район',
        city: '',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 20,
    title: 'Гостиничный комплекс',
    destination: {
      id: 'KZ-AKT',
      title: 'г.Актобе',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 3900000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2025,
      },
      placeInfo: {
        region: ' - ',
        city: 'г.Актобе',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 21,
    title: 'Строительство горнолыжного курорта "Есик"',
    destination: {
      id: 'KZ-ALM',
      title: 'Алматинская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 120000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2025,
      },
      placeInfo: {
        region: 'Алматинская область',
        city: ' - ',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 22,
    title: 'Капчагай Глэмпинг Playa',
    destination: {
      id: 'KZ-ALM',
      title: 'Алматинская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 1200000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2019,
        end: 2025,
      },
      placeInfo: {
        region: 'Алматинская область',
        city: ' - ',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 23,
    title: '«Golden beach Kapshagai»',
    destination: {
      id: 'KZ-ALM',
      title: 'Алматинская область, г. Конаев',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 4000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Алматинская область',
        city: 'г. Конаев',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 24,
    title: 'Makao Luxury Village',
    destination: {
      id: 'KZ-ALM',
      title: 'Алматинская область, г. Конаев',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 5000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2023,
      },
      placeInfo: {
        region: 'Алматинская область',
        city: 'г. Конаев',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 25,
    title: 'Зона отдыха «AII Seasons»',
    destination: {
      id: 'KZ-ALM',
      title: 'Алматинская область, Уйгурский район',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 420000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Алматинская область',
        city: 'Уйгурский район',
        rooms: 52,
        enoughBeds: 144
      },
    }
  },
  {
    id: 26,
    title: 'Горнолыжный курорт "Tekesu"',
    destination: {
      id: 'KZ-ZHT',
      title: 'область Жетісу, Ескельдинский район',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 65000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2024,
        end: 2030,
      },
      placeInfo: {
        region: 'область Жетісу',
        city: 'Ескельдинский район',
        rooms: ' - ',
        enoughBeds: 300
      },
    }
  },
  {
    id: 27,
    title: 'Спортивно-туристический комплекс "California"',
    destination: {
      id: 'KZ-ZHT',
      title: 'область Жетісу,  г.Текели',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 420000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2023,
      },
      placeInfo: {
        region: 'область Жетісу',
        city: 'г.Текели',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 28,
    title: 'Туристический комплекс «Этноауыл»',
    destination: {
      id: 'KZ-ZHT',
      title: 'область Жетісу, г.Текели',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 456000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'область Жетісу',
        city: 'г.Текели',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 29,
    title: 'Гостевой дом',
    destination: {
      id: 'KZ-ZHT',
      title: 'область Жетісу',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 76000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2019,
        end: 2024,
      },
      placeInfo: {
        region: 'область Жетісу',
        city: ' - ',
        rooms: 8,
        enoughBeds: 20
      },
    }
  },
  {
    id: 30,
    title: 'Комплекс для загородного отдыха «Irbis»',
    destination: {
      id: 'KZ-ZHT',
      title: 'область Жетісу',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 2100000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2019,
        end: 2025,
      },
      placeInfo: {
        region: 'область Жетісу',
        city: ' - ',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 31,
    title: 'Коттеджный городок "Alakol Apartments"',
    destination: {
      id: 'KZ-ZHT',
      title: 'область Жетісу',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 10000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2019,
        end: 2025,
      },
      placeInfo: {
        region: 'область Жетісу',
        city: ' - ',
        rooms: 1500,
        enoughBeds: 3900
      },
    }
  },
  {
    id: 32,
    title: 'Строительство семейного парка «Keremet Park»',
    destination: {
      id: 'KZ-ATY',
      title: 'Атырауская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 3000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Атырауская область',
        city: ' - ',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 33,
    title: 'Строительство базы отдыха "Эльдорадо"',
    destination: {
      id: 'KZ-ATY',
      title: 'Атырауская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 570000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2024,
      },
      placeInfo: {
        region: 'Атырауская область',
        city: ' - ',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 34,
    title: 'Строительство гостиничного комплекса в г.Усть-Каменогорск «Диамант»',
    destination: {
      id: 'KZ-VOS',
      title: 'Восточно-Казахстанская область, г.Усть-Каменогорск',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 1200000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2023,
      },
      placeInfo: {
        region: 'Восточно-Казахстанская область',
        city: 'г.Усть-Каменогорск',
        rooms: 35,
        enoughBeds: 76
      },
    }
  },
  {
    id: 35,
    title: 'Открытие придорожной гостиницы со стоянкой для большегрузов в Курчумском районе',
    destination: {
      id: 'KZ-VOS',
      title: 'Восточно-Казахстанская область, Курчумский район',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 25000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2019,
        end: 2023,
      },
      placeInfo: {
        region: 'Восточно-Казахстанская область',
        city: 'Курчумский район',
        rooms: 15,
        enoughBeds: 30
      },
    }
  },
  {
    id: 36,
    title: 'Строительство гостиничного комплекса 4 этажа',
    destination: {
      id: 'KZ-VOS',
      title: 'Восточно-Казахстанская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 650000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2024,
      },
      placeInfo: {
        region: 'Восточно-Казахстанская область',
        city: ' - ',
        rooms: 10,
        enoughBeds: 36
      },
    }
  },
  {
    id: 37,
    title: 'Гостиничный комплекс "Керуен-Плаза" побережье озера Алаколь',
    destination: {
      id: 'KZ-VOS',
      title: 'Восточно-Казахстанская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 1500000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2019,
        end: 2023,
      },
      placeInfo: {
        region: 'Восточно-Казахстанская область',
        city: ' - ',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 38,
    title: 'Строительство базы отдыха «Шале ла бале»',
    destination: {
      id: 'KZ-VOS',
      title: 'Восточно-Казахстанская область, район Алтай',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 904000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2019,
        end: 2023,
      },
      placeInfo: {
        region: 'Восточно-Казахстанская область',
        city: 'район Алтай',
        rooms: 50,
        enoughBeds: 150
      },
    }
  },
  {
    id: 39,
    title: 'Строительство гостиничного комплекса',
    destination: {
      id: 'KZ-VOS',
      title: 'Восточно-Казахстанская область, г.Риддер',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 200000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Восточно-Казахстанская область',
        city: 'г.Риддер',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 40,
    title: 'Этнотуристская зона отдыха «Коксай»',
    destination: {
      id: 'KZ-ZHA',
      title: 'Жамбылская область, Жуалынский район, а.о.Кызыларык',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 4500000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2024,
      },
      placeInfo: {
        region: 'Жамбылская область',
        city: 'Жуалынский район, а.о.Кызыларык',
        rooms: 17,
        enoughBeds: 34
      },
    }
  },
  {
    id: 41,
    title: '"Акжол" этнотуристский комплекс',
    destination: {
      id: 'KZ-ZHA',
      title: 'Жамбылская область, Жамбыл',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 330000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2023,
      },
      placeInfo: {
        region: 'Жамбылская область',
        city: 'Жамбыл',
        rooms: 9,
        enoughBeds: 18
      },
    }
  },
  {
    id: 42,
    title: 'Зона отдыха',
    destination: {
      id: 'KZ-ZHA',
      title: 'Жамбылская область, Гродиково село',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 550000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2023,
      },
      placeInfo: {
        region: 'Жамбылская область',
        city: 'Гродиково село',
        rooms: 10,
        enoughBeds: 15
      },
    }
  },
  {
    id: 43,
    title: '"Тасколь" зона отдыха',
    destination: {
      id: 'KZ-ZHA',
      title: 'Жамбылская область, Жамбыл',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 250000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Жамбылская область',
        city: 'Жамбыл',
        rooms: 10,
        enoughBeds: 20
      },
    }
  },
  {
    id: 44,
    title: 'Гостиница "Фортис"',
    destination: {
      id: 'KZ-ZHA',
      title: 'Жамбылская область, Гродиково село',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 550000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2005,
        end: 2023,
      },
      placeInfo: {
        region: 'Жамбылская область',
        city: 'Гродиково село',
        rooms: 8,
        enoughBeds: 16
      },
    }
  },
  {
    id: 45,
    title: 'Строительство горнолыжной базы «Каралма (в горах Таласского Алатау Западного Тянь-Шаня, на высоте 3200 метров над уровнем моря, в 70 км от города Тараз.), Жуалы ауданы, Т.Дүйсебай ауылы',
    destination: {
      id: 'KZ-ZHA',
      title: 'Жамбылская область, Жуалынский район',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 6300000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2028,
        end: 2025,
      },
      placeInfo: {
        region: 'Жамбылская область',
        city: 'Жуалынский район',
        rooms: 40,
        enoughBeds: 80
      },
    }
  },
  {
    id: 46,
    title: 'Строительство парка отдыха и водных развлечений "Green Park" 2 этап',
    destination: {
      id: 'KZ-ZAP',
      title: 'ЗКО, Бәйтерек',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 1000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2023,
      },
      placeInfo: {
        region: 'ЗКО',
        city: 'Бәйтерек',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 47,
    title: 'Строительство гостиницы в г.Уральск',
    destination: {
      id: 'KZ-ZAP',
      title: 'ЗКО, Уральск',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 2700000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2023,
      },
      placeInfo: {
        region: 'ЗКО',
        city: 'Уральск',
        rooms: 32,
        enoughBeds: 64
      },
    }
  },
  {
    id: 48,
    title: 'Строительство гостиницы в г.Аксай',
    destination: {
      id: 'KZ-ZAP',
      title: 'ЗКО, Бурлинский',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 3500000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2018,
        end: 2023,
      },
      placeInfo: {
        region: 'ЗКО',
        city: 'Бурлинский',
        rooms: 108,
        enoughBeds: 216
      },
    }
  },
  {
    id: 49,
    title: 'Строительство базы отдыха "Акжол"',
    destination: {
      id: 'KZ-ZAP',
      title: 'ЗКО, Теректинский',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 1000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'ЗКО',
        city: 'Теректинский',
        rooms: 25,
        enoughBeds: 50
      },
    }
  },
  {
    id: 50,
    title: 'Строительство 4* гостиницы в г.Уральск ',
    destination: {
      id: 'KZ-ZAP',
      title: 'ЗКО, Уральск',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 6900000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2023,
      },
      placeInfo: {
        region: 'ЗКО',
        city: 'Уральск',
        rooms: 100,
        enoughBeds: 200
      },
    }
  },
  {
    id: 51,
    title: 'Зона отдыха ИП «Жошканова»',
    destination: {
      id: 'KZ-KAR',
      title: 'Карагандинская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 60000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Карагандинская область',
        city: ' - ',
        rooms: 20,
        enoughBeds: 40
      },
    }
  },
  {
    id: 52,
    title: 'Строительство и открытие дома отдыха "Жасыл аймак"',
    destination: {
      id: 'KZ-KAR',
      title: 'Карагандинская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 50000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2023,
      },
      placeInfo: {
        region: 'Карагандинская область',
        city: ' - ',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 53,
    title: 'Строительство гостиницы в Каркаралинске',
    destination: {
      id: 'KZ-KAR',
      title: 'Карагандинская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 7000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2023,
      },
      placeInfo: {
        region: 'Карагандинская область',
        city: ' - ',
        rooms: 5,
        enoughBeds: 10
      },
    }
  },
  {
    id: 54,
    title: 'Придорожный сервис категорий "С"',
    destination: {
      id: 'KZ-KZY',
      title: 'Кызылординская область, Шиелинский район',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 250000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2018,
        end: 2023,
      },
      placeInfo: {
        region: 'Кызылординская область',
        city: 'Шиелинский район',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 55,
    title: 'Придорожный сервис категорий "С" ',
    destination: {
      id: 'KZ-KZY',
      title: 'Кызылординская область, г. Кызылорда',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 400000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2023,
      },
      placeInfo: {
        region: 'Кызылординская область',
        city: 'г. Кызылорда',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 56,
    title: 'Строительство гостиничного комплекса',
    destination: {
      id: 'KZ-ULT',
      title: 'Область Ұлытау',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 350000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2024,
      },
      placeInfo: {
        region: 'Область Ұлытау',
        city: ' - ',
        rooms: 50,
        enoughBeds: 100
      },
    }
  },
  {
    id: 57,
    title: 'Гостиница в селе Улытау',
    destination: {
      id: 'KZ-ULT',
      title: 'Область Ұлытау',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 310000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2024,
      },
      placeInfo: {
        region: 'Область Ұлытау',
        city: ' - ',
        rooms: 34,
        enoughBeds: 68
      },
    }
  },
  {
    id: 58,
    title: 'База отдыха «TOBYL BEACH» ',
    destination: {
      id: 'KZ-KUS',
      title: 'Костанайская область, Костанайский район',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 140000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2023,
      },
      placeInfo: {
        region: 'Костанайская область',
        city: 'Костанайский район',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 59,
    title: 'Строительство 5 домов коттеджного типа для проживания туристов',
    destination: {
      id: 'KZ-KUS',
      title: 'Костанайская область, Алтынсаринский район',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 120000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2023,
      },
      placeInfo: {
        region: 'Костанайская область',
        city: 'Алтынсаринский район',
        rooms: 15,
        enoughBeds: 20
      },
    }
  },
  {
    id: 60,
    title: 'Реконструкция бывшего здания швейного цеха под гостиничный комплекс (кафе, кофейня, СПА-салон, гостиница) ',
    destination: {
      id: 'KZ-KUS',
      title: 'Костанайская область, г. Аркалык',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 200000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2023,
      },
      placeInfo: {
        region: 'Костанайская область',
        city: 'г. Аркалык',
        rooms: 60,
        enoughBeds: 80
      },
    }
  },
  {
    id: 61,
    title: 'Строительство двухэтажного придорожного гостиничного комплекса, район Беимбета Майлина ',
    destination: {
      id: 'KZ-KUS',
      title: 'Костанайская область, район Беимбета Майлина ',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 110000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2024,
      },
      placeInfo: {
        region: 'Костанайская область',
        city: 'район Беимбета Майлина',
        rooms: 10,
        enoughBeds: 20
      },
    }
  },
  {
    id: 62,
    title: 'Многофункциональный гостинично-туристский комплекс, гольф клуб. Гольф отель с виллами',
    destination: {
      id: 'KZ-MAN',
      title: 'Мангистауская область, г. Актау, район Теплого пляжа',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 89900000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Мангистауская область',
        city: 'г. Актау, район Теплого пляжа',
        rooms: 500,
        enoughBeds: 1000
      },
    }
  },
  {
    id: 63,
    title: 'Торговые ряды "TownCenter"',
    destination: {
      id: 'KZ-MAN',
      title: 'Мангистауская область, г. Актау, район Теплого пляжа',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 34900000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Мангистауская область',
        city: 'г. Актау, район Теплого пляжа',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 64,
    title: '«Курортно-гостиничный комплекс «AQUALINA RESORT» ',
    destination: {
      id: 'KZ-MAN',
      title: 'Мангистауская область, г. Актау, район Теплого пляжа',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 20200000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Мангистауская область',
        city: 'г. Актау, район Теплого пляжа',
        rooms: 120,
        enoughBeds: 240
      },
    }
  },
  {
    id: 65,
    title: 'Оздоровительный комплекс «Нур»',
    destination: {
      id: 'KZ-MAN',
      title: 'Мангистауская область, г. Актау, район Теплого пляжа',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 9500000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2023,
      },
      placeInfo: {
        region: 'Мангистауская область',
        city: 'г. Актау, район Теплого пляжа',
        rooms: 350,
        enoughBeds: 700
      },
    }
  },
  {
    id: 66,
    title: 'Зона отдыха «Seaside Palace». ',
    destination: {
      id: 'KZ-MAN',
      title: 'Мангистауская область, г. Актау, район Теплого пляжа',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 2100000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Мангистауская область',
        city: 'г. Актау, район Теплого пляжа',
        rooms: 120,
        enoughBeds: 240
      },
    }
  },
  {
    id: 67,
    title: 'Строительство дома отдыха санаторного назначения на 30 койко-мест, оз. Сабындыколь, ',
    destination: {
      id: 'KZ-PAV',
      title: 'Павлодарская область, Баянаульский район',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 50000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Павлодарская область',
        city: 'Баянаульский район',
        rooms: 15,
        enoughBeds: 30
      },
    }
  },
  {
    id: 68,
    title: 'Строительство ресторанного комплекса с гостиницей на 35 койко-мест, на оз. Торайгыр',
    destination: {
      id: 'KZ-PAV',
      title: 'Павлодарская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 125000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Павлодарская область',
        city: ' - ',
        rooms: 10,
        enoughBeds: 35
      },
    }
  },
  {
    id: 69,
    title: 'Строительство круглогодичного санатория, лечебно-оздоровительного комплекса на 120 койко-мест, оз. Торайгыр',
    destination: {
      id: 'KZ-PAV',
      title: 'Павлодарская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 900000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Павлодарская область',
        city: ' - ',
        rooms: 60,
        enoughBeds: 120
      },
    }
  },
  {
    id: 70,
    title: 'Строительство зоны отдыха на озере Маралды',
    destination: {
      id: 'KZ-PAV',
      title: 'Павлодарская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 150000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Павлодарская область',
        city: ' - ',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 71,
    title: 'Строительство базы отдыха «Tuzzcity» на озере Тузкала',
    destination: {
      id: 'KZ-PAV',
      title: 'Павлодарская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 8000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2030,
      },
      placeInfo: {
        region: 'Павлодарская область',
        city: ' - ',
        rooms: 4,
        enoughBeds: 16
      },
    }
  },
  {
    id: 72,
    title: 'Строительство базы отдыха "Карлыгаш" "Черная лагуна"',
    destination: {
      id: 'KZ-SEV',
      title: 'Северо-Казахстанская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 150000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2023,
        end: 2023,
      },
      placeInfo: {
        region: 'Северо-Казахстанская область',
        city: ' - ',
        rooms: 10,
        enoughBeds: 20
      },
    }
  },
  {
    id: 73,
    title: 'Зона отдыха "Тихая бухта"',
    destination: {
      id: 'KZ-SEV',
      title: 'Северо-Казахстанская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 129000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2024,
      },
      placeInfo: {
        region: 'Северо-Казахстанская область',
        city: ' - ',
        rooms: 60,
        enoughBeds: 150
      },
    }
  },
  {
    id: 74,
    title: 'Зона отдыха «Қазығұрт»',
    destination: {
      id: 'KZ-SEV',
      title: 'Северо-Казахстанская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 40000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2023,
      },
      placeInfo: {
        region: 'Северо-Казахстанская область',
        city: ' - ',
        rooms: 25,
        enoughBeds: 50
      },
    }
  },
  {
    id: 75,
    title: 'Зона отдыха «AytaS»',
    destination: {
      id: 'KZ-SEV',
      title: 'Северо-Казахстанская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 50000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2023,
      },
      placeInfo: {
        region: 'Северо-Казахстанская область',
        city: ' - ',
        rooms: 25,
        enoughBeds: 60
      },
    }
  },
  {
    id: 76,
    title: 'Зона отдыха «Дзауров»',
    destination: {
      id: 'KZ-SEV',
      title: 'Северо-Казахстанская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 20000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2023,
      },
      placeInfo: {
        region: 'Северо-Казахстанская область',
        city: ' - ',
        rooms: 7,
        enoughBeds: 15
      },
    }
  },
  {
    id: 77,
    title: 'Строительство гостиницы «Medina Palace» в городе Туркестане',
    destination: {
      id: 'KZ-TUR',
      title: 'Туркестанская область, город Туркестан',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 3000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Туркестанская область',
        city: 'город Туркестан',
        rooms: 60,
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 78,
    title: 'Гостиница TOO Жибек жолы инвестмент',
    destination: {
      id: 'KZ-TUR',
      title: 'Туркестанская область, город Туркестан',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 7800000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Туркестанская область',
        city: 'город Туркестан',
        rooms: 30,
        enoughBeds: 50
      },
    }
  },
  {
    id: 79,
    title: 'торговый-развлекательный центр «Turkistan Mall» в городе Туркестане',
    destination: {
      id: 'KZ-TUR',
      title: 'Туркестанская область, город Туркестан',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 12500000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Туркестанская область',
        city: 'город Туркестан',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 80,
    title: 'Гостиница "Рандеву-Сити" ',
    destination: {
      id: 'KZ-TUR',
      title: 'Туркестанская область, город Сарыагаш',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 70000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2022,
      },
      placeInfo: {
        region: 'Туркестанская область',
        city: 'город Сарыагаш',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 81,
    title: 'Строительство зона одыха Сагыныш',
    destination: {
      id: 'KZ-TUR',
      title: 'Туркестанская область, Сайрамский район',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 100000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2022,
      },
      placeInfo: {
        region: 'Туркестанская область',
        city: 'Сайрамский район',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 82,
    title: 'Глемпинг Tau-Tas на Бутаковке',
    destination: {
      id: 'KZ-ALM',
      title: 'Алматы',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 193000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2023,
      },
      placeInfo: {
        region: 'Алматинская Область',
        city: 'Алматы',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 83,
    title: 'Глемпинг в урочище Туюк-Су',
    destination: {
      id: 'KZ-ALM',
      title: 'Алматы',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 180000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2023,
      },
      placeInfo: {
        region: 'Алматинская Область',
        city: 'Алматы',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 84,
    title: 'Гостиничный комплекс «Medeu Park Hotel»',
    destination: {
      id: 'KZ-ALM',
      title: 'Алматы',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 14000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: 'Алматинская Область',
        city: 'Алматы',
        rooms: 90,
        enoughBeds: 180
      },
    }
  },
  {
    id: 85,
    title: 'Строительство оздоровительного комплекса «Butakovka Med»',
    destination: {
      id: 'KZ-ALM',
      title: 'Алматы',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 11000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2023,
        end: 2025,
      },
      placeInfo: {
        region: 'Алматинская Область',
        city: 'Алматы',
        rooms: 70,
        enoughBeds: 140
      },
    }
  },
  {
    id: 86,
    title: 'Строительство эко-отеля на «Медеу» 4*',
    destination: {
      id: 'KZ-ALM',
      title: 'Алматы',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 2000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2023,
        end: 2024,
      },
      placeInfo: {
        region: 'Алматинская Область',
        city: 'Алматы',
        rooms: 70,
        enoughBeds: 140
      },
    }
  },
  {
    id: 87,
    title: 'Строительство Туристического квартала',
    destination: {
      id: 'KZ-AKM',
      title: 'Нур-Султан',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 1000000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2019,
        end: 2026,
      },
      placeInfo: {
        region: ' - ',
        city: 'Нур-Султан',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 88,
    title: 'Отель «The Kerra Stars Hotel 4*» ',
    destination: {
      id: 'KZ-TUR',
      title: 'Шымкент',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 700000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2023,
      },
      placeInfo: {
        region: ' - ',
        city: 'Шымкент',
        rooms: 85,
        enoughBeds: 170
      },
    }
  },
  {
    id: 89,
    title: 'Гостиница Б/Н-4 Омурзакова Ш.',
    destination: {
      id: 'KZ-TUR',
      title: 'Шымкент',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 270000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2023,
      },
      placeInfo: {
        region: ' - ',
        city: 'Шымкент',
        rooms: ' - ',
        enoughBeds: ' - '
      },
    }
  },
  {
    id: 90,
    title: 'Гостиница «Diamond Hotel»',
    destination: {
      id: 'KZ-TUR',
      title: 'Шымкент',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 600000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2023,
      },
      placeInfo: {
        region: ' - ',
        city: 'Шымкент',
        rooms: 70,
        enoughBeds: 140
      },
    }
  },
  {
    id: 91,
    title: 'Гостиница «Алтай»',
    destination: {
      id: 'KZ-TUR',
      title: 'Шымкент',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 750000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2020,
        end: 2023,
      },
      placeInfo: {
        region: ' - ',
        city: 'Шымкент',
        rooms: 60,
        enoughBeds: 120
      },
    }
  },
  {
    id: 92,
    title: 'Зона отдыха',
    destination: {
      id: 'KZ-KAR',
      title: 'Карагандинская область, с.Торангалык (г.Балхаш)',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 1000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2022,
        end: 2023,
      },
      placeInfo: {
        region: 'Карагандинская область',
        city: 'с.Торангалык (г.Балхаш)',
        rooms: 28,
        enoughBeds: 112
      },
    }
  },
  {
    id: 93,
    title: 'Зона отдыха "Танго"',
    destination: {
      id: 'KZ-KAR',
      title: 'Карагандинская область',
    },
    type: 'Кемпинг',
    color: '#FF006E',
    allocatedMoney: 1000000000,
    longitude: null,
    latitude: null,
    moreInfo: null,
    tableInfo: {
      calendar: {
        start: 2021,
        end: 2025,
      },
      placeInfo: {
        region: 'Карагандинская область',
        city: ' - ',
        rooms: 60,
        enoughBeds: 120
      },
    }
  },
]


export const getProjectData = id => {
  return projectsList.find(product => product.id === Number(id))
}