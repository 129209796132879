export const projectsFinished = [
  {
    id: 11,
    title: 'Гостиница "Байтас"',
    subtitle: 'Гостиничный комплекс в урочище голубой залив. Общая площадь гостиничного комплекса-6062,32м2, общая площать участка составляет 9,356 га. ',
    info: {
      calendar: {
        start: '01.07.2019 г.',
        end: '-'
      },
      price: 4856,
      place: {
        region: '-',
        city: '-',
        jobs: '-',
        rooms: 76,
        enoughBeds: 248,
        initiator: '-'
      }
    }
  },
  {
    id: 12,
    title: 'Отель "Hilton"',
    info: {
      calendar: {
        start: '2019 г.',
        end: '-'
      },
      price: 6100,
      place: {
        region: '-',
        city: '-',
        jobs: '-',
        rooms: 76,
        enoughBeds: 250,
        initiator: '-'
      }
    }
  },
  {
    id: 13,
    title: 'Отель "Novotel"',
    info: {
      calendar: {
        start: '2019 г.',
        end: '-'
      },
      price: 7000,
      place: {
        region: '-',
        city: '-',
        jobs: '-',
        rooms: 76,
        enoughBeds: 400,
        initiator: '-'
      }
    }
  },
  {
    id: 14,
    title: 'отель "Sheraton"',
    info: {
      calendar: {
        start: '2015 г.',
        end: '2020 г.'
      },
      price: 20000,
      place: {
        region: '-',
        city: 'город Нур-Султан',
        jobs: '-',
        rooms: 191,
        enoughBeds: 382,
        initiator: '-'
      }
    }
  },
  {
    id: 15,
    title: 'Строительство гостиничного комплекса «Rixos Water World Aktau» 1 этап',
    info: {
      calendar: {
        start: '2019 г.',
        end: '2020 г.'
      },
      price: 68000,
      place: {
        region: 'Мангистауская область',
        city: '-',
        jobs: '-',
        rooms: 500,
        enoughBeds: 1000,
        initiator: '-'
      }
    }
  },
  {
    id: 16,
    title: '«Rixos Turkistan»',
    info: {
      calendar: {
        start: '2019 г.',
        end: '2020 г.'
      },
      price: 10300,
      place: {
        region: 'Туркестанская область',
        city: '-',
        jobs: '-',
        rooms: 120,
        enoughBeds: 200,
        initiator: '-'
      }
    }
  },
  {
    id: 17,
    title: 'Hampton by Hilton',
    info: {
      calendar: {
        start: '2019 г.',
        end: '2019 г.'
      },
      price: 3895,
      place: {
        region: 'Туркестанская область',
        city: '-',
        jobs: '-',
        rooms: 120,
        enoughBeds: 200,
        initiator: '-'
      }
    }
  },
  {
    id: 18,
    title: 'Комплекс "Караван Сарай"',
    info: {
      calendar: {
        start: '2019 г.',
        end: '2021 г.'
      },
      price: 88000,
      place: {
        region: 'Туркестанская область',
        city: '-',
        jobs: '-',
        rooms: 110,
        enoughBeds: 220,
        initiator: '-'
      }
    }
  },
  {
    id: 19,
    title: 'Строительство гостиницы «Ramada by Wyndham» в городе Туркестане',
    info: {
      calendar: {
        start: '2021 г.',
        end: '2021 г.'
      },
      price: 1800,
      place: {
        region: 'Туркестанская область',
        city: '-',
        jobs: '-',
        rooms: 100,
        enoughBeds: 156,
        initiator: '-'
      }
    }
  },
  {
    id: 20,
    title: 'Строительство гостиничного комплекса «Алаколь - Love» (с бассейном, летней террасой, сауной и др.инфраструктурой)',
    info: {
      calendar: {
        start: '2020 г.',
        end: '2021 г.'
      },
      price: 837,
      place: {
        region: 'Восточно-Казахстанская область',
        city: '-',
        jobs: '-',
        rooms: 96,
        enoughBeds: 240,
        initiator: '-'
      }
    }
  },
  {
    id: 21,
    title: 'Строительство отеля Wyndham Garden',
    info: {
      calendar: {
        start: '2019 г.',
        end: '2020 г.'
      },
      price: 2000,
      place: {
        region: 'Акмолинская область',
        city: '-',
        jobs: '-',
        rooms: 100,
        enoughBeds: 204,
        initiator: '-'
      }
    }
  },
  {
    id: 22,
    title: 'Тематический парк',
    info: {
      calendar: {
        start: '2020 г.',
        end: '2021 г.'
      },
      price: 47700,
      place: {
        region: 'Мангистауская область',
        city: '-',
        jobs: '-',
        rooms: 0,
        enoughBeds: 0,
        initiator: '-'
      }
    }
  },
  {
    id: 23,
    title: 'Гостиница Holiday Inn Express',
    info: {
      calendar: {
        start: '-',
        end: '2021 г.'
      },
      price: 4300,
      place: {
        region: '-',
        city: 'Алматы',
        jobs: '-',
        rooms: 184,
        enoughBeds: 368,
        initiator: '-'
      }
    }
  },
  {
    id: 24,
    title: 'Гостиница «Swiss hotel»',
    info: {
      calendar: {
        start: '-',
        end: '2021 г.'
      },
      price: 38500,
      place: {
        region: '-',
        city: '-',
        jobs: '-',
        rooms: 222,
        enoughBeds: 444,
        initiator: '-'
      }
    }
  }
]

export const getFinishedProject = id => {
  return projectsFinished.find(product => product.id === Number(id))
}