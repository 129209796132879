import React from "react";

import { Viewer, Worker } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { useEffect } from "react";


export const ModalPDF = ({fileUrl, pageNumber, modalPageNumber, handlePageChange}) => {

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const {jumpToPage} = pageNavigationPluginInstance;

    useEffect(() => {
        jumpToPage(pageNumber)
    }, [pageNumber])

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.1.266/build/pdf.worker.min.js">
        <Viewer 
        plugins={[pageNavigationPluginInstance]}
        fileUrl={fileUrl}
        initialPage={pageNumber}
        onPageChange={handlePageChange}
        >
        </Viewer>
      </Worker>
    )
}