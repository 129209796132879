import React, { Component } from "react";
import { BackendUrl, getInvestProjectById, changeInvestProject, deleteInvestProject } from "../../actions/mainActions";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Spin, Space, Col, Row, Button, Collapse, Image, message, Empty } from "antd";
import { Map, Marker, TileLayer } from "react-leaflet";
import L from "leaflet";

const iconMarker = new L.Icon({
  iconUrl: require("../../assets/images/icons/marker.png"),
  iconSize: new L.Point(29, 37),
  className: "iconMarkerMap"
});


class InvestmentProject extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      projectData: null,
      user: JSON.parse(localStorage.getItem('user'))
    }
  }

  componentDidMount() {

    this.setState({
      loading: true,
    })

    getInvestProjectById(this.props.parentProps.match.params.project_id).then(response => {
      this.setState({
        projectData: response.data.data,
        loading: false,
      })
    })
  }

  onCoordinatorSubmitted = status => {
    this.state.user.is_coordinator === 1 &&
    changeInvestProject(this.state.projectData?.id, {status: status}).then(response => {
      message.success("Статус успешно изменен")
      this.props.parentProps.history.push('/investment/projects')
    })
  }

  onOwnerSubmitted = status => {
    this.state.user.id === this.state.projectData?.owner.id &&
    changeInvestProject(this.state.projectData?.id, {status: status}).then(response => {
      message.success("Статус успешно изменен")
      this.props.parentProps.history.push('/investment/projects')
    })
  }

  onOwnerDeleted = () => {
    this.state.user.id === this.state.projectData?.owner.id &&
    deleteInvestProject(this.state.projectData?.id).then(response => {
      message.success("Проект успешно удален")
      this.props.parentProps.history.push('/investment/projects')
    })
  }

  render() {
    const { Panel } = Collapse
    const formatToCurrnecy = number => {
      return new Intl.NumberFormat('ru-RU').format(number)
    }
    const mapCenter = this.state.projectData?.map_object ? [this.state.projectData?.map_object.coordinatesArr[0].latitude, this.state.projectData?.map_object.coordinatesArr[0].longitude] : null

    return (this.state.loading ? (<Spin />) :
      (<div className="investment-project-page">
        <Row style={{flexWrap: 'nowrap', marginBottom: '40px'}}>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <Link to='/investment/projects'>
              <Button
                style={{ height: '70px', width: "70px" }}
                type='primary'
                size='large'
                icon={<ArrowLeftOutlined />}
              />
            </Link>
          </Col>
          <Col style={{ textAlign: 'left', paddingLeft: '20px', display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <span style={{ color: '#1890FF', fontSize: '24px' }}>{this.state.projectData?.name}</span><br />
            <span style={{ color: '#8B96A5', fontSize: '20px' }}></span>
          </Col>
        </Row>
        <Row style={{marginBottom: '40px'}}>
          <h2>Заявитель: 
            {this.state.projectData?.owner === null ? ` ${this.state.projectData?.temp_user.fio}` : <Link to={'/profile/' + this.state.projectData?.owner.id} style={{color: '#338DE0'}}>{' ' + this.state.projectData?.owner.first_name + ' ' + this.state.projectData?.owner.last_name}</Link>}
            
          </h2>
        </Row>
        <Row style={{columnGap: '50px', flexWrap: 'nowrap', marginBottom: '40px'}}>
          <Col span={10}>
            <h2>О проекте</h2>
            <div className="line"></div>
            <div className="project-data">
              <span className="title">Тип проекта: <span>{this.state.projectData?.category.name}</span></span>
              <span className="title">Стоимость: <span>{formatToCurrnecy(this.state.projectData?.price)} тг</span></span>
              {/* <span className="title">Инвестиции:</span>
              <span className="title">Чистая приведённая стоимость:</span>
              <span className="title">Окупаемость:</span> */}
              <span className="title">Телефон: {this.state.projectData?.owner === null ? this.state.projectData?.temp_user.phone : null}</span>
              {this.state.projectData?.owner === null ? (<span className="title">Электронный адрес: {this.state.projectData?.temp_user.email}</span>) : null}
            </div>
          </Col>
          <Col span={10}>
            <h2>Краткое описание </h2>
            <div className="line"></div>
            <p>{this.state.projectData?.description}</p>
          </Col>
        </Row>
        <Row style={{marginBottom: '40px'}}>
          <Col span={15}>
            <Collapse>
              <Panel header="Документы к проекту">
                {
                  this.state.projectData?.files && this.state.projectData?.files.length ?
                    <div style={{display: 'flex', flexDirection: 'column', rowGap: '5px', textAlign: 'start'}}>
                      {this.state.projectData?.files.map(file =>
                        <a href={`${BackendUrl}${file.src}`} key={file.id} target="_blank" rel="noopener noreferrer">{file.name}</a>
                      )}
                    </div>
                  :
                  <Empty description={'Нет файлов'}/>
                }
              </Panel>
              <Panel header="Метод финансирования">
              <Empty description={'Нет данных'}/>
              </Panel>
              <Panel header="Обьекты на карте">
                <div style={{position: 'relative', zIndex: 1}}>
                  {
                    this.state.projectData?.map_object ?
                    <Map
                      zoomControl={true}
                      center={mapCenter} zoom={14}
                      style={{height: 500}}
                    >
                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                        {/* {points && points.map((el, index) => */}
                        <Marker position={mapCenter} icon={iconMarker}>
                        </Marker>
                        {/* } */}
                    </Map> :
                    <Empty description={'Нет данных'}/>
                  }
                </div>
              </Panel>
              <Panel header="Фото проекта">
              {
                this.state.projectData?.images && this.state.projectData?.images.length ?
                  <div style={{display: 'flex', flexWrap: 'wrap', gap: '5px', textAlign: 'start'}}>
                    {this.state.projectData?.images.map(image =>
                      <Image src={`${BackendUrl}${image.image_path}`} key={image.id} style={{width: 200}}/>
                    )}
                  </div>
                :
                <Empty description={'Нет фотографий'}/>
              }
            </Panel>
            </Collapse>
          </Col>
        </Row>
        <Row className="project-footer">
        {
          this.state.user.is_coordinator === 1 &&
          <Space size="middle">
            {
              this.state.projectData?.status !== '1' &&
                <Button type='primary' size="large" onClick={() => this.onCoordinatorSubmitted(1)}>ПРИНЯТЬ</Button>
            }
            {
              this.state.projectData?.status !== '2' &&
                <Button type='primary' size="large" danger onClick={() => this.onCoordinatorSubmitted(2)}>ОТКЛОНИТЬ</Button>
            }
            {
              this.state.projectData?.status !== '3' ? this.state.projectData?.owner === null ? null :
                <Button type='primary' size="large" className="gray-button" onClick={() => this.onCoordinatorSubmitted(3)}>НА ДОРАБОТКУ</Button> : null
            }
          </Space>
        }
        { this.state.projectData?.owner === null ? null : (
          // Эта фича кажется не работает? Что означает статус, в каких случаях он 3?
          this.state.user.id === this.state.projectData?.owner.id && Number(this.state.projectData?.status) === 3 && 
          <>
            {
              // (Number(this.state.projectData?.status) === 2 || Number(this.state.projectData?.status) === 3) && 
              <Button type='primary' size="large" onClick={() => this.onOwnerSubmitted(0)}>ПОДАТЬ ЗАЯВКУ</Button>
            }
            {/* {
              (Number(this.state.projectData?.status) === 0 || Number(this.state.projectData?.status) === 1) && 
              <Button type='primary' size="large" className="gray-button" onClick={() => this.onOwnerSubmitted(3)}>В ЧЕРНОВИК</Button>
            } */}
            <Button type='primary' size="large" danger onClick={() => this.onOwnerDeleted()}>
              УДАЛИТЬ
            </Button>
          </>) 
        }
          </Row>
      </div>
    ))
  }
}

export default InvestmentProject
