import React, { Component } from "react";
import { Circle, Map, Marker, Polygon, Polyline, Popup, TileLayer, withLeaflet } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { ReactComponent as Pointer } from "../../assets/images/icons/pointer.svg";
import { ReactComponent as Poly } from "../../assets/images/icons/poly.svg";
import { ReactComponent as Road } from "../../assets/images/icons/road.svg";
import { ReactComponent as Search } from "../../assets/images/icons/search.svg";
import { ReactComponent as Filter } from "../../assets/images/icons/filter.svg";
import { ReactComponent as Arrow } from "../../assets/images/icons/arrow.svg";
import moment from "moment";
import "antd/dist/antd.css";
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Upload } from "antd";
import { addFile, addMapObject, BackendUrl, getFile } from "../../actions/mainActions";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ReactComponent as Gas } from "../../assets/images/icons/map/gas.svg";
import { ReactComponent as Building } from "../../assets/images/icons/map/building.svg";
import { ReactComponent as Hotel } from "../../assets/images/icons/map/hotel.svg";
import { ReactComponent as Roads } from "../../assets/images/icons/map/road.svg";
import { ReactComponent as DefaultMarker } from "../../assets/images/icons/map/default.svg";
import { ReactComponent as Walker } from "../../assets/images/icons/map/walker.svg";
import { ReactComponent as Electro } from "../../assets/images/icons/map/electro.svg";
import { ReactComponent as Com } from "../../assets/images/icons/map/com.svg";
import { ReactComponent as Wc } from "../../assets/images/icons/map/wc.svg";
import { ReactComponent as Food } from "../../assets/images/icons/map/food.svg";
import { ReactComponent as Park } from "../../assets/images/icons/map/park.svg";
import { ReactComponent as Monument } from "../../assets/images/icons/map/monument.svg";
import { ReactComponent as Trees } from "../../assets/images/icons/map/trees.svg";
import { ReactLeafletSearch } from "react-leaflet-search";

const { Option } = Select;

export class ProjectsMap extends Component {
    formRef = React.createRef();
    state = {
        renderIcon: "defaultIcon",
        pointersId: [],
        filesListMarker: [],
        loading: false,
        showHelpTips: true,
        activeAddItemType: null,
        inputValue: 1,
        center: {
            lat: 51.08913298486825,
            lng: 71.41601158238157
        },
        points: [],
        newMapObject: {
            title: "",
            description: "",
            coordinates: [0, 0],
            type: ""
        }
    };

    componentDidMount() {
        const { existPoints } = this.props;
        existPoints && existPoints.length > 0 && this.setExistPoints();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { existPoints } = this.props;
        if (existPoints !== prevProps.existPoints) {
            existPoints.length > 0 && this.setExistPoints();
        }
    }

    setExistPoints = () => {
        const { existPoints } = this.props;
        const points = [...existPoints];
        points.forEach(el => {
            if (el.type === 'point') {
                const oldCoords = [...el.coordinates];
                el.coordinates = [];
                el.coordinates = oldCoords;
            }
        })
        this.setState({
            points
        })
    }

    renderIconFunc = (icon) => {
        switch (icon) {
            case "building":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/building.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "hotel":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/hotel.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "roads":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/road.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "walker":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/walker.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "gas":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/gas.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "electro":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/electro.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "com":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/com.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "wc":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/wc.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "food":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/food.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "park":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/park.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "monument":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/monument.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            case "trees":
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/trees.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });
            default:
                return new L.icon({
                    iconUrl: require("../../assets/images/icons/map/default.svg"),
                    iconSize: new L.point(37, 46),
                    className: "iconMarkerMap"
                });

        }
    };
    dragEndEvents = e => {
    };
    addMarker = (e) => {
        const { points, activeAddItemType, newMapObject } = this.state;
        const copyMapObject = { ...newMapObject };
        const lat = e.latlng.lat;
        const lng = e.latlng.lng;
        if (!!activeAddItemType) {
            switch (activeAddItemType) {
                case "point":
                    copyMapObject.coordinates = [lat, lng];
                    copyMapObject.type = activeAddItemType;
                    this.setState({ newMapObject: copyMapObject });
                    break;
                case "line":
                case "poligon":
                    const newValues = [...copyMapObject.coordinates];
                    newValues.push([lat, lng]);
                    copyMapObject.coordinates = [...newValues];
                    copyMapObject.type = activeAddItemType;
                    this.setState({ newMapObject: { ...copyMapObject } });
                    break;
                default:
                    return null;
            }
        }
        this.setState({
            points
        });
    };
    deleteLastPoint = () => {
        const { newMapObject } = this.state;
        const copyMapObject = { ...newMapObject };
        const newCoords = [...copyMapObject.coordinates];
        newCoords.pop();
        copyMapObject.coordinates = newCoords;
        this.setState({ newMapObject: { ...copyMapObject } });
    };
    onChange = value => {
        this.setState({
            inputValue: value
        });
    };
    changeTool = (e) => {
        const { newMapObject } = this.state;
        newMapObject.coordinates = [];
        if (e === this.state.activeAddItemType) {
            this.setState({
                activeAddItemType: null,
                newMapObject
            });
        } else {
            this.setState({
                activeAddItemType: e,
                newMapObject: newMapObject
            });
        }
    };
    handleOk = (e) => {
        this.setState({
            loading: true
        });
        const { newMapObject, points, filesListMarker } = this.state;
        const newPoints = [...points];
        const newPointersId = [...this.props.mapObjectsArr];
        console.log(!!newMapObject.coordinates && !!newMapObject.type)
        if (!!newMapObject.coordinates && !!newMapObject.type) {
            e.start_date = moment(e.start_date).format("YYYY-MM-DD") + " 00:00:00";
            e.end_date = moment(e.end_date).format("YYYY-MM-DD") + " 23:59:59";
            const objToSave = e;
            const markerCoords = [];
            markerCoords.push(this.state.newMapObject.coordinates);
            objToSave.coordinates = this.state.newMapObject.type === "point" ? markerCoords : this.state.newMapObject.coordinates;
            objToSave.type = this.state.newMapObject.type;
            /*проверяем фото*/
            if (filesListMarker.length > 0) {
                objToSave.image = filesListMarker[0].response.id;
            }
            debugger
            addMapObject(objToSave).then(res => {
                newMapObject.coordinates = [];
                message.success("Объект успешно создан");
                objToSave.id = res.data.data.id;
                newPointersId.push(res.data.data.id);
                newPoints.push(objToSave);
                this.setState({
                    loading: false,
                    points: newPoints,
                    pointersId: newPointersId,
                    renderIcon: "defaultIcon",
                    newMapObject
                });
                this.props.setMapObjectsArr(newPointersId);
                this.formRef.current.resetFields();
            });

        }
    };
    onFinishFailed = () => {
    };

    uploadMarkerFile = ({ onSuccess, onError, file }) => {
        const formData = new FormData();
        let url = "https://cdn.onlinewebfonts.com/svg/img_402470.png";
        formData.append("files[]", file);
        addFile(formData).then(res => {
            onSuccess(file);
            const filesListMarker = this.state.filesListMarker;
            getFile(res.data.data).then(res => {
                url = BackendUrl + res.data.data[0].src;
                filesListMarker.push({
                    uid: file.uid,
                    name: file.name,
                    status: "done",
                    url: url,
                    response: res.data.data[0]
                });
                this.setState({
                    filesListMarker: [...filesListMarker]
                });
            });
        });
    };

    formChanged = (e, a) => {
        const copyMapObject = { ...this.state.newMapObject };
        copyMapObject.name = a.name;
        copyMapObject.description = a.description;
        copyMapObject.start_date = a.start_date;
        copyMapObject.end_date = a.end_date;
        this.setState({ newMapObject: copyMapObject });
    };

    deleteMarker = (e) => {
        const { mapObjectsArr } = this.props;
        const { points } = this.state;
        const newMapObjectsArr = [...mapObjectsArr];
        const index = newMapObjectsArr.indexOf(e.id);
        newMapObjectsArr.splice(index, 1);
        const foundedItem = points.find(item => item.id === e.id);
        const index2 = points.indexOf(foundedItem);
        points.splice(index2, 1);
        this.props.setMapObjectsArr(newMapObjectsArr);
        this.setState({
            points
        })
    }

    render() {
        const { center, points, newMapObject, filesListMarker } = this.state;
        const ReactLeafletSearchComponent = withLeaflet(ReactLeafletSearch);
        return (<div>
            <Row>
                <Col span={8} className="MapToolbar">
                    <Row>
                        <Col span={24}>
                            <h4 className='toolbarHeader'>Панель инструментов</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Select
                                style={{ width: "95%" }}
                                showSearch
                                placeholder="Поиск по проекту"
                                showArrow={false}
                            >
                                {points && points.length > 0 && points.map(item =>
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                )}
                            </Select>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} className='helper'>
                            <Filter />
                            <b>Фильтр</b>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12} className={"actions"}>
                            <Button type={"link"} onClick={() => this.changeTool("point")}
                                className={this.state.activeAddItemType === "point" && "activeBtnType"}><Pointer /></Button>
                            <Button type={"link"} onClick={() => this.changeTool("line")}
                                className={this.state.activeAddItemType === "line" && "activeBtnType"}><Road /></Button>
                            <Button type={"link"} onClick={() => this.changeTool("poligon")}
                                className={this.state.activeAddItemType === "poligon" && "activeBtnType"}><Poly /></Button>
                        </Col>
                        {this.state.activeAddItemType === "point" && <Col span={18}>
                            <br />
                            Широта
                            <Input onChange={(e) => {
                                const oldMapObject = JSON.parse(JSON.stringify(this.state.newMapObject));
                                oldMapObject.coordinates[0] = Number(e.target.value);
                                oldMapObject.type = this.state.activeAddItemType
                                this.setState({ newMapObject: oldMapObject });
                            }
                            } placeholder='Широта' value={this.state.newMapObject.coordinates[0]} />
                            <br /> <br />
                            Долгота
                            <Input
                                onChange={(e) => {
                                    const oldMapObject = JSON.parse(JSON.stringify(this.state.newMapObject));
                                    oldMapObject.coordinates[1] = Number(e.target.value);
                                    oldMapObject.type = this.state.activeAddItemType
                                    this.setState({ newMapObject: oldMapObject });
                                }}
                                placeholder='Долгота' value={this.state.newMapObject.coordinates[1]} />

                        </Col>}
                        <Col span={10}>
                            {this.state.newMapObject.coordinates && this.state.newMapObject.coordinates.length > 0 &&
                                ((this.state.newMapObject.type === "line") || (this.state.newMapObject.type === "poligon")) &&
                                <Button danger onClick={() => this.deleteLastPoint()}>
                                    Удалить последнюю точку
                                </Button>
                            }
                        </Col>
                    </Row>

                    {this.state.activeAddItemType !== null && <Row>
                        <Form
                            className={"formMarkerMaps"}
                            ref={this.formRef}
                            name="basic"
                            layout="vertical"
                            initialValues={{
                                icon_pointer: "defaultIcon"
                            }
                            }
                            onValuesChange={this.formChanged}
                            onFinish={this.handleOk}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Upload
                                        listType="picture"
                                        fileList={filesListMarker}
                                        customRequest={this.uploadMarkerFile}
                                    >
                                        {filesListMarker.length >= 1 ? null :
                                            <Button className={"pl-0"} size="large"
                                                icon={<PlusCircleOutlined />}
                                                type={"link"}>
                                                Загрузить фото метки (необязательно)
                                            </Button>}
                                    </Upload>
                                </Col>


                                <Col span={24}>
                                    <Form.Item
                                        label='Иконка метки'
                                        name='icon_pointer'
                                        rules={[
                                            {
                                                required: true,
                                                message: "Выберите иконку"
                                            }
                                        ]}>
                                        <Select
                                            style={{ width: "100%" }}
                                            placeholder="Иконка метки"
                                            onChange={(e) => {
                                                this.setState({ renderIcon: e });
                                            }}
                                            defaultValue={"defaultIcon"}
                                            optionLabelProp="label"
                                            showArrow={false}
                                    >
                                            <Option value="defaultIcon" label="Стандарт">
                                                <div className="demo-option-label-item">
                                                    <span role="img" aria-label="defaultIcon">
                                                        <DefaultMarker />
                                                    </span>
                                                    <span>Стандарт</span>
                                                </div>
                                            </Option>
                                            <Option value="building" label="Здание">
                                                <div className="demo-option-label-item">
                                                    <span role="img" aria-label="Building">
                                                        <Building />
                                                    </span>
                                                    <span>Здание</span>
                                                </div>
                                            </Option>

                                            <Option value="hotel" label="Гостиница">
                                                <div className="demo-option-label-item">
                                                    <span role="img" aria-label="Hotel">
                                                        <Hotel />
                                                    </span>
                                                    <span>Гостиница</span>
                                                </div>
                                            </Option>

                                            <Option value="roads" label="Дорога">
                                                <div className="demo-option-label-item">
                                                    <span role="img" aria-label="Roads">
                                                        <Roads />
                                                    </span>
                                                    <span>Дорога</span>
                                                </div>
                                            </Option>

                                            <Option value="walker" label="Тротуар">
                                                <div className="demo-option-label-item">
                                                    <span role="img" aria-label="walker">
                                                        <Walker />
                                                    </span>
                                                    <span> Тротуар</span>
                                                </div>
                                            </Option>


                                            <Option value="gas" label="Газ">
                                                <div className="demo-option-label-item">
                                                    <span role="img" aria-label="gas">
                                                        <Gas />
                                                    </span>
                                                    <span> Газ</span>
                                                </div>
                                            </Option>


                                            <Option value="electro" label="Свет">
                                                <div className="demo-option-label-item">
                                                    <span role="img" aria-label="electro">
                                                        <Electro />
                                                    </span>
                                                    <span> Свет</span>
                                                </div>
                                            </Option>

                                            <Option value="com" label="Коммуникации">
                                                <div className="demo-option-label-item">
                                                    <span role="img" aria-label="com">
                                                        <Com />
                                                    </span>
                                                    <span> Коммуникации</span>
                                                </div>
                                            </Option>

                                            <Option value="wc" label="Туалет">
                                                <div className="demo-option-label-item">
                                                    <span role="img" aria-label="wc">
                                                        <Wc />
                                                    </span>
                                                    <span> Туалет</span>
                                                </div>
                                            </Option>


                                            <Option value="food" label="Пункты питания">
                                                <div className="demo-option-label-item">
                                                    <span role="img" aria-label="food">
                                                        <Food />
                                                    </span>
                                                    <span> Пункты питания</span>
                                                </div>
                                            </Option>


                                            <Option value="park" label="Парк">
                                                <div className="demo-option-label-item">
                                                    <span role="img" aria-label="park">
                                                        <Park />
                                                    </span>
                                                    <span> Пункты питания</span>
                                                </div>
                                            </Option>

                                            <Option value="monument" label="Монумент">
                                                <div className="demo-option-label-item">
                                                    <span role="img" aria-label="monument">
                                                        <Monument />
                                                    </span>
                                                    <span>  Монумент</span>
                                                </div>
                                            </Option>

                                            <Option value="trees" label="Посадка деревьев">
                                                <div className="demo-option-label-item">
                                                    <span role="img" aria-label="trees">
                                                        <Trees />
                                                    </span>
                                                    <span>  Посадка деревьев</span>
                                                </div>
                                            </Option>

                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}><Form.Item
                                    label='Наименование'
                                    name='name'
                                    rules={[
                                        {
                                            required: true,
                                            message: "Введите наимнование метки"
                                        }
                                    ]}>
                                    <Input placeholder='Введите имя' size='large' />
                                </Form.Item></Col>
                                <Col span={24}><Form.Item
                                    label='Описание'
                                    name='description'
                                    rules={[
                                        {
                                            required: true,
                                            message: "Введите описание метки"
                                        }
                                    ]}>
                                    <Input
                                        placeholder='Введите описание'
                                        size='large'
                                    />
                                </Form.Item></Col>
                                <Col span={12}><Form.Item
                                    label='Дата инициации объекта'
                                    name='start_date'
                                    rules={[
                                        {
                                            required: true,
                                            message: "Дата инициации объекта"
                                        }
                                    ]}>
                                    <DatePicker size='large' format="DD.MM.YYYY" />
                                </Form.Item></Col>
                                <Col span={12}><Form.Item
                                    label='Дата завершения объекта'
                                    name='end_date'
                                    rules={[
                                        {
                                            required: true,
                                            message: "Дата завершения объекта"
                                        }
                                    ]}>
                                    <DatePicker size='large' format="DD.MM.YYYY" />
                                </Form.Item></Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item>
                                        <Button type="secondary" size="large">Очистить</Button>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item>
                                        <Button onClick={(e) => {
                                            e.preventDefault();
                                            this.formRef.current.submit();
                                        }} type="primary" htmlType="submit" size="large">Сохранить</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Row>}


                    <Row>
                        <Col span={24} className={"blueText helper"}
                            onClick={() => this.setState({ showHelpTips: !this.state.showHelpTips })}>
                            <svg width="20" height="25" viewBox="0 0 20 25" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.99997 0C4.48598 0 0 4.11255 0 9.16753C0 10.7654 0.455442 12.3388 1.31711 13.7177C2.07705 14.9339 3.1384 15.9807 4.40364 16.7656V25H15.5964V16.7656C16.8616 15.9807 17.923 14.9339 18.6829 13.7177C19.5446 12.3388 20 10.7653 20 9.16753C19.9999 4.11255 15.514 0 9.99997 0V0ZM6.0015 19.1359H13.9985V20.6031H6.0015V19.1359ZM6.8017 9.90112C6.36048 9.90112 6.0015 9.57202 6.0015 9.16753C6.0015 8.76304 6.36048 8.43398 6.8017 8.43398C7.24292 8.43398 7.60186 8.76304 7.60186 9.16753C7.60186 9.57202 7.24292 9.90112 6.8017 9.90112ZM10.8002 11.366V17.6711H9.19971V11.366H10.8002ZM12.398 9.16753C12.398 8.76304 12.757 8.43398 13.1982 8.43398C13.6394 8.43398 13.9984 8.76304 13.9984 9.16753C13.9984 9.57202 13.6394 9.90112 13.1982 9.90112C12.757 9.90112 12.398 9.57202 12.398 9.16753ZM6.0015 23.5352V22.068H13.9985V23.5352H6.0015ZM17.2964 12.9896C16.5942 14.1134 15.5858 15.0651 14.3802 15.7417L13.9984 15.9561V17.6711H12.398V11.366H13.1982C14.5205 11.366 15.5963 10.3797 15.5963 9.16753C15.5963 7.95532 14.5205 6.96914 13.1982 6.96914C11.8759 6.96914 10.8002 7.95532 10.8002 9.16753C10.8002 9.42471 10.849 9.67158 10.938 9.90112H9.06192C9.15087 9.67153 9.19971 9.42471 9.19971 9.16753C9.19971 7.95532 8.12398 6.96914 6.8017 6.96914C5.47942 6.96914 4.40364 7.95532 4.40364 9.16753C4.40364 10.3797 5.47942 11.366 6.8017 11.366H7.60186V17.6711H6.00144V15.9561L5.61966 15.7417C4.41408 15.0651 3.40573 14.1134 2.70352 12.9896C1.98017 11.832 1.5978 10.5103 1.5978 9.16753C1.59786 4.92026 5.36704 1.46484 9.99997 1.46484C14.6329 1.46484 18.4021 4.92026 18.4021 9.16753C18.4021 10.5103 18.0197 11.832 17.2964 12.9896Z"
                                    fill="#338DE0" />
                            </svg>
                            Помощник
                            <Arrow className='helpToogler'
                                style={this.state.showHelpTips ? { transform: "rotate(270deg)" } : { transform: "rotate(90deg)" }} />
                        </Col></Row>

                    {this.state.showHelpTips && <Row className={"helpBlock"}>
                        <Col span={24}>
                            <div className={"helpItem"}>
                                <div className="icon-help">
                                    <Search style={{ fill: '#338DE0' }} />
                                </div>
                                <div className={"text-help"}>
                                    Если Вы хотите увидеть существующие проекты на карте воспользуйтесь “Поиск по
                                    проектом”
                                </div>
                            </div>

                            <div className={"helpItem"}>
                                <div className="icon-help">
                                    <Pointer />
                                </div>
                                <div className={"text-help"}>
                                    Если Вы хотите поставить <b>на карте обьект</b>, выберите инструмент <b>Map
                                        Pin</b> и щелкните на
                                    нужное место
                                </div>
                            </div>

                            <div className={"helpItem"}>
                                <div className="icon-help">
                                    <Road />
                                </div>
                                <div className={"text-help"}>
                                    С помощью инструмента <b>Line</b> Вы можете <b>проложить дорогу</b> от точки A
                                    до точки B
                                </div>
                            </div>

                            <div className={"helpItem"}>
                                <div className="icon-help">
                                    <Poly />
                                </div>
                                <div className={"text-help"}>
                                    С помощью инструмента <b>Polygon</b> Вы можете выделить
                                    необходимое <b>местоположение</b> обьектов
                                    и <b>создать полигон</b>
                                </div>
                            </div>
                        </Col>
                    </Row>}


                </Col>
                <Col span={16} className={"mapBlockEditor"}>
                    <Map center={center} zoom={14} className={"mapFullScreen"}
                        onClick={this.addMarker}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />

                        <ReactLeafletSearchComponent
                            customProvider={this.provider}
                            position="topleft"
                            inputPlaceholder="Поиск по карте"
                            search={[33.100745405144245, 46.48315429687501]}
                            showMarker={false}
                            zoom={12}
                            showPopup={true}
                            closeResultsOnClick={true}
                            openSearchOnLoad={false}
                            // // these searchbounds would limit results to only Turkey.
                            searchBounds={[
                                [33.100745405144245, 46.48315429687501],
                                [44.55916341529184, 24.510498046875]
                            ]}
                        />
                        {newMapObject && newMapObject.type === "point" && newMapObject.coordinates.length > 1 &&
                            <Marker position={newMapObject.coordinates}
                                icon={this.renderIconFunc(this.state.renderIcon)}>
                                <Popup>
                                    <h4>{newMapObject.name}</h4>
                                    <p>{newMapObject.description}</p>
                                </Popup>
                            </Marker>
                        }

                        {newMapObject && newMapObject.type === "line" &&
                            <Polyline positions={newMapObject.coordinates}>
                                <Popup>
                                    <h4>{newMapObject.name}</h4>
                                    <p>{newMapObject.description}</p>
                                </Popup>
                            </Polyline>
                        }

                        {newMapObject && newMapObject.type === "poligon" &&
                            <Polygon positions={newMapObject.coordinates}>
                                <Popup>
                                    <h4>{newMapObject.name}</h4>
                                    <p>{newMapObject.description}</p>
                                </Popup>
                            </Polygon>}


                        {points && points.map(el => {
                            if (el.type === "circle") {
                                return <Circle key={el.id} center={el.center}
                                    color={"red"}
                                    draggable
                                    fillColor={this.state.inputValue > el.color ? "green" : "red"}
                                    fillOpacity={0.5}
                                    radius={el.radius}
                                    >
                                    <Popup>
                                        <h4>{el.name}</h4>
                                        <p>{el.description}</p>
                                    </Popup>
                                </Circle>;
                            } else if (el.type === "line") {
                                return <Polyline key={el.id} positions={el.coordinates}
                                    color={this.state.inputValue > el.color ? "green" : "red"}>
                                    <Popup>
                                        <h4>{el.name}</h4>
                                        <p>{el.description}</p>
                                        <span className="deleteMarker" onClick={() => this.deleteMarker(el)}>Удалить метку</span>
                                    </Popup>
                                </Polyline>;
                            } else if (el.type === "poligon") {
                                return <Polygon key={el.id} positions={el.coordinates}
                                    color={this.state.inputValue > el.color ? "green" : "red"}>
                                    <Popup>
                                        <h4>{el.name}</h4>
                                        <p>{el.description}</p>
                                        <span className="deleteMarker" onClick={() => this.deleteMarker(el)}>Удалить метку</span>
                                    </Popup>
                                </Polygon>;
                            } else if (el.type === "point") {
                                return <Marker key={el.id}
                                    position={Array.isArray(el.coordinates[0]) ? el.coordinates[0] : el.coordinates}
                                    icon={this.renderIconFunc(el.icon_pointer)}>
                                    <Popup>
                                        <h4>{el.name}</h4>
                                        <p>{el.description}</p>
                                        <span className="deleteMarker" onClick={() => this.deleteMarker(el)}>Удалить метку</span>
                                    </Popup>
                                </Marker>;
                            } else {
                                return null;
                            }
                        })}
                    </Map>
                </Col>
            </Row>

        </div>
        );
    }
}

export default ProjectsMap;
