import React, {Component} from "react";
import {globalSearch} from '../actions/mainActions';
import {Link} from "react-router-dom";
import {Col, Row, Spin} from "antd";

class SearchPage extends Component {
    state = {
        type: '',
        text: '',
        results: [],
        loading: false
    }

    componentDidMount() {
        this.doSearch();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {type, text} = this.props.match.params;
        if ((prevProps.match.params.type !== type) || (prevProps.match.params.text !== text)) {
            this.doSearch();
        }
    }

    doSearch = () => {
        this.setState({
            loading: true
        })
        const {type, text} = this.props.match.params;
        globalSearch(type, text).then(res => {
            this.setState({
                results: res.data.data,
                loading: false
            })
        })
    }

    renderTitle = () => {
        const {type} = this.props.match.params;
        switch (type) {
            case 'task':
                return 'задачам';
            case 'project':
                return 'проектам'
            case 'problem':
                return 'проблемам'
            default:
                return type
        }
    }
    renderLink = (el) => {
        const {type} = this.props.match.params;
        switch (type) {
            case 'task':
                return <Link key={el.id} className="serachLinkResult" to={`/board/${el.id}`}>{el.name}</Link>;
            case 'project':
                return <Link key={el.id} className="serachLinkResult" to={`/project/${el.id}`}>{el.name}</Link>
            case 'problem':
                return <Link key={el.id} className="serachLinkResult" to={`/problems/${el.id}`}>{el.name}</Link>
            default:
                return 'Неверный тип'
        }

    }

    render() {
        const {results, loading} = this.state;
        return (
            <div>
                <h2 className="componentTitle">Результаты поиска по {this.renderTitle()}</h2>
                <Row gutter={16}>
                    <Spin spinning={loading}>
                    <Col span={24} className='resultsSearchBlock'>
                        {results.length > 0 ? results.map(el =>
                            this.renderLink(el)
                        ) : <h3>Поиск не дал результатов</h3>}
                    </Col>
                    </Spin>
                </Row>
            </div>
        )
    }
}

export default SearchPage;