import React, {Component} from "react";
import VpPagination from "../AdminPanel/VpPagination";
import {Empty, message, Popconfirm, Select, Table} from "antd";
import {ReactComponent as ErrorIcon} from "../../assets/images/icons/errorIcon.svg";
import {changeStatusProblem, deleteProblem, getProblems} from "../../actions/mainActions";
import {ReactComponent as Delete} from "../../assets/images/icons/delete.svg";
// import CreateAndViewProblem from "./createAndViewProblem";
// import {Link} from "react-router-dom";

const {Option} = Select;

class AllProblems extends Component {
    state = {
        problemId: null,
        loading: false,
        tab: 1,
        page: 1,
        total: 0,
        from: 0,
        to: 0,
        current_page: 0,
        last_page: 0,
        pageSize: 15,
        tableData: [],
        columns: [
            {
                title: "Название",
                dataIndex: "name",
                key: "name",
                onCell: record => {
                    return {
                        onClick: event => {
                            this.props.parentProps.history.push(`/problems/${record.id}`);
                            this.props.showModal();
                        }
                    }
                }
            },
            {
                title: "Кто адресовал",
                dataIndex: "from",
                key: "from",
                render: el => el.map(item => `${item.first_name} ${item.last_name}`),
                onCell: record => {
                    return {
                        onClick: event => {
                            this.props.parentProps.history.push(`/problems/${record.id}`);
                            this.props.showModal();
                        }
                    }
                }
            },
            {
                title: "Кому адресовано",
                dataIndex: "to",
                key: "to",
                render: el => el.map(item => `${item.first_name} ${item.last_name}`).join((', ')),
                onCell: record => {
                    return {
                        onClick: event => {
                            this.props.parentProps.history.push(`/problems/${record.id}`);
                            this.props.showModal();
                        }
                    }
                }
            },
            {
                title: "Краткое описание",
                dataIndex: "description",
                key: "description",
                onCell: record => {
                    return {
                        onClick: event => {
                            this.props.parentProps.history.push(`/problems/${record.id}`);
                            this.props.showModal();
                        }
                    }
                }
            },
            {
                title: <ErrorIcon/>,
                dataIndex: "status",
                key: "status",
                render: (el, row) => {
                    return <Select onChange={(val) => this.statusChange(row.id, val)} value={el}
                                   className={[el + "Select errorSelect"]}
                    >
                        <Option value="need_help">Нужна помощь</Option>
                        <Option value="resolved">Проблем нет</Option>
                        <Option value="in_progress">В разработке</Option>
                    </Select>
                }
                /*el === 'need_help' ? <NeedHelp/> : el==='resolved' ?  <Resolved/> : el==='in_progress' ? <Inprogress/> : el*/
            },
            {
                title: "",
                dataIndex: "actions",
                key: "actions",
                render: (el, row) => {
                    return <Popconfirm
                        placement="topRight"
                        title={"Подтвердить удаление?"}
                        onConfirm={() => this.deleteProblemTotal(row.id)}
                        okText="Удалить"
                        cancelText="Отмена"
                    >
                        {row.role !== "root" && <Delete className={"actionsIcons redIcon"}/>}
                    </Popconfirm>;
                }
            }
        ],
        problemsList: []
    };
    handleCancel = () => {
        this.setState({
            visibleRegModal: false
        });
    };

    statusChange = (id, val) => {
        const objToChange = {
            status: val,
            id_problem: id
        };
        changeStatusProblem(id, objToChange).then(res => {
            message.success('Статус изменен');
            this.getProblemsList();
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.state.pageSize !== prevState.pageSize) || (this.state.page !== prevState.page) || (this.props.addCounter !== prevProps.addCounter)) {
            this.getProblemsList();
        }
    }

    changeCountPage = pageSize => {
        this.setState({
            pageSize
        });
    };

    changePage = page => {
        this.setState({
            page
        });
    };


    componentDidMount() {
        this.getProblemsList();
    }

    deleteProblemTotal = (id) => {
        deleteProblem(id).then(res => {
            message.success("Проблема удалена");
            this.getProblemsList();
        });
    };


    getProblemsList = () => {
        const {page, pageSize} = this.state;
        getProblems(page, pageSize).then(res => {
            const {total, from, to, current_page, last_page} = res.data.data;
            this.setState({
                problemsList: res.data.data.data,
                total,
                from,
                to,
                current_page,
                last_page
            })
        })
    }

    render() {
        const {columns, total, from, to, current_page, last_page, loading, problemsList} = this.state;
        return (<div>

            <Table bordered className={"myProjectsCell usersTable problemsTable"}
                   dataSource={problemsList}
                   loading={loading}
                   columns={columns}
                   pagination={false}
                   locale={{ emptyText: <Empty description={'Нет данных'}/> }}/>
            <VpPagination
                changeCountPage={this.changeCountPage}
                changePage={this.changePage}
                total={total}
                from={from}
                to={to}
                current_page={current_page}
                last_page={last_page}/>
        </div>);
    }
}

export default AllProblems;
