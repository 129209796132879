import React, { Component } from 'react'
import {
  Row,
  Table,
  Empty
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";

export default class investmentLibrary extends Component {
  render() {
    const tableColumns = [
      {
        title: "№",
        dataIndex: "id",
        key: "id",
        width: "5em",
        render: (i, j, index) => (
          <span>{index+1}</span>
        )
      },
      {
        title: "Название",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Дата",
        dataIndex: "created_at",
        key: "created_at",
        render: (date) => moment(date).format("DD.MM.YYYY"),
        width: 300,
      },
      {
        title: "Операции",
        dataIndex: "src",
        key: "src",
        width: 200,
        render: (src, data) => (
          <div style={{display: 'flex', gap: '7px'}}>
            <a
              href={src}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              Скачать <DownloadOutlined style={{ marginLeft: "5px" }} />
            </a>
          </div>
        ),
      },
    ];

    const tableValues = [
      {
        id: 1,
        name: 'КУРОРТНЫЙ КОМПЛЕКС ПОБЕРЕЖЬЕ ОЗЕРА АЛАКОЛЬ - Алаколь',
        created_at: '2021-12-27',
        src: require('../../assets/docs/invest/Алаколь.pdf')
      },
      {
        id: 2,
        name: 'БАЛХАШСКАЯ КУРОРТНАЯ ЗОНА - Балхаш',
        created_at: '2021-12-27',
        src: require('../../assets/docs/invest/Балхаш.pdf')
      },
      {
        id: 3,
        name: 'БАЯНАУЛЬСКАЯ КУРОРТНАЯ ЗОНА - Баянауыл',
        created_at: '2021-12-27',
        src: require('../../assets/docs/invest/Баянауыл.pdf')
      },
      {
        id: 4,
        name: 'ИМАНТАУ ШАЛКАРСКАЯ КУРОРТНАЯ ЗОНА - Имантау',
        created_at: '2021-12-27',
        src: require('../../assets/docs/invest/Имантау.pdf')
      },
      {
        id: 5,
        name: 'КУРОРТНЫЙ КОМПЛЕКС МАНГИСТАУ - Мангистау',
        created_at: '2021-12-27',
        src: require('../../assets/docs/invest/Мангистау.pdf')
      },
      {
        id: 6,
        name: 'ИСТОРИКО-КУЛЬТУРНЫЙ КОМПЛЕКС ТУРКЕСТАН - Туркестан',
        created_at: '2021-12-27',
        src: require('../../assets/docs/invest/Туркестан.pdf')
      },
      {
        id: 7,
        name: 'КУРОРТНЫЙ КОМПЛЕКС ПОБЕРЕЖЬЕ ОЗЕРА ЩУЧЬЕ - Щучье',
        created_at: '2021-12-27',
        src: require('../../assets/docs/invest/Щучье.pdf')
      }
    ]

    return (
      <section className="library-section" style={{ padding: "0 10px" }}>
        <Row style={{ alignItems: "center", marginBottom: "15px" }}>
          <h2
            className='componentTitle'
            style={{
              fontWeight: "bold",
              marginBottom: 0,
              textAlign: "start",
            }}
          >
            Инвестиционные предложения
          </h2>
          <p>Если вы нашли интересный проект для вас, просим обратиться по номеру <a href="tel:87018884728">87018884728</a> или написать на наш электронный адрес <a href="mailto:y.adilkhanov@gmail.com">y.adilkhanov@gmail.com</a>.</p>
        </Row>
        <Table
          columns={tableColumns}
          dataSource={tableValues}
          className="library-table"
          bordered
          pagination={false}
          locale={{ emptyText: <Empty description={'Нет данных'}/> }}
        />
      </section>
    )
  }
}
