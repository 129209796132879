import React, {Component} from "react";
import {getTasks, getProjects, getTaskWithFilter, getTask} from "../actions/mainActions";
import {Spin, Row, Space, Button} from "antd";
import {Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption} from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import "./diagrams.scss";
import { TaskView } from "./Tasks/taskView";
import { ProjectView } from "./Projects/projectView";


const filterForTasks = {
    status: [],
    project_id: null,
    user_id: null,
    from_date: null,
    to_date: null,
    organization_id: null,
}

class Diagrams extends Component {
    state = {
        loading: false,
        projectsList: [],
        tasksList: [],

        finalGantt: [],
        showGantt: [],

        existingProjectsIds: [],
        existingTaskIds: [],

        viewMode: 'month',

        taskVisible: false,
        projectVisible: false,

        task: null,
        project_id: null,
    };

    componentDidMount() {
        this.setState({loading: true})
        getProjects().then((response) => {
            this.setState({
                projectsList: !!response.data.data ? Object.values(response.data.data) : [],
                loading: false,
                taskVisible: false,
                task: null,
            });
            getTaskWithFilter(filterForTasks).then(res => {
                this.setState({
                    loading: false,
                    tasksList: res.data.data,
                });
                this.handleGantt();
            });
        });
    }

    handleGantt = () => {
        const projectList = this.state.projectsList;
        const tasksList = this.state.tasksList;

        const existingProjectsIds = [];
        const existingTaskIds = [];
        const tempFinalGantt = [];

        projectList.forEach((project) => {
            if (!existingProjectsIds.includes(project.id)) {
                tempFinalGantt.push({
                    start: new Date(project.start),
                    end: new Date(project.end),
                    name: project.name,
                    id: `project-${project.id}`,
                    progress: project.perform_percent,
                    type: 'project',
                    hideChildren: true,
                })

                existingProjectsIds.push(project.id);
            }

            const currentProjectTasksList = project.tasks ? project.tasks : null;

            if (currentProjectTasksList) {
                currentProjectTasksList.forEach((task) => {
                    if (task.status === 'archive ') {
                        return;
                    }

                    if (!existingTaskIds.includes(task.id)) {
                        tempFinalGantt.push({
                            start: new Date(task.start),
                            end: new Date(task.end),
                            name: task.name,
                            id: task.id,
                            progress: task.perform_percent,
                            type: 'task',
                            project: `project-${task.task_project_id}`,
                            dependencies: task.parent_task_id ? [task.parent_task_id] : null
                        });

                        existingTaskIds.push(task.id);
                    }
                })
            }
        })

        existingProjectsIds.push('NoProject');
        tempFinalGantt.push({
            start: new Date(),
            end: new Date(),
            name: "Без проекта",
            id: 'NoProject',
            barFill: 100,
            type: 'project',
            hideChildren: true,
        });

        tasksList.forEach((task, index) => {
            if (task.status === 'archive ') {
                return;
            }

            if (!existingTaskIds.includes(task.id)) {
                tempFinalGantt.push({
                    start: new Date(task.start),
                    end: new Date(task.end),
                    name: task.name,
                    id: task.id,
                    barFill: task.perform_percent,
                    type: 'task',
                    project: 'NoProject',
                    dependencies: task.parent_task_id ? [task.parent_task_id] : null
                });

                existingTaskIds.push(task.id);
            }
        })

        this.setState({
            finalGantt: tempFinalGantt,
            showGantt: tempFinalGantt
        })
    }

    handleViewModeButton = (viewModeString) => {
        this.setState({
            viewMode: viewModeString,
        })
    }

    openObject = (task) => {
        if (task.type === 'task') {
            getTask(task.id).then((response) => {
                this.setState({
                    taskVisible: true,
                    task: response.data.data,
                })
            })
        }

        if (task.type === 'project') {
            if (task.id === 'NoProject') {
                return;
            }

            this.setState({
                projectVisible: true,
                project_id: task.id.split('-')[1],
            })
        }
    }

    handleCancel = () => {
        this.setState({
            taskVisible: false,
            projectVisible: false,
        })
    }

    render() {
        const handleExpanderClick = (project) => {
            const showGantt = this.state.showGantt;

            if (project.type === 'project') {
                const newShowGantt = showGantt.map((element) => {
                    if (element.id === project.id) {
                        return {
                            ...element,
                            hideChildren: !element.hideChildren,
                        }
                    } else {
                        return element;
                    }
                })

                this.setState({
                    showGantt: newShowGantt
                })
            }
        };

        const stateViewMode = () => {
            const viewModeString = this.state.viewMode;

            if (viewModeString === 'year') {
                return ViewMode.Year;
            }

            if (viewModeString === 'month') {
                return ViewMode.Month;
            }

            if (viewModeString === 'week') {
                return ViewMode.Week;
            }

            if (viewModeString === 'day') {
                return ViewMode.Day;
            }
        }

        return (
            <div>
                <h1 style={{textAlign: 'start'}} className="componentTitle">Диаграмма ганта</h1>
                {this.state.loading ? (<Spin></Spin>) : null}
                {this.state.showGantt.length > 0 ? (
                    <Row style={{marginBottom: '10px'}}>
                        <Space>
                            <Button
                                onClick={() => this.handleViewModeButton('year')}
                                style={this.state.viewMode === 'year' ? {
                                    color: '#40a9ff',
                                    borderColor: '#40a9ff'
                                } : null}
                            >
                                Год
                            </Button>
                            <Button
                                onClick={() => this.handleViewModeButton('month')}
                                style={this.state.viewMode === 'month' ? {
                                    color: '#40a9ff',
                                    borderColor: '#40a9ff'
                                } : null}
                            >
                                Месяц
                            </Button>
                            <Button
                                onClick={() => this.handleViewModeButton('week')}
                                style={this.state.viewMode === 'week' ? {
                                    color: '#40a9ff',
                                    borderColor: '#40a9ff'
                                } : null}
                            >
                                Неделя
                            </Button>
                            <Button
                                onClick={() => this.handleViewModeButton('day')}
                                style={this.state.viewMode === 'day' ? {
                                    color: '#40a9ff',
                                    borderColor: '#40a9ff'
                                } : null}
                            >
                                День
                            </Button>
                        </Space>
                    </Row>
                ) : null}
                <div className='gantDiv'>
                    {this.state.showGantt.length > 0 ? (<Gantt
                        viewMode={stateViewMode()}
                        tasks={this.state.showGantt}
                        preStepsCount={0}
                        locale={'rus'}
                        projectProgressColor={'#38B836'}
                        projectBackgroundColor={'#338DE0'}
                        barProgressColor={'#38B836'}
                        barBackgroundColor={'#808080'}
                        onExpanderClick={handleExpanderClick}
                        className={'TEST3333'}
                        onClick={(task) => {
                            this.openObject(task);
                            console.log(task)
                        }}
                    />) : null}
                </div>
                {this.state.taskVisible && (
                    <TaskView
                        taskId={this.state.task.id}
                        taskVisible={this.state.taskVisible}
                        handleCancel={this.handleCancel}
                    />
                )}
                {this.state.projectVisible && (
                    <ProjectView
                    id={this.state.project_id} 
                    open={this.state.projectVisible}
                    handleCancel={this.handleCancel}
                    />
                )}
            </div>
        )
    }
}

export default Diagrams;
