export const finSource = [
    {
        label: 'Бюджет',
        value: 'budget'
    },
    {
        label: 'Местный бюджет',
        value: 'local_budget'
    },
    {
        label: 'Республиканский бюджет',
        value: 'republican_budget'
    },
    {
        label: 'Инвестиции',
        value: 'investments'
    },
    {
        label: 'Другое',
        value: 'other'
    }
]